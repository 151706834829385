import React from 'react';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../../svgIcons';
import LinkedInIcon from '../../../assets/images/linkedin.svg';
import InstagramIcon from '../../../assets/images/instagram.svg';
import FacebookIcon from '../../../assets/images/facebook.svg';
import YoutubeIcon from '../../../assets/images/youtube.svg';
import TwitterIcon from '../../../assets/images/twitter.svg';



export const LandingFooter = () =>{
 return(<footer className="footer-container">
         <div className="row">
             <div className="col-6 p-0">
                <Link className="footer-link" to="/terms">Terms & Conditions</Link>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://help.interswitchgroup.com/">Support</a>
                <Link className="footer-link" to="/faq">FAQ</Link>
             </div>
             <div className="footer-line col-12"></div>
             <div className="col-12 p-0 footer-base">
                 <Link to="/">
                     <LogoIcon classname="footer-logo" />
                 </Link>
                 <span className="d-mobile">© 2002 - 2022 Interswitch Group</span>
                 <span className="footer-base social-icons">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/interswitch-limited" ><img src={LinkedInIcon} alt="LinkedIn" /></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/InterswitchGRP" ><img src={TwitterIcon} alt="Twitter" /></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/interswitchgroup/?hl=en" ><img src={InstagramIcon} alt="Instagram" /></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/InterswitchGroup" ><img src={FacebookIcon} alt="Facebook" /></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/Interswitch" ><img src={YoutubeIcon} alt="Youtube" /></a>
                 </span>
                 <span className="d-desktop">© 2002 - 2022 Interswitch Group</span>
             </div>
         </div>
 </footer>)
}
export default LandingFooter;