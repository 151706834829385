import React from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import ProductSwitch from "./ProductSwitch";
import ChangePW from "../Auth/ChangePW/ChangePW";
import EditProfile from "../components/Profile/EditProfile";
// import { UserSvg } from "../assets/Svgs/Svgs";

const UserSvg = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="14" cy="14" r="14" fill="#7F8FA4" />
            <g clipPath="url(#clip0)">
                <path
                    d="M18.2833 15.3725C17.9458 15.076 17.5366 14.8198 17.0669 14.6111C16.8661 14.5219 16.6311 14.6123 16.5419 14.813C16.4527 15.0137 16.5432 15.2487 16.7439 15.338C17.14 15.514 17.4813 15.7266 17.7582 15.97C18.0996 16.27 18.2955 16.7044 18.2955 17.162V17.8978C18.2955 18.1171 18.117 18.2955 17.8978 18.2955H10.1023C9.88301 18.2955 9.70457 18.1171 9.70457 17.8978V17.162C9.70457 16.7044 9.90041 16.27 10.2418 15.97C10.6437 15.6169 11.8145 14.7955 14 14.7955C15.6229 14.7955 16.9432 13.4752 16.9432 11.8524C16.9432 10.2295 15.6229 8.90918 14 8.90918C12.3772 8.90918 11.0568 10.2295 11.0568 11.8524C11.0568 12.8011 11.5082 13.6463 12.2073 14.1848C10.9281 14.466 10.1323 15.0073 9.71677 15.3725C9.20353 15.8234 8.90912 16.4756 8.90912 17.162V17.8978C8.90912 18.5558 9.44434 19.091 10.1023 19.091H17.8978C18.5557 19.091 19.0909 18.5558 19.0909 17.8978V17.162C19.0909 16.4756 18.7965 15.8234 18.2833 15.3725ZM11.8523 11.8524C11.8523 10.6681 12.8158 9.70463 14 9.70463C15.1843 9.70463 16.1478 10.6681 16.1478 11.8524C16.1478 13.0366 15.1843 14.0001 14 14.0001C12.8158 14.0001 11.8523 13.0366 11.8523 11.8524Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="10.1818"
                        height="10.1818"
                        fill="white"
                        transform="translate(8.90912 8.90918)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

class Header extends React.Component {
    componentDidMount() {
        this.props.dispatch(Actions.getBasicInfo());
    }

    changePassword = () => {
        const isCPToggle = this.props.toggleRightSidebar === "change_pw";
        this.props.dispatch(Actions.toggleRightSidebar(isCPToggle ? false : "change_pw"));
    };

    openEdit = e => {
        e.preventDefault();
        const isEPToggle = this.props.toggleRightSidebar === "edit_profile";
        this.props.dispatch(Actions.toggleRightSidebar(isEPToggle ? false : "edit_profile"));
    };

    render() {
        const {
            logout,
            decodedData = {},
            modules = [],
            status,
            toggleModal,
            // dispatch,
            toggleRightSidebar
        } = this.props;

        const { firstName = "Administrator" } = decodedData;
        const isOpen = status === "open";

        return (
            <React.Fragment>
                <div className="navbar-ag">
                    <header className="isw-nav--header" style={{ zIndex: 1001 }}>
                        <div className="isw-nav--pad">
                            <div className="isw-logo-menu">
                                <div className="logo"></div>
                                <div className="isw-nav-button">
                                    {isOpen ? (
                                        <button
                                            id="menu-close"
                                            className="menu-btn"
                                            onClick={() => toggleModal("close")}
                                            style={{
                                                display: "block",
                                                height: `${24}px`
                                            }}>
                                            <i className="material-icons">close</i>
                                        </button>
                                    ) : (
                                            <button
                                                id="menu-reveal"
                                                className="menu-btn"
                                                onClick={() => toggleModal("open")}>
                                                <i className="material-icons">menu</i>
                                            </button>
                                        )}
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="isw-image" style={{ maxWidth: "2.1rem", marginRight: "1rem" }}></div>
                                <div className="dropdown dropdown-toggle" style={{ marginRight: 30 }}>
                                    <a
                                        href="."
                                        role="button"
                                        id="dropUserNav"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ color: '#00425f', fontSize: "14px" }}
                                    >
                                        <span className="text-blue d-xs-none d-sm-none d-md-inline font-weight-bold mr-2">
                                            Hi {firstName}
                                        </span>{' '}
                                        <UserSvg />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" style={{
                                        borderRadius: '0 0 0.25rem 0.25rem',
                                        marginTop: '49%', 
                                    }} aria-labelledby="dropUserNav">
                                        {/* <a className="dropdown-item" href="#">
                                        <p className="mb-0">{localStorage.getItem("name")}</p>
                                        <small>{localStorage.getItem("role")}</small>
                                        </a> */}
                                        <span
                                            onClick={this.changePassword}
                                            className="dropdown-item"
                                        >
                                            Change Password
                                        </span>
                                        <span
                                            className="dropdown-item"
                                            onClick={this.openEdit}
                                        >
                                            Profile
                                        </span>
                                        <span
                                            onClick={logout}
                                            className="dropdown-item text-danger"
                                        >
                                            Logout
                                        </span>
                                    </div>
                                </div>
                                <ProductSwitch modules={modules} />
                            </div>
                        </div>
                    </header>

                    <ChangePW />

                    <EditProfile toggleRightSidebar={toggleRightSidebar} />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ getBasicInfo_reducer: { basicInfo } }) => {
    return {
        basicInfo
    };
};

export default connect(mapStateToProps)(Header);