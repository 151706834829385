import {
    GET_VENDOR_USERS_FAILURE,
    GET_VENDOR_USERS_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getVendorUsers_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_VENDOR_USERS_SUCCESS:
            return {
                ...state,
                vendor_users: payloadReturned,
                vendor_users_length: totalCount
            };
        case GET_VENDOR_USERS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getVendorUsers_reducer;
