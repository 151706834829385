import { TOGGLE_RIGHT_SIDEBAR } from "../../redux-flow/arsVariables";

import { initialState } from "../initialState";

const toggleRightSidebar_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_RIGHT_SIDEBAR:
            return {
                ...state,
                toggleRightSidebar: payload
            };

        default:
            return state;
    }
};

export default toggleRightSidebar_reducer;
