import React from 'react';

export const LandingButton = ({ text, to, onclick=null, classname, icon="", datatestid='', pretextIcon=""}) => {
    return (<a onClick={onclick}href={to} style={{
        justifyContent: icon? 'space-around': 'center',
    }} className={`landing-button ${classname} ${pretextIcon? 'pretextIcon': ''}`} data-testid={datatestid}>
                {pretextIcon && <img src={pretextIcon} alt={text}/>} {text} {icon && <img src={icon} alt={text}/>}
    </a>)
}

export default LandingButton;