import React, { Fragment } from "react";
import { connect } from "react-redux";
import FormLayout from "../../reuse/form";
import { Actions } from "../../redux-flow/actions/_index";
import { Icon, Drawer } from "antd";
import Joi from "joi-browser";
// material ui
import { Button, IconButton } from "@material-ui/core";

class EditProfile extends FormLayout {
    data = {
        firstName: "",
        lastName: ""
    };

    state = {
        data: this.data,
        pending: false,
        errors: {},
        styles: {}
    };

    schema = {
        firstName: Joi.string()
            .required()
            .label("First Name"),
        lastName: Joi.string()
            .required()
            .label("Last Name")
    };

    componentDidUpdate() {
        if (!this.props.toggleRightSidebar && this.state.pending) {
            this.setState({ data: this.data, pending: false });
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        const { lastName, firstName } = this.state.data;
        this.setState({
            pending: true
        });
        this.props.dispatch(
            Actions.editBasicInfo({
                firstName,
                lastName
            })
        );
    };

    _toggleRightSidebar = () => {
        this.setState({
            data: this.data,
            errors: {},
            styles: {}
        });
        this.props.dispatch(Actions.toggleRightSidebar(false));
    };

    render() {
        const { toggleRightSidebar, isLoading } = this.props;

        return (
            <div className="isw-mainLayout1" id="body">
                <Drawer
                    visible={
                        toggleRightSidebar === "edit_profile" ? true : false
                    }
                    mask={false}
                    closable={false}
                    placement="right"
                    title={
                        <Fragment>
                            {" "}
                            <IconButton
                                onClick={this._toggleRightSidebar}
                                style={{
                                    cursor: "pointer"
                                }}>
                                <Icon
                                    type="right"
                                    style={{
                                        width: `${16}px`,
                                        color: "#999"
                                    }}
                                />
                            </IconButton>
                            Edit Your Details
                        </Fragment>
                    }
                    zIndex={999003}
                    width={400}
                    onClose={this._toggleRightSidebar}>
                    <div className="isw-content--wrapper ">
                        <div>
                            <form className="content" id="content-body">
                                <div className="container-fluid container-limited">
                                    <div className="row">
                                        <div className="form-field mb-3 col-12">
                                            {this.renderInput(
                                                "firstName",
                                                "First Name"
                                            )}
                                        </div>
                                        <div className="form-field mb-3 col-12">
                                            {this.renderInput(
                                                "lastName",
                                                "Last Name"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </form>

                            <div className="drawer-btn-cont">
                                <Button
                                    onClick={this._toggleRightSidebar}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className="isw-btn  text-primary">
                                    <span>Cancel</span>
                                </Button>
                                {this.renderDrawerSubmitButton(
                                    isLoading,
                                    this.onSubmit,
                                    "Save",
                                    "Saving"
                                )}
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = ({ ui_reducer }) => {
    const { isLoading } = ui_reducer;
    return {
        isLoading
    };
};

export default connect(mapStateToProps)(EditProfile);
