import React from 'react';
import { useOutletContext } from 'react-router-dom';
import LandingButton from '../../component/landing-button';
import ProductNav from '../../component/product-nav/product-nav';
import TankerRoad from '../../../assets/images/tanker_road.png';
import mTankerRoad from '../../../assets/images/tanker_road_mobile.png';
import CheckIcon from '../../../assets/images/check.svg';


export const ContractManagementLandingPage = () => {
    const { handleShowLeadForm } = useOutletContext();
    return (<div className="forecourt-container">
        <div className="contract-mgt sub-hero">
            <h2 className="hero-caption">Maximize opportunities and limit risks with digital <br className="d-mobile" />Contract Management </h2>
            <p className="sub-hero-caption">Protect your business and strengthen your connections with technology driven contract<br className="d-mobile" /> management that is transparent, reliable, thorough and secure.</p>
            <LandingButton text="Talk to Sales" onclick={handleShowLeadForm} classname="sub-hero-button" />
        </div>
        <div className="mg-txt-section bg-sm-white mb-0 d-desktop d-tab">
            <h2 className="h sol-cap m-0">
                Seamless contract management for every aspect of your energy business
            </h2>
        </div>
        <div className="contract-mgt forcourt-feature-container">
            <div className="row card mgt-card">
                <div className="col-12 d-mobile">
                    <h2 className="h sol-cap">
                        Seamless contract management for every aspect of your energy business
                    </h2>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>Fleet <br className="d-mobile"/> Management </h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>Contract administration
                    <br className="d-mobile"/> management </h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>Limit
                    <br className="d-mobile"/> management </h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>API integration to<br className="d-mobile"/> 3rd party ERP </h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>Online access to real-<br className="d-mobile"/>time reporting</h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                    <img src={CheckIcon} alt="Fleet Management" />
                    <h5>Automated Settlement<br className="d-mobile"/> & Reconciliation </h5>
                </div>
            </div>
        </div>
        <div className="row contractmgt-benefit quick-energy-benefit">
            <div className="col-lg-6 col-sm-12 benefit-right">

            </div>
            <div className="col-lg-6 col-sm-12 benefit-left">
                <div className="">
                    <h3 className="benefit-cap">Benefits for Oil marketers & Corporate customers</h3>
                    <span>With our contract management solution you have access to a wide variety of benefits such as:</span>
                    <ul>
                        <li><span className="orange-circle"></span>Increase in customer confidence, transparency of operations, revenue and profitability.</li>
                        <li><span className="orange-circle"></span>Multiple validation tokens (voucher & cards)
                            Closed scheme POS can support post paid and prepaid contract types.</li>
                        <li><span className="orange-circle"></span>Online real-time reporting across all points of interaction with data analytics.</li>
                        <li><span className="orange-circle"></span>Flexibility of implementation – stand alone or integrated to your ERP.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="quick-energy product-nav" id="product-nav">
            <ProductNav />
        </div>
        {/* before footer */}
        <div className="quick-energy pre-footer">
            <div className="row pre-footer-ctn">
                <div className="col-lg-6 col-sm-12 right">
                    <h2>
                        Improved revenue and customer<br className="d-mobile" /> experience for energy providers
                    </h2>
                    <p>
                        Stay in charge of your energy business, regardless of how many outlets you have. From your
                        inventory to your processes and daily sales, Interswitch Energy provides insights real-time,
                        equipping you to make informed business decisions.
                    </p>
                </div>
                <div className="col-lg-6 col-sm-12 left">
                    <picture>
                        <source srcSet={mTankerRoad} media="(max-width: 600px)" />
                        <img

                            src={TankerRoad} alt="Contract Management reveue" />
                    </picture>

                </div>
            </div>
            <div className="pre-footer-lower">
                <div className="card pre-footer-card">
                    <span>
                        <h3>Interested in the product?</h3>
                        <span>Let’s get you started!</span>
                    </span>
                    <LandingButton onclick={handleShowLeadForm} classname="sub-hero-button" text="Talk to Sales" />
                </div>
            </div>
        </div>
    </div>)
}

export default ContractManagementLandingPage;