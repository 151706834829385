import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../redux-flow/services/services";
import {
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    DELETE_USER
} from "../../../redux-flow/arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";

function* deleteUser(payload) {
    try {
        const { error, data, status } = yield call(
            Services.deleteUserService,
            payload
        );

        const { code, description } = data;
        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (error === false && status === 200 && code === 1) {
            return yield put({
                type: DELETE_USER_SUCCESS
            });
        } else if (error === false && status === 200) {
            return yield put({
                type: DELETE_USER_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: DELETE_USER_FAILURE
        });
    }
}

export default function* deleteUserSaga() {
    yield takeLatest(DELETE_USER, deleteUser);
}
