import {
  UPDATE_PAYMENT_ITEM_CODE_SUCCESS,
  UPDATE_PAYMENT_ITEM_CODE_FAILURE,
  SUCCESS,
  FAILURE,
  RESET
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const updatePaymentCodeInfo_reducer = (state = initialState, action) => {
  const { type, message } = action;
  switch (type) {
    case UPDATE_PAYMENT_ITEM_CODE_SUCCESS:
      return {
        ...state,
        edit_basic_info: SUCCESS,
        updatedPaymentCode: message
      };
    case UPDATE_PAYMENT_ITEM_CODE_FAILURE:
      return {
        ...state,
        edit_basic_info: FAILURE,
        edit_basic_info_data: message ? message : "Bad Network Connectivity"
      };
    default:
      return state;
  }
};

export default updatePaymentCodeInfo_reducer;
