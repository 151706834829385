import {
    GET_CUSTOMER_USERS_FAILURE,
    GET_CUSTOMER_USERS_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getCustomerUsers_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_CUSTOMER_USERS_SUCCESS:
            return {
                ...state,
                customer_users: payloadReturned,
                customer_users_length: totalCount
            };
        case GET_CUSTOMER_USERS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getCustomerUsers_reducer;
