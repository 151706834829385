import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { history } from "../reuse/history";
import { Spin } from "antd";
import { NotifyClose } from "../reuse/notify";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
import { PropsContext } from "../reuse/PropsContext";

class Fleets extends BaseComponent {
    state = {
        SearchKeyword: "",
        currentPage: 1,
        dataPerPage: 10
    };

    componentWillUnmount() {
        NotifyClose();
    }

    componentDidMount() {
        const { currentPage: pageIndex, dataPerPage: pageSize } = this.state;
        this.fetch({ pageIndex, pageSize });
    }

    fetch = ({ pageIndex, pageSize, SearchKeyword = "" }) => {
        this.props.dispatch(
            Actions.getFleets({
                pageIndex,
                pageSize,
                SearchKeyword
            })
        );
    };

    goToInfo = id => {
        const { fleets } = this.props;
        const user = fleets.filter(user => user.id === id);
        history.push({
            pathname: `/isw/fleets/${user[0].id}`,
            state: { user }
        });
    };

    getBySearch = (e, empty) => {
        e.preventDefault();
        const { dataPerPage: pageSize, SearchKeyword } = this.state;
        this.setState({
            SearchKeyword: empty ? "" : SearchKeyword
        });
        this.props.dispatch(
            Actions.getFleets({
                pageIndex: 1,
                pageSize,
                SearchKeyword: empty ? "" : SearchKeyword
            })
        );
    };

    render() {
        const { fleets, fleetsLength } = this.props;
        const { status, toggleModal, loading } = this.context;
        return (
            <Fragment>
                <div className="isw-mainLayout1">
                    <Sidebar
                        search={this.state.SearchKeyword}
                        resetSearch={this.getBySearch}
                        status={status}
                        toggleModal={toggleModal}
                    />
                    <div />
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar title='Fleet Owners'
                                currentText= {this.state.SearchKeyword}
                                onChangeHandler={this.handleSearch} 
                                onSubmitHandler={this.getBySearch}
                            />
                            <div>
                                <div className="content" id="content-body">
                                    <div className={`container-fluid container-limited`}>
                                        <div className="row my-4">
                                            <div className="col-lg-6 isw-valign--middle mr-auto">
                                                {this.renderPageTracker(
                                                    fleetsLength
                                                )}
                                            </div>
                                            {/* <form
                                                className="ml-auto col-5"
                                                onSubmit={this.getBySearch}>
                                                <input
                                                    value={
                                                        this.state.SearchKeyword
                                                    }
                                                    onChange={this.handleSearch}
                                                    onBlur={this.handleSearch}
                                                    className="isw-input-search"
                                                    placeholder="Search"
                                                />
                                            </form> */}
                                        </div>

                                        <div className="row">
                                            <div
                                                className="col-12"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                <div className="row mb-4 mt-3">
                                                    <div className="col-12">
                                                        <div className="isw-table">
                                                            {this.renderAdminTableHeader()}

                                                            {fleetsLength ===
                                                            0 ? (
                                                                this.renderTableAbnormality(
                                                                    `No fleet owners(s) created`
                                                                )
                                                            ) : !fleetsLength ? (
                                                                this.renderTableAbnormality()
                                                            ) : (
                                                                <ul className="isw-table--body">
                                                                    {fleets.map(
                                                                        fleet_user => {
                                                                            const {
                                                                                activated,
                                                                                contactTel,
                                                                                title,
                                                                                id,
                                                                                contactEmail
                                                                            } = fleet_user;
                                                                            return this.renderAdminTableContents(
                                                                                {
                                                                                    activated,
                                                                                    phoneNumber: contactTel,
                                                                                    companyName: title,
                                                                                    id,
                                                                                    email: contactEmail,
                                                                                    component:
                                                                                        "fleets"
                                                                                }
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.renderPagination(
                                                    fleetsLength
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}
Fleets.contextType = PropsContext;
const mapStateToProps = ({ getFleets_reducer }) => {
    const { fleets, fleetsLength } = getFleets_reducer;
    return {
        fleets,
        fleetsLength
    };
};

export default connect(mapStateToProps)(Fleets);
