import {
    GET_BASIC_SUCCESS,
    GET_BASIC_FAILURE
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const getBasicInfo_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BASIC_SUCCESS:
            return {
                ...state,
                basicInfo: payload
            };
        case GET_BASIC_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getBasicInfo_reducer;
