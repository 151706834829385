import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
  UPDATE_PAYMENT_ITEM_CODE_INFO,
  UPDATE_PAYMENT_ITEM_CODE_SUCCESS
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* updatePaymentCodeInfo(payload) {
  try {
    yield put(Actions.miniUiStart());
    const {
      data: { code, description }
    } = yield call(Services.updatePaymentCodeInfoService, payload);
    yield put(Actions.miniUiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error"
    });
    if (code === 1) {
      return yield put({
        type: UPDATE_PAYMENT_ITEM_CODE_SUCCESS,
        email: payload.EmailAddress
      });
    }
  } catch (err) {
    Notify({
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error"
    });
    yield put(Actions.miniUiStop());
  }
}

export default function* updatePaymentCodeInfoSaga() {
  yield takeLatest(UPDATE_PAYMENT_ITEM_CODE_INFO, updatePaymentCodeInfo);
}
