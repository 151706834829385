import React, { Component, Fragment, Suspense, lazy } from "react";
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import { Spin } from "antd";
import { SUCCESS, FAILURE } from "../redux-flow/arsVariables";
import Header from "./header-ag";
import { history } from "./history";
import { PropsContext } from "./PropsContext";
import withRouter from "./WithRouter";
import { url } from "../route";
const ChangePW = lazy(() => import("../Auth/ChangePW/ChangePW"));
class Layout extends Component {
    
    state = { status: "close" };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(Actions.onInit());
    }

    logout = () => {
        // window.location.href = process.env.REACT_APP_FRONT_SSO_URL +  '/logout';
        history.push("/logout");
    };

    toggleModal = status => {
        this.setState({ status });
    };

    static getDerivedStateFromProps = (props, state) => {
        const { storage, logout, fakeAuth, dispatch } = props;

        if (storage === SUCCESS && logout === undefined) {
            fakeAuth.authenticate();
            dispatch(Actions.reset());
            return null;
        }

        if (logout === SUCCESS) {
            setTimeout(() => {
                dispatch(Actions.reset());
                fakeAuth.signout();
                history.replace({ pathname: url });
            }, 1000);
        }

        if (logout === FAILURE) return dispatch(Actions.reset());

        return state;
    };

    render() {
        // component and props passed are accessed
        const {
            // children,
            decodedData,
            data,
            toggleRightSidebar,
            // loading,
            // isLoading,
            // success
        } = this.props;
        const { status } = this.state;
        return (
            <Fragment>
                <Header
                    logout={this.logout}
                    decodedData={decodedData}
                    toggleModal={this.toggleModal}
                    toggleRightSidebar={toggleRightSidebar}
                    status={status}
                    modules={(data || {}).modules}
                />

                <Suspense fallback={<Spin spinning={true} tip="Loading..." />}>
                    <ChangePW toggleRightSidebar={toggleRightSidebar} />
                </Suspense>

                <div>
                    <PropsContext.Provider value={{...this.props, toggleModal: this.toggleModal}}>
                         <Outlet />
                    </PropsContext.Provider>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({
    storage_reducer,
    login_reducer,
    logout_reducer,
    toggleRightSidebar_reducer,
    ui_reducer
}) => {
    const { storage, data, decodedData: decodedFromStorage } = storage_reducer;
    const { decodedData: decodedFromLogin } = login_reducer;
    const { logout } = logout_reducer;
    const { loading, isLoading, success } = ui_reducer;
    const { toggleRightSidebar } = toggleRightSidebar_reducer;

    return {
        toggleRightSidebar,
        storage,
        data,
        logout,
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage,
        loading,
        isLoading,
        success,
    };
};

export default withRouter(connect(mapStateToProps)(Layout));
