import createStore from "./create-store";

 const store =
    process.env.NODE_ENV === "development"
        ? createStore(
              window.__REDUX_DEVTOOLS_EXTENSION__ &&
                  window.__REDUX_DEVTOOLS_EXTENSION__()
          )
        : createStore();

export default store;