import {put, takeLatest} from "redux-saga/effects";
import {clear} from "../../redux-flow/services/localStorageHelper";
import {LOGOUT_USER, LOGOUT_FAILURE, LOGOUT_SUCCESS} from "../arsVariables";

function* logoutUser() {
  try {
    clear();
    return yield put({type: LOGOUT_SUCCESS});
  } catch (error) {
    return yield put({type: LOGOUT_FAILURE});
  }
}

export default function* logoutSaga() {
  yield takeLatest(LOGOUT_USER, logoutUser);
}
