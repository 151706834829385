import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_ISW_ORDER_STATS,
    GET_ISW_ORDER_STATS_FAILURE,
    GET_ISW_ORDER_STATS_SUCCESS
} from "../../arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";

function* getISWOrderStats(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, totalCount, payload: fromData }
        } = yield call(Services.getISWOrderStatsService, payload);
        yield put(Actions.uiStop());

        if (code === 1) {
            return yield put({
                type: GET_ISW_ORDER_STATS_SUCCESS,
                payloadReturned: fromData,
                totalCount: totalCount
            });
        } else if (code !== 1) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: GET_ISW_ORDER_STATS_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_ISW_ORDER_STATS_FAILURE
        });
    }
}

export default function* getISWOrderStatsSaga() {
    yield takeLatest(GET_ISW_ORDER_STATS, getISWOrderStats);
}
