import React, { Fragment, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { IconAddContact } from "../reuse/svgs";
import { Spin } from "antd";
import { NotifyClose } from "../reuse/notify";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
import { PropsContext } from "../reuse/PropsContext";
const CreateSystemUser = lazy(() =>
    import("../components/SystemUser/CreateSysUser")
);
class SystemUsers extends BaseComponent {
    state = {
        SearchKeyword: "",
        currentPage: 1,
        dataPerPage: 10,
        dropdownUserId: null
    };

    componentWillUnmount() {
        NotifyClose();
    }

    componentDidMount() {
        const { currentPage: pageIndex, dataPerPage: pageSize } = this.state;
        this.fetch({ pageIndex, pageSize });
    }

    fetch = ({ pageIndex, pageSize, SearchKeyword = "" }) => {
        this.props.dispatch(
            Actions.getSystemUsers({ pageIndex, pageSize, SearchKeyword })
        );
    };

    getBySearch = (e, empty) => {
        e.preventDefault();
        const { dataPerPage: pageSize, SearchKeyword } = this.state;
        this.setState({
            SearchKeyword: empty ? "" : SearchKeyword
        });
        this.props.dispatch(
            Actions.getSystemUsers({
                pageIndex: 1,
                pageSize,

                SearchKeyword: empty ? "" : SearchKeyword
            })
        );
    };

    render() {
        const {
            dropdownUserId,
            SearchKeyword
        } = this.state;

        const {
            system_users,
            system_usersLength,
            
        } = this.props;
        const { toggleRightSidebar, toggleModal, status, userId, loading } = this.context;

        return (
            <Fragment>
                <div className="isw-mainLayout1">
                    <Sidebar
                        resetSearch={this.getBySearch}
                        search={this.state.SearchKeyword}
                        status={status}
                        toggleModal={toggleModal}
                    />
                    <div />
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar title='Administrative Users' 
                                currentText= {SearchKeyword}
                                onChangeHandler={this.handleSearch}
                                onSubmitHandler={this.getBySearch}
                            />
                            <div>
                                <div className="content" id="content-body">
                                    <div className={`container-fluid container-limited`}>
                                        <div className="row my-4">
                                            <div className="col-lg-9 isw-valign--middle mr-auto">
                                                {this.renderPageTracker(
                                                    system_usersLength
                                                )}
                                            </div>
                                            {/* <form
                                                className="ml-auto col-5"
                                                onSubmit={this.getBySearch}>
                                                <input
                                                    value={SearchKeyword}
                                                    onChange={this.handleSearch}
                                                    onBlur={this.handleSearch}
                                                    className="isw-input-search"
                                                    placeholder="Search"
                                                />
                                            </form> */}
                                            <div
                                                className="col-lg-3 col-md-2 text-right"
                                                data-toggle="modal"
                                                data-target="#exampleModal">
                                                <button className="isw-btn isw-btn--raised bg-primary text-white w-100">
                                                    <IconAddContact />
                                                    <span>Add User</span>
                                                </button>
                                            </div>
                                        </div>
                                        {this.renderChoiceModal({
                                            header: "Create System User",
                                            others: "system user",
                                            id: "sys_users"
                                        })}

                                        <div className="row">
                                            <div
                                                className="col-12"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                <div className="row mb-4 mt-3">
                                                    <div className="col-12">
                                                        <div className="isw-table">
                                                            <ul className="isw-table--head">
                                                                <li className="isw-table--headLi">
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                            <span className="isw-table--headItem">
                                                                                Name
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <span className="isw-table--headItem">
                                                                                Email
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <span className="isw-table--headItem">
                                                                                Status
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2">
                                                                            <span className="isw-table--headItem">
                                                                                Role
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 ">
                                                                            <span className="isw-table--headItem">
                                                                                Phone
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-1 ">
                                                                            <span className="isw-table--headItem">
                                                                                Action
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            {system_usersLength ===
                                                            0 ? (
                                                                this.renderTableAbnormality(
                                                                    `No system user(s) created`
                                                                )
                                                            ) : !system_usersLength? (
                                                                this.renderTableAbnormality()
                                                            ) : (
                                                                <ul className="isw-table--body">
                                                                    {system_users.map(
                                                                        system_user => {
                                                                            const {
                                                                                activated,

                                                                                phoneNumber,
                                                                                id,
                                                                                email,
                                                                                fullName,
                                                                                role
                                                                            } = system_user;
                                                                            return (
                                                                                <li
                                                                                    className="isw-table--bodyLi"
                                                                                    key={id.toString()}>
                                                                                    <div className="row isw-table--bodyA">
                                                                                        <div className="col-lg-3 isw-valign--middle">
                                                                                            <h3 className="isw-subtitle">
                                                                                                {
                                                                                                    fullName
                                                                                                }
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div className="col-lg-2 isw-valign--middle">
                                                                                            <span className="isw-p2">
                                                                                                {
                                                                                                    email
                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-lg-2 isw-valign--middle">
                                                                                            <span className="isw-p2">
                                                                                                {activated
                                                                                                    ? "Active"
                                                                                                    : "Inactive"}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-lg-2 isw-valign--middle">
                                                                                            <span className="isw-p2">
                                                                                                {
                                                                                                    role
                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-lg-2 isw-valign--middle">
                                                                                            <span className="isw-p2">
                                                                                                {
                                                                                                    phoneNumber
                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-lg-1 isw-valign--middle text-center">
                                                                                            {this.renderTableDropdown(
                                                                                                {
                                                                                                    activated,
                                                                                                    id,
                                                                                                    goToInfo: this
                                                                                                        .goToSystemUserInfo,
                                                                                                    component:
                                                                                                        "sys",

                                                                                                    sbFor:
                                                                                                        "bulk_sys_users"
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.renderPagination(
                                                    system_usersLength
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                    <Suspense
                        fallback={<Spin spinning={true} tip="Loading..." />}>
                        <CreateSystemUser
                            toggleRightSidebar={toggleRightSidebar}
                            dropdownUserId={dropdownUserId}
                            userId={userId}
                        />
                    </Suspense>
                </div>
            </Fragment>
        );
    }
}
SystemUsers.contextType = PropsContext;
const mapStateToProps = ({ getSystemUsers_reducer }) => {
    const { system_users, system_usersLength } = getSystemUsers_reducer;
    return {
        system_users,
        system_usersLength
    };
};

export default connect(mapStateToProps)(SystemUsers);
