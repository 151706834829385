import {
    GET_FLEET_INFO_FAILURE,
    GET_FLEET_INFO_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getFleetInfo_reducer = (state = initialState, action) => {
    const { type, payloadReturned } = action;
    switch (type) {
        case GET_FLEET_INFO_SUCCESS:
            return {
                ...state,
                fleet_info: payloadReturned
            };
        case GET_FLEET_INFO_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getFleetInfo_reducer;
