import { call, put, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";
import Services from "../services/services";
import {
  GET_REPORT_LOGS_FAILURE,
  GET_REPORT_LOGS_SUCCESS,
  GET_REPORT_LOGS,
  GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  GET_REPORT_LOGS_PDF_DOWNLOAD,
  GET_REPORT_LOGS_DOWNLOAD_FAILURE,
  GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

const {
  getReportLogsService,
  getReportLogsDownloadPDFService,
  getReportLogsDownloadExcelService,
} = Services;

function* getReportLogs(payload) {
  try {
    yield put(Actions.uiStart());
    const {
      data: {
        code,
        description,
        data: payloadReturned,
        total,
        noOfPages,
        sizeOfCurrentPage,
      },
    } = yield call(getReportLogsService, payload);

    yield put(Actions.uiStop());

    if (code !== "00") {
      Notify({
        message: description,
        className: "error",
      });
      return yield put({
        type: GET_REPORT_LOGS_FAILURE,
      });
    } else if (code === "00") {
      return yield put({
        type: GET_REPORT_LOGS_SUCCESS,
        payloadReturned,
        noOfPages,
        sizeOfCurrentPage,
        total,
      });
    }
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_FAILURE,
    });
  }
}

export default function* getReportLogsSaga() {
  yield takeLatest(GET_REPORT_LOGS, getReportLogs);
}

// PDF Dowloads
function* getReportLogsPDFDownload(payload) {
  try {
    yield put(Actions.uiStart());
    const response = yield call(getReportLogsDownloadPDFService, payload);
    fileDownload(response.data, 'report.pdf');

    yield put(Actions.uiStop());
    Notify({
      message: 'Download Successful',
      className: "success",
    });
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
    });
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_FAILURE,
    });
  }
}


export function* getReportLogsPDFDownloadSaga() {
  yield takeLatest(GET_REPORT_LOGS_PDF_DOWNLOAD, getReportLogsPDFDownload);
}

// Excel Dowloads
function* getReportLogsExcelDownload(payload) {
  try {
    yield put(Actions.uiStart());
    const response = yield call(getReportLogsDownloadExcelService, payload);
    fileDownload(response.data, 'report.xlsx');
    Notify({
      message: 'Download Successful',
      className: "success",
    });
    yield put(Actions.uiStop());
    
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
    });
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_FAILURE,
    });
  }
}


export function* getReportLogsExcelDownloadSaga() {
  yield takeLatest(GET_REPORT_LOGS_EXCEL_DOWNLOAD, getReportLogsExcelDownload);
}