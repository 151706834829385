import {
    EDIT_BASIC_SUCCESS,
    EDIT_BASIC_FAILURE,
    SUCCESS,
    FAILURE,
    RESET
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const editBasicInfo_reducer = (state = initialState, action) => {
    const { type, message } = action;
    switch (type) {
        case EDIT_BASIC_SUCCESS:
            return {
                ...state,
                edit_basic_info: SUCCESS,
                edit_basic_info_data: message
            };
        case EDIT_BASIC_FAILURE:
            return {
                ...state,
                edit_basic_info: FAILURE,
                edit_basic_info_data: message
                    ? message
                    : "Bad Network Connectivity"
            };

        case RESET:
            return {
                ...state,
                edit_basic_info: null,
                edit_basic_info_data: null
            };
        default:
            return state;
    }
};

export default editBasicInfo_reducer;
