import {
  LOGOUT_SUCCESS,
  SUCCESS,
  FAILURE,
  LOGOUT_FAILURE,
  RESET,
} from "../arsVariables";
import {initialState} from "../initialState";

const logout_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {...state, logout: SUCCESS};
    case LOGOUT_FAILURE:
      return {...state, logout: FAILURE};
    case RESET:
      return {...state, logout: null};
    default:
      return state;
  }
};

export default logout_reducer;
