import { CLEAR_LEAD_FORM, SEND_LEAD_FORM } from "../../redux-flow/arsVariables";

const sendLeadForm = payload => ({
    type: SEND_LEAD_FORM,
    payload
});

export const clearLeadForm = () => ({
    type: CLEAR_LEAD_FORM,
})

export default sendLeadForm;
