import React, { Fragment, useMemo } from 'react';

export const Input = ({ value, required = true, placeholder = "", type = "text", Onchange, OnBlur = () => { }, id, name, className="", ctnClassName="", min="1", max="40"}) => {
    const isIOS = useMemo(()=>{
        return /iPhone/.test(navigator.userAgent);
    },[]) 
    return (
        <Fragment>
            {(type !== "checkbox") &&
                <div className="form-group col-lg-6 col-md-12 col-sm-12 landing-input">
                    <label htmlFor={id}>{name}</label>
                    <input data-testid={id} required={required} className={`form-control ${className}`} minLength={min} maxLength={max}
                    placeholder={placeholder} type={type} id={id} name={name} value={value} 
                    onChange={Onchange} />
                </div>
            }
            {type === "checkbox" &&
                <div className={`form-check landing-input ${ctnClassName}`}>
                    <input data-testid={id} type="checkbox" name={name} value={value} className={`form-check-input ${isIOS? 'ios-checbox': ''}`} id={id} onChange={Onchange} />
                    <label className={`form-check-label chl ${isIOS? 'ios-form-check-label' : '' }`} htmlFor={id}>{name}</label>
                </div>
            }
        </Fragment>
    )
}

export const SelectInput = ({ value, required = true, placeholder = "", Onchange, OnBlur = () => { }, id, name, options=[],  ctnClassName="" }) => {
    
    return (<div className={`form-group landing-input ${ctnClassName}`}>
    <label htmlFor={id}>{name}</label>
    <select placeholder={placeholder} onChange={Onchange} value={value} required={required} className="form-control" id={id} data-testid={id}>
        <option className="placeholder" value="">{placeholder}</option>
        {options.map(({ value, label }, index) => { return <option key={`${index}-${value}`} value={value}>{label}</option> })}
    </select>
</div>)
}

export default Input;
