import { GET_FLEETS_FAILURE, GET_FLEETS_SUCCESS } from "../arsVariables";
import { initialState } from "../initialState";

const getFleets_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_FLEETS_SUCCESS:
            return {
                ...state,
                fleets: payloadReturned,
                fleetsLength: totalCount
            };
        case GET_FLEETS_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default getFleets_reducer;
