import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    SUCCESS,
    FAILURE,
    RESET
} from "../arsVariables";

const login_reducer = (state = {}, action) => {
    const { type, role, decodedData, message, permissions, modules } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: SUCCESS,
                role: role,
                decodedData: decodedData,
                permissions,
                modules
            };
        case LOGOUT_SUCCESS: return { }
        case LOGIN_FAILURE:
            return {
                ...state,
                login: FAILURE,
                loginErrorMessage: message
            };
        case RESET:
            return {
                ...state,
                role: role,
                decodedData: decodedData,
                login: null,
                data: null
            };
        default:
            return state;
    }
};

export default login_reducer;
