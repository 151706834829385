import {call, put, takeLatest} from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {encryptAndStore} from "../../redux-flow/services/localStorageHelper";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_USER,
  ENCRYPT_USER,
  // SUPER_ADMIN,
} from "../arsVariables";
import {Actions} from "../actions/_index";
// import {url} from "../../routes";
import jwtDecode from "jwt-decode";
import { url } from "../../route";

function* loginUser(payload) {
  const {email} = payload.payload;
  try {
    yield put(Actions.uiStart());
    const returnedData = yield call(Services.loginService, payload);
    const {data} = returnedData.data;
    const {token, type, permissions, modules = [], description, firstName, ...rest} = data;
    const decoded = { ...jwtDecode(token), firstName };

    // minor hack for Interswitch super-admin user
    // if ((modules.length === 0) && (type === 'OWNER_USER') && permissions.includes('OWNER_SUPERADMIN')) {
    //   modules.push("CONTRACT_MANAGEMENT", "FORECOURT_AUTOMATION", "QUICKTELLER_FUEL");
    // }

    if (returnedData.error === false && returnedData.status === 200) {
      localStorage.setItem("email", email);
      encryptAndStore(
        ENCRYPT_USER,
        {
          access_token: token,
          url,
          permissions,
          decoded,
          modules,
          type,
          firstName,
          ...rest,
        },
        true
      );
      yield put({
        type: LOGIN_SUCCESS,
        role: type,
        permissions,
        modules,
        decodedData: decoded,
      });
    }
  } catch (err) {
    yield put(Actions.uiStop());
    return yield put({
      type: LOGIN_FAILURE,
      message:
        (err.response.response?
          err.response.response.data.status.message ||
        err.response.response.data.data
        :err.response.message)
    });
  }
}

export default function* loginUserSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
}
