import {
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    SUCCESS,
    FAILURE,
    RESET
} from "../../../redux-flow/arsVariables";
import { initialState } from "../../initialState";

const deleteUser_reducer = (state = initialState, action) => {
    const { type, message } = action;
    switch (type) {
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                delete_user: SUCCESS,
                delete_user_data: message
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                delete_user: FAILURE,
                delete_user_data: message ? message : "Bad Network Connectivity"
            };

        case RESET:
            return {
                ...state,
                delete_user: null,
                delete_user_data: null
            };
        default:
            return state;
    }
};

export default deleteUser_reducer;
