import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { NotifyClose } from "../reuse/notify";
import Graph from "../reuse/graph";
import DatePicker, { pastDate, currentDate2 } from "../reuse/datePicker";
import { Spin } from "antd";
import { formatMoney, formatNumber } from "../reuse/currencyHelper";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
import { PropsContext } from "../reuse/PropsContext";

class Dashboard extends BaseComponent {
    state = {
        currentPage: 1,
        from: pastDate,
        to: currentDate2
    };

    componentDidMount() {
        const { from, to } = this.state;
        this.fetchByDate({
            from,
            to
        });
    }

    fetchByDate = ({ from, to }) => {
        const { dispatch } = this.props;
        dispatch(
            Actions.getISWOrderStats({
                from,
                to
            })
        );
        dispatch(Actions.getISWProfileStats());
    };

    componentWillUnmount() {
        NotifyClose();
    }

    dateExtractor = ({ past, today }) => {
        this.setState(
            {
                from: past,
                to: today
            },
            () =>
                this.fetchByDate({
                    from: past,
                    to: today
                })
        );
    };
    render() {
        const { orderStatistics, profileStatistics } = this.props;
        const { from, to } = this.state;
        const {
            cancelledOrderAmount,
            cancelledOrderCount,
            cancelledOrderQuantity,
            pendingDeliveryAmount,
            pendingDeliveryCount,
            pendingDeliveryQuantity,
            pendingVendorApprovalCount,
            pendingVendorApprovalQuantity,
            pendingVendorApprovalAmount,
            orderCount,
            orderQuantity,
            orderAmount
        } = orderStatistics;

        const {
            profileCount,
            inactiveCustomerCount,
            activeCustomerCount,
            inactiveVendorCount,
            activeVendorCount
        } = profileStatistics;

        const chartGen = ({ label, graphType }) => (
            <div className="col-12 mb-4">
                <div className="card h-100 shadow-sm border-0 graph-container">
                    <div className="isw-card-header p-3">
                        <h3 className="isw-card-h3">Total Product purchased</h3>
                        <div className="isw-card-p">{label}</div>
                    </div>
                    <Graph graphType={graphType} from={from} to={to} />
                </div>
            </div>
        );

        return (
            <Fragment>
                <Sidebar
                    search={this.state.SearchKeyword}
                    status={this.context.status}
                    toggleModal={this.context.toggleModal}
                />
                <div className="isw-mainLayout1">
                    <Spin tip="Please wait..." spinning={this.context.loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar />
                            <div>
                                <div className="content" id="content-body">
                                    <div className="container-fluid container-limited">
                                        {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <h1>Dashboard</h1>
                                                </li>
                                            </ol>
                                        </nav> */}

                                        <DatePicker
                                            dateExtractor={this.dateExtractor}
                                        />

                                        <div className="row">
                                            <div className="col-lg-9 col-12">
                                                <div className="row">
                                                    <div className="col-lg-5 mb-4">
                                                        <div className="card shadow-sm border-0">
                                                            <div className="card-body">
                                                                <div className="isw-card-header mb-2">
                                                                    <h3 className="isw-card-h3">
                                                                        Total
                                                                        Amount
                                                                    </h3>
                                                                    <div className="isw-card-p" />
                                                                </div>
                                                                <div className="d-inline">
                                                                    <h4 className="isw-user-section-title mb-1 text-success">
                                                                        {formatMoney(
                                                                            orderAmount
                                                                        )}
                                                                    </h4>
                                                                    <div className="isw-p2">
                                                                        Ordered
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 mb-4">
                                                        <div className="card shadow-sm border-0">
                                                            <div className="card-body">
                                                                <div className="isw-card-header mb-2">
                                                                    <h3 className="isw-card-h3">
                                                                        No of
                                                                        Orders
                                                                    </h3>
                                                                    <div className="isw-card-p" />
                                                                </div>

                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-inline">
                                                                        <h4 className="isw-user-section-title mb-1">
                                                                            {
                                                                                orderCount
                                                                            }
                                                                        </h4>
                                                                        <div className="isw-p2">
                                                                            Total
                                                                            Number
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-inline">
                                                                        <h4 className="isw-user-section-title mb-1">
                                                                            {formatNumber(
                                                                                orderQuantity
                                                                            )}
                                                                            <sub
                                                                                className="text-muted"
                                                                                style={{
                                                                                    fontSize: `${0.5}em`
                                                                                }}>
                                                                                Litres
                                                                            </sub>
                                                                        </h4>
                                                                        <div className="isw-p2">
                                                                            Volume
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {chartGen({
                                                        label: "By Location",
                                                        graphType: "locations"
                                                    })}
                                                    {chartGen({
                                                        label: "By Customer",
                                                        graphType: "customers"
                                                    })}
                                                    {chartGen({
                                                        label: "By Vendor",
                                                        graphType: "vendors"
                                                    })}
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-12">
                                                <div className="row">
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Pending Deliveries",
                                                        quantity: pendingDeliveryQuantity,
                                                        amount: pendingDeliveryAmount,
                                                        count: pendingDeliveryCount
                                                    })}
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Cancelled Orders",
                                                        count: cancelledOrderCount,
                                                        quantity: cancelledOrderQuantity,
                                                        amount: cancelledOrderAmount
                                                    })}

                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "orders pending vendor approval",
                                                        quantity: pendingVendorApprovalQuantity,
                                                        amount: pendingVendorApprovalAmount,
                                                        count: pendingVendorApprovalCount
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        header: "Users",
                                                        count: profileCount
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        header:
                                                            "Active Customers",
                                                        count: activeCustomerCount
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        header:
                                                            "Inactive Customers",
                                                        count: inactiveCustomerCount
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        header:
                                                            "Active Vendors",
                                                        count: activeVendorCount
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        header:
                                                            "Inactive Vendors",
                                                        count: inactiveVendorCount
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}
Dashboard.contextType = PropsContext;

const mapStateToProps = ({
    getISWOrderStats_reducer,
    getISWProfileStats_reducer,
}) => {
    const { orderStatistics } = getISWOrderStats_reducer;
    const { profileStatistics } = getISWProfileStats_reducer;
    return {
        orderStatistics,
        profileStatistics,
    };
};

export default connect(mapStateToProps)(Dashboard);
