import React, { Fragment, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { IconAddContact } from "../reuse/svgs";

import { Spin } from "antd";
import { NotifyClose } from "../reuse/notify";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
import { PropsContext } from "../reuse/PropsContext";

const CreateVendor = lazy(() => import("../components/Vendor/Create_Vendor"));

class Vendors extends BaseComponent {
  state = {
    currentPage: 1,
    dataPerPage: 10,
    dropdownUserId: null,
    SearchKeyword: "",
  };

  componentWillUnmount() {
    NotifyClose();
  }

  componentDidMount() {
    const { currentPage: pageIndex, dataPerPage: pageSize } = this.state;
    this.fetch({ pageIndex, pageSize });
  }

  fetch = ({ pageIndex, pageSize, SearchKeyword = "" }) => {
    this.props.dispatch(
      Actions.getVendors({
        pageIndex,
        pageSize,
        SearchKeyword,
      })
    );
  };

  getBySearch = (e, empty) => {
    e.preventDefault();
    const { dataPerPage: pageSize, SearchKeyword } = this.state;
    this.setState({
      SearchKeyword: empty ? "" : SearchKeyword,
    });
    this.props.dispatch(
      Actions.getVendors({
        pageIndex: 1,
        pageSize,
        SearchKeyword: empty ? "" : SearchKeyword,
      })
    );
  };

  render() {
    const { SearchKeyword, dropdownUserId } = this.state;

    const { vendors, vendorsLength } = this.props;
    const { toggleRightSidebar, status, toggleModal, loading, userId } =
      this.context;

    return (
      <Fragment>
        <div className="isw-mainLayout1">
          <Sidebar
            resetSearch={this.getBySearch}
            search={this.state.SearchKeyword}
            status={status}
            toggleModal={toggleModal}
          />
          <div />
          <Spin tip="Please wait..." spinning={loading}>
            <div className="isw-content--wrapper">
              <MenuBar
                title="Vendors"
                currentText={SearchKeyword}
                onChangeHandler={this.handleSearch}
                onSubmitHandler={this.getBySearch}
              />
              <div>
                <div className="content" id="content-body">
                  <div className={`container-fluid container-limited`}>
                    <div className="row my-4">
                      <div className="col-lg-9 isw-valign--middle mr-auto">
                        {this.renderPageTracker(vendorsLength)}
                      </div>
                      {/* <form
                                                className="ml-auto col-5"
                                                onSubmit={this.getBySearch}>
                                                <input
                                                    value={SearchKeyword}
                                                    onChange={this.handleSearch}
                                                    onBlur={this.handleSearch}
                                                    className="isw-input-search"
                                                    placeholder="Search"
                                                />
                                            </form> */}
                      <div
                        className="col-lg-3 text-right"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <button className="isw-btn isw-btn--raised bg-primary text-white w-100">
                          <IconAddContact />
                          <span>Add Vendor</span>
                        </button>
                      </div>
                    </div>
                    {this.renderChoiceModal({
                      header: "Create Vendor",
                      others: "vendor",
                      id: "vendors",
                    })}

                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="row mb-4 mt-3">
                          <div className="col-12">
                            <div className="isw-table">
                              {this.renderAdminTableHeader()}
                              {vendorsLength === 0 ? (
                                this.renderTableAbnormality(
                                  `No vendor(s) created`
                                )
                              ) : !vendorsLength ? (
                                this.renderTableAbnormality()
                              ) : (
                                <ul className="isw-table--body">
                                  {vendors.map((vendor) => {
                                    const {
                                      activated,
                                      phoneNo,
                                      title,
                                      id,
                                      emailAddress,
                                      vendorNumber,
                                    } = vendor;
                                    return this.renderAdminTableContents({
                                      activated,
                                      phoneNumber: phoneNo,
                                      companyName: title,
                                      id,
                                      vendorNumber,
                                      email: emailAddress,
                                      component: "vendors",
                                    });
                                  })}
                                </ul>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        {this.renderPagination(vendorsLength)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
        <Suspense fallback={<Spin spinning={true} tip="Loading..." />}>
          <CreateVendor
            toggleRightSidebar={toggleRightSidebar}
            dropdownUserId={dropdownUserId}
            userId={userId}
          />
        </Suspense>
      </Fragment>
    );
  }
}
Vendors.contextType = PropsContext;
const mapStateToProps = ({ getVendors_reducer }) => {
  const { vendors, vendorsLength } = getVendors_reducer;
  return {
    vendors,
    vendorsLength,
  };
};

export default connect(mapStateToProps)(Vendors);
