// fakeAuth.js

export const fakeAuth = {
    isAuthenticated: false,
    authenticate() {
      return (this.isAuthenticated = true);
    },
    signout() {
      return (this.isAuthenticated = false);
    },
  };
  
//   export default fakeAuth;