import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
    SEND_LEAD_FORM,
    SEND_LEAD_FORM_FAILURE,
    SEND_LEAD_FORM_SUCCESS
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { errorHandler } from "../../reuse/util";

function* sendLeadForm({ payload }) {
     try {
        yield put(Actions.uiStart());
        const {
            data: { status: { description, httpCode } }
        } = yield call(Services.submitLeadFormService, payload);
        
        yield put(Actions.uiStop());
        if (httpCode === 200) {
            return yield put({
                type: SEND_LEAD_FORM_SUCCESS,
                description,
                httpCode
            });
        } else if (httpCode !== 200) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: SEND_LEAD_FORM_FAILURE
            });
        }
      } catch (err) {
        Notify({
            message: errorHandler(err),
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: SEND_LEAD_FORM_FAILURE
        });
    }
}

export default function* sendLeadFormSaga() {
    yield takeLatest(SEND_LEAD_FORM, sendLeadForm);
}

export { sendLeadForm };