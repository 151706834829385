import {
    GET_VENDOR_INFO_FAILURE,
    GET_VENDOR_INFO_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getVendorInfo_reducer = (state = initialState, action) => {
    const { type, payloadReturned } = action;
    switch (type) {
        case GET_VENDOR_INFO_SUCCESS:
            return {
                ...state,
                vendor_info: payloadReturned
            };
        case GET_VENDOR_INFO_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getVendorInfo_reducer;
