import React from "react";
export const faqDoc = [
    {
        question: "What is Interswitch Energy?",
        answer: {
            header: "ISW Energy is a suite of solutions created for the oil and gas (downstream) sector to provide seamless automation for; cash payments, forecourt monitoring, fleet management and bulk order management, for the benefit of every stakeholder in the energy value chain.",
            subHeader: "It consists of the following modules:​",
            list: ["Forecourt Automation", "Contract Management","QuickEnergy​"],
            footer: ""
        }
    },
    {
        question: "Who is ISW Energy for?",
        answer: {
            header: "ISW Energy offers benefits to the following types of users:",
            subHeader: "Oil marketers​",
            list: ["Station owners", "Large corporates","Fleet companies and​", "Individuals​"],
            footer: ""
        }
    },
    {
        question: "How can my Business sign up for ISW Energy?",
        answer: {
            header: "ISW Energy offerings are specialized and customizable to fit your business module. Get in touch with our sales team from our landing page to get started.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "I have an Oil & Gas Business (oil marketers & station owners), what benefits are applicable to me?",
        answer: {
            header: "ISW Energy enables you to introduce self-service and other digital solutions to your oil and gas retail operations to activate a new level of customer acquisition, loyalty, and revenue flow.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "As a Corporate, what benefits are applicable to me?",
        answer: {
            header: "For Corporations, ISW Energy offers self-service and convenience through multi-channel payments, access to multiple suppliers, competitive product pricing and bulk order placement.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What is Forecourt Automation?",
        answer: {
            header: "Forecourt Automation is a wireless 4th generation self-service that supports provisioning over various pumps, automated tank gauges (ATG) and various payment methods, including CASH, POS, QR, USSD, PAYCODE, WALLETS, VOUCHERS and REWARDS. The system uses an external terminal, an intermediate controller (external or embedded in the terminal), a local server, a cloud server and an Automated Vehicle Identification System (AVIS) for interacting with pumps, to authorize transactions automatically upon payment. ",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What is Contract Management?",
        answer: {
            header: `The Contract Management Solution is a smart contract platform that helps Oil Merchants and corporates manage large contracts seamlessly, creating superior operational experiences for the vendors and corporates across all points of interaction.  ​
            It is customizable for all parties to the contract such that local operational excellence is delivered to each stakeholder while upholding the terms and nature of the contracts.`,
            subHeader: "​",
            list: [],
            footer: ""
        }
    },

    {
        question: "What is QuickEnergy?",
        answer: {
            header: "Quick Energy Delivery is a Diesel distribution platform that digitizes the delivery process, serving key customer segments",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "How can I automate my filling stations?",
        answer: {
            header: "By getting across to an Interswitch Business or sales representative to discuss the process with you.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },

    {
        question: "How can I automate my filling stations?",
        answer: {
            header: "By getting across to an Interswitch Business or sales representative to discuss the process with you.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "Who is a Vendor?",
        answer: {
            header: "A vendor is an Oil Major / Independent marketer with fuel stations. They offer credit services to customers to use in purchasing petroleum products from your stations. This credit is expressed as the limit on the system and tells the amount of product a customer can access within a period on credit",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "Who is the customer on the platform?",
        answer: {
            header: "This is a corporate, fleet company/transporter.  The customer is the entity which uses credit as assigned/approved by a vendor",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What is limit?",
        answer: {
            header: "This is the amount assigned to a customer on credit. It is used in calculating the quantity of products used by a customer (customers’ drivers, trucks or vehicles).",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What is a contract?",
        answer: {
            header: `This is the agreement that exists between a vendor and a customer. It specifies the products/product price, the duration for which the customer can access credit, and also the limit the customer can access within the duration. After a contract is established by a vendor it needs to be accepted/ approved by a customer to become active. `,
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What is a contract cycle?",
        answer: {
            header: "This is the duration of the contract, it could be daily, weekly, or monthly.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What payment channels are available for transactions?",
        answer: {
            header: "A vendor can be paid via a variety of available payment channels on the platform today. This includes Pay with transfer, card, Bank deposit pay on Quickteller.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "How do I get settled of my transactions settled via the provided payment method?",
        answer: {
            header: "All settlements are done T+1 to the provided account details of the vendor.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "How do I decide on the appropriate solution for my business?",
        answer: {
            header: "Our sales agents are available to recommend the appropriate solution for your business size and type based on our available solutions. To speak with any of our agents, kindly click on this link, SmartFuel (interswitchng.com), select the “Talk to Sales” button top right of the page, and fill out the form appropriately.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "What are the cost implication of onboarding this service.",
        answer: {
            header: "The Smartfuel pricing model can be classified into 3 main aspects:",
            subHeader: "​",
            list: ["Subscription Model (FCA Deployment): 0 – 4 Pumps: ₦10,000 per month​, 5 – 8 Pumps: ₦15,000 per month​, Above 8 Pumps: ₦30,000 per month​​", "Transaction Model: 0.68% Regulatory MSC Transaction ​ Fee (borne by Bank)", " SmartFuel Gauging System Deployment: SGS Probe (1 per Tank)- ₦438,000​, SGS Controller (1 per Retail Outlet)- ₦210,000."],
            footer: "Fees are exclusive of VAT & Shipping Charges and are subject to changes in Exchange Rates.​"
        }
    },
    {
        question: "As a Vendor, how do I get a POS terminal to run my transactions?",
        answer: {
            header: "To acquire a POS terminal for your transactions as a vendor kindly speak to any of our sales agents or support persons to get insight on prices and how to acquire terminals for your business.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "If I forget my password, how do I change it or get a new one?",
        answer: {
            header: `If you have forgotten your password and need to reset your password, click the link. (an email is sent to your email which you will use to complete the process). Upon selecting “forgot password", go through the process or you can navigate to  ${<a href="https://passport.interswitchng.com/passport/login">"https://passport.interswitchng.com/passport/login</a>} to complete the process.`,
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "Would I as a User get appropriate training on how to use the platform?",
        answer: {
            header: "The team would ensure to provide appropriate training, product manuals and training demos to aid the users in navigating the platforms easily and skilfully till they are well familiarized with the platform.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    {
        question: "Who do I reach out to in case I need support?",
        answer: {
            header: "All users can access help via all of our designated helplines or go to Interswitch - Customer Support Portal (interswitchgroup.com) to log your complaint.",
            subHeader: "​",
            list: [],
            footer: ""
        }
    },
    
]