import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
    GET_CUSTOMER_INFO_FAILURE,
    GET_CUSTOMER_INFO_SUCCESS,
    GET_CUSTOMER_INFO
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { history } from "../../reuse/history";
import { message } from "antd";
function* getCustomerInfo(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, payload: payloadReturned, totalCount }
        } = yield call(Services.getCustomerInfoService, payload);
        yield put(Actions.uiStop());
        if (code !== 1) {
            const { pathname } = history.location;
            const url = pathname.split("/");
            message.error(description);
            setTimeout(() => {
                history.push({ pathname: `/${url[1]}/${url[2]}` });
            }, 1500);
            return yield put({
                type: GET_CUSTOMER_INFO_FAILURE
            });
        } else if (code === 1) {
            return yield put({
                type: GET_CUSTOMER_INFO_SUCCESS,
                payloadReturned,
                totalCount
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CUSTOMER_INFO_FAILURE
        });
    }
}

export default function* getCustomerInfoSaga() {
    yield takeLatest(GET_CUSTOMER_INFO, getCustomerInfo);
}
