import { baseurl } from "./authServices";
import { postFunc } from "./httpService";

// delete user
export const deleteUserService = ({ payload }, ) => {
    return postFunc(`${baseurl}ISW/DeleteUser`, payload);
};

// toggle user status
export const toggleUserStatusService = ({ payload }, ) => {
    const { userId, activationStatus, page } = payload;
    const objToSend =
        page === "customers"
            ? { customerId: userId, activationStatus }
            : page === "vendors"
            ? { vendorId: userId, activationStatus }
            : page === "fleet"
            ? { fleetId: userId }
            : { userId, activationStatus };
    return postFunc(
        page === "customers"
            ? `${baseurl}ISW/DisableCustomer`
            : page === "vendors"
            ? `${baseurl}ISW/DisableVendor`
            : page === "fleet"
            ? `${baseurl}ISW/DisableFleet`
            : `${baseurl}ISW/DisableUser`,
        objToSend
    );
};
