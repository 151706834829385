import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Modal, Button, Spin } from 'antd';
import Input, { SelectInput } from '../../component/Input/input';
import { refinedState } from '../../nigeria-states';
import { Actions } from '../../../redux-flow/actions/_index';
import { Notify } from "../../../reuse/notify";
import  SuccessIcon from "../../../assets/images/bx-check-circle.svg";


export const LeadForm  = ({ handleForm }) => {
    const [fullName, setFullName] = useState();
    const [email, setEmail] = useState();
    const [companyName, setCompanyName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [ solutionOfInterest, setSolutions] = useState([]);
    const [state, setState ] = useState("");
    const dispatch = useDispatch();
    const { desctiption, httpCode } = useSelector(state => state.submitLeadForm_reducer, shallowEqual);
    const { loading } = useSelector(state => state.ui_reducer, shallowEqual);
    const handleSubmit = (e) => {
        e.preventDefault();
        if(solutionOfInterest.length <= 0){
            Notify({ message:"you have to select a solution", className: "error"});
            return;
        }
        let firstName = "";
        let lastName= "";
        let arr = fullName && fullName.length >=1? fullName.split(" "): [];
        if(arr.length>=1){
            firstName = arr[0];
            lastName = arr[arr.length - 1];
        }
        let payload = {
            firstName,
             lastName,
            companyName,
            email,
            phoneNumber,
            solutionOfInterest,
            state
        }
        dispatch(Actions.sendLeadForm({payload}));
    }
    const closeLeadForm = () =>{
        handleForm();
        dispatch(Actions.clearLeadForm());
    }
    const handleChecked=(e)=>{
        let temp = [...solutionOfInterest];
        const valueIndex = temp.findIndex(x => x === e.target.value);
        if(valueIndex >= 0)  temp.splice(valueIndex, 1);
        else temp.push(e.target.value);
        setSolutions(temp)
    }
       

    return (<div className="lead-form-container">
        <Modal
            visible={!httpCode}
            maskClosable={true}
            closeIcon={<></>}
            centered
            className="lead-form-modal"
            footer={null}
        >
            <Spin spinning={loading}>
            <div className="row lead-modal-container">
                <div className="col-12 text-center mb-2">
                    <h1 className="lead-title">Get Started</h1>
                    <p className="lead-caption">Kindly enter your details below and we<br className="d-mobile"/> would reach out to you</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <Input
                            name="Name"
                            id="Name"
                            placeholder="Enter Firstname Lastname"
                            value={fullName}
                            Onchange={(e) => setFullName(e.target.value)}
                            // ctnClassName="pl-0"
                        />
                        <Input
                            name="Email"
                            id="Email"
                            type="email"
                            placeholder="Enter your Email Address"
                            value={email}
                            Onchange={(e) => setEmail(e.target.value)}
                            // ctnClassName="pl-0"
                        />
                    </div>
                    <div className="row">
                        <Input
                            name="Phone Number"
                            id="phoneNumber"
                            placeholder="Enter your Phone Number"
                            value={phoneNumber}
                            max={"11"}
                            min={'8'}
                            Onchange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <Input
                            name="Company Name"
                            id="companyName"
                            placeholder="Your company or organisation"
                            value={companyName}
                            Onchange={(e) => setCompanyName(e.target.value)}
                        />
                        <fieldset className="ml-4 mt-1">
                            <legend>Select Solution</legend>
                            <Input
                                name="Forecourt Automation"
                                id="forCourt"
                                value="Forecourt Automation"
                                type="checkbox"
                                Onchange={handleChecked}
                            />
                            <Input
                                name="Contract Management"
                                id="contractMgt"
                                value="Contract Management"
                                type="checkbox"
                                ctnClassName="mt-2"
                                Onchange={handleChecked}
                            />
                            <Input
                                name="QuickEnergy (I am a Diesel Supplier)"
                                id="quickEnergy"
                                value="QuickEnergy"
                                type="checkbox"
                                ctnClassName="mt-2"
                                Onchange={handleChecked}
                            />
                        </fieldset>
                        <div className="col-12 mt-4">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <SelectInput
                                        name="State"
                                        id="state"
                                        placeholder="Select State"
                                        options={refinedState}
                                        value={state}
                                        Onchange={(e)=>setState(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lead-form-btn-ctn">
                        <Button onClick={handleForm} className="lead-form-button cancel" key="back">
                            Close
                        </Button>
                        <button data-testid="submit" className="lead-form-button" htmlType="submit" key="submit" type="primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            </Spin>
        </Modal>
        <Modal
            visible={httpCode? true : false}
            closeIcon={<></>}
            centered
            width={355}
            footer={null}
        >
            <div className="lead-succes-modal">
                <img src={SuccessIcon} alt="Success Icon" />
                <h2>Thanks for contacting us!</h2>
                <span>We would reach out to you</span>
                {desctiption && <span>
                    {desctiption}
                </span>}
                <Button onClick={closeLeadForm} data-testid="close-form" className="lead-form-button mt-4" key="submit" type="primary">
                  Continue
                </Button>
            </div>
        </Modal>
    </div>)
};

export default LeadForm;
