import React, { useState, useRef, useEffect } from "react";
import "./faq.css";
import { faqDoc } from "../../../reuse/faqDoc";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef(faqDoc.map(() => React.createRef()));

  useEffect(() => {
    contentRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.style.maxHeight =
          activeIndex === index ? `${ref.current.scrollHeight}px` : "0px";
      }
    });
  }, [activeIndex]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq_container">
      <h3>Frequently Asked Questions</h3>

      <div>
        {faqDoc.map((doc, index) => (
          <button
            className={`question-section ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => toggleAccordion(index)}
            key={index}
          >
            <div>
              <div className="question-align">
                <h4 className="question-style" data-testid={`question-${index}`}>
                  {doc.question}
                </h4>
                <div
                  className={
                    activeIndex === index
                      ? `question-icon rotate`
                      : `question-icon`
                  }
                >
                  +
                </div>
              </div>
              <div
                ref={contentRefs.current[index]}
                className={
                  activeIndex === index ? `answer answer-divider` : `answer`
                }
                data-testid={`answer-${index}`}
              >
                <p>{doc.answer.header}</p>
                {doc.answer.subHeader && <p>{doc.answer.subHeader}</p>}
                {doc.answer.list.length > 0 && (
                  <ul>
                    {doc.answer.list.map((listItem, listItemIndex) => (
                      <li key={listItemIndex}>{listItem}</li>
                    ))}
                  </ul>
                )}

                {doc.answer.footer && <p>{doc.answer.footer}</p>}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Faq;
