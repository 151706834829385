import { getFunc, postFunc } from "./httpService";
export const qs = require("qs");
export const baseurl = window.env.REACT_APP_BASE_AUTH_URL
  ? window.env.REACT_APP_BASE_AUTH_URL
  : "https://uber-for-diesel-ag-auth-service.k8.isw.la/u4da/api/";
export const otherUrl = "";
const client_id = window.env.REACT_APP_ISW_CLIENT_ID
  ? window.env.REACT_APP_ISW_CLIENT_ID
  : "smartfuelsuperadmin";
const client_secret = window.env.REACT_APP_ISW_CLIENT_KEY
  ? window.env.REACT_APP_ISW_CLIENT_KEY
  : "2C267211-49E7-4F10-AF84-92DE23509258";

export const forgotPasswordUrl =
  window.env.REACT_APP_FORGOT_PASSWORD_URL ||
  "https://passport-v2.k8.isw.la/passport/accounts/recovery";
// : "https://passport.interswitchng.com/passport/accounts/recovery";

export const authUrl = window.env.REACT_APP_BASE_AGG_URL;

export const reportUrl = window.env.REACT_APP_BASE_REPORT_URL;
// Login super admin
export const loginService = ({ payload }) => {
  const { email, password } = payload;
  const objectToSend = {
    userEmail: email,
    secret: password
    // grant_type: "password",
    // client_id: client_id,
    // client_secret: client_secret,
    // scope: "openid profile email roles offline_access",
  };

  return postFunc(`${authUrl}auth/userlogin`, objectToSend);
};

// refresh Token
export const refreshTokenService = token => {
  const objectToSend = qs.stringify({
    grant_type: "refresh_token",
    client_id: client_id,
    client_secret: client_secret,
    refresh_token: token
  });

  return postFunc(`${baseurl}auth/token`, objectToSend);
};

// reset password
export const resetPasswordService = ({ payload }) => {
  return postFunc(`${baseurl}Auth/ResetPasssword`, payload);
};

// change password
export const changePasswordService = ({ payload }) => {
  const { currentPW, password, rtPassword } = payload;
  const objectToSend = {
    currentPassword: currentPW,
    newPassword: password,
    confirmPassword: rtPassword
  };

  return postFunc(`${baseurl}Auth/ChangePassword`, objectToSend);
};

// forgot password
export const forgotPasswordService = ({ payload }) => {
  return getFunc(`${baseurl}Auth/ForgotPassword?${qs.stringify(payload)}`);
};

export const submitLeadFormService = ({ payload }) => {
  return postFunc(`${authUrl}enquiries/sale`, payload);
};
