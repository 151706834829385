import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
const url = "/isw/";
class Sidebar extends Component {
    assignActive = path => {
        
        return window.location.pathname === path ||
            window.location.pathname.includes(path)
            ? "isw-sideBarList active"
            : "isw-sideBarList";
    };

    render() {
        const {
            status,
            toggleModal,
            search,
            resetSearch,
            dispatch
        } = this.props;

        const listGen = (path, icon, label) => (
            <li
                className={this.assignActive(`${url}${path}`)}
                onClick={e => {
                    dispatch(Actions.toggleRightSidebar("remove"));
                    if (search !== undefined && search.length > 0)
                        return resetSearch(e, true);
                }}>
                <NavLink
                    activeClassName={""}
                    to={`${url}${path}`}
                    className="isw-sideBarNavLink">
                    <span className="isw-sideBarIcon">
                        <i className="material-icons isw-sideBarIconFont">
                            {icon}
                        </i>
                    </span>
                    <span className="isw-sideBarNavLabel">{label}</span>
                </NavLink>
            </li>
        );

        return (
            <Fragment>
                <aside
                    className={`isw-sideBarQuick123 ${
                        status === "open" ? "openModal" : ""
                    }`}
                    id="side-nav"
                    onClick={() => toggleModal("close")}>
                    <div className="isw-sideBarNav">
                        <ul className="isw-ul">
                            {listGen("dashboard", "dashboard", "Dashboard")}
                            {listGen(
                                "customers",
                                "supervised_user_circle",
                                "Customers"
                            )}
                            {listGen("vendors", "local_gas_station", "Vendors")}
                            {listGen(
                                "fleets",
                                "local_shipping",
                                "Fleet Owners"
                            )}
                            {listGen(
                                "system-users",
                                "local_gas_station",
                                "System Users"
                            )}
                            {listGen("audits", "handyman", "Audit Trail")} 
                            {listGen("reports", "assignment", "Report")}
                        </ul>
                    </div>
                </aside>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { decodedData: decodedFromLogin } = state.login_reducer;
    const { decodedData: decodedFromStorage } = state.storage_reducer;

    return {
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage
    };
};

export default connect(mapStateToProps)(Sidebar);
