import { GET_CUSTOMERS_FAILURE, GET_CUSTOMERS_SUCCESS } from "../arsVariables";
import { initialState } from "../initialState";

const getCustomers_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: payloadReturned,
                customersLength: totalCount
            };
        case GET_CUSTOMERS_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default getCustomers_reducer;
