import React, { useEffect, useMemo} from "react";
import {useDispatch, useSelector}from "react-redux";
import { Actions } from "../../redux-flow/actions/_index";
import { SUCCESS, FAILURE } from "../../redux-flow/arsVariables";
import {UserSvg} from "../../assets/Svgs/Svgs";
import { history } from "../../reuse/history";
// import { createBrowserHistory } from "history";
import Ndpr from "../../reuse/Ndpr";
// import { url } from "../../App";
import { EnergyLogoIcon } from "../../refresh/svgIcons";
import { Link } from "react-router-dom";
import ROUTE_PATHS, {url} from "../../route";


const Header = (props) => {

  const { user, fakeAuth } = props || { };
  const dispatch = useDispatch();
  const logoutProps = useSelector(state => state.logout_reducer )
  const { logout } = logoutProps;

  useEffect(()=>{
    if (logout === SUCCESS && fakeAuth) {
      setTimeout(() => {
          dispatch(Actions.reset());
          fakeAuth.signout();
          history.replace({ pathname: url });
      }, 1000);
  }

  if (logout === FAILURE) return dispatch(Actions.reset());
  },[logout])

  const backPath = () => {
    switch (history.location.pathname) {  // dashboad
      case ROUTE_PATHS["quick-energy-signin"]: 
        return ROUTE_PATHS.quickEnergy_landing; 
      case ROUTE_PATHS["forecourt-signin"]:
        return ROUTE_PATHS.forecourt_landing;
      case ROUTE_PATHS["contract-management"]:
        return ROUTE_PATHS.contractMgt_landing;
      default :  return "";
    }
  }

  const decideModule = () =>{
    if(user && user.modules) {
      if(user.modules.length > 0 & user.modules.includes('CONTRACT_MANAGEMENT')){
        return 'Contract Management';
      }else if(user.modules.length & user.modules.includes('QUICKTELLER_FUEL')){
        return 'Fuel Delivery';
      }
    }
  }

  const gotoModule = () => {
    if(user.modules.length & user.modules.includes('CONTRACT_MANAGEMENT')){
      history.push({ pathname: '/contract' });
    }else if(user.modules.length & user.modules.includes('QUICKTELLER_FUEL')){
      history.push({ pathname: '/delivery' });
    }
  }

  const logOut = () =>{dispatch(Actions.logoutUser()) }
  return (
    <>

    <div className="navbar">
        <EnergyLogoIcon classname="new-logo"/>
      {user && (
         <div className="username">
          <div className="dropdown dropdown-toggle" style={{ marginRight: 30 }}>
          <span className=" d-xs-none d-sm-none d-md-inline font-weight-bold">
            Hello {user.firstName}
          </span>
          <a
                                        href="."
                                        role="button"
                                        id="dropUserNav"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ color: '#00425f', fontSize: "14px" }}
                                    >
          <UserSvg />
          </a>
          <div className="dropdown-menu dropdown-menu-right" style={{
                                        borderRadius: '0 0 0.25rem 0.25rem',
                                        marginTop: '49%', 
                                    }} aria-labelledby="dropUserNav">
                                        {/* <a className="dropdown-item" href="#">
                                        <p className="mb-0">{localStorage.getItem("name")}</p>
                                        <small>{localStorage.getItem("role")}</small>
                                        </a> */}
                                        <span
                                            onClick={gotoModule}
                                            className="dropdown-item"
                                        >
                                          Go to {decideModule()}
                                        </span>
                                       
                                        <span
                                            onClick={logOut}
                                            className="dropdown-item text-danger"
                                        >
                                            Logout
                                        </span>
                                    </div>
        </div>
        </div>
      )}
        <Link className="account-btn text-center" data-testid='back-btn' style={{
          maxWidth: 250,
          paddingTop: 7,}} to={backPath()}>Back to Product Page</Link>
    </div>
    </>
  );
};
export default Header;
