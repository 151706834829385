import React, {Fragment, Component} from "react";
import { Outlet } from 'react-router-dom';
import {connect} from "react-redux";
import {history} from "./history";
import {decryptAndRead} from "../redux-flow/services/localStorageHelper";
import {ENCRYPT_USER} from "../redux-flow/arsVariables";

class AuthLayout extends Component {
  componentDidMount() {
    const {fakeAuth, location} = this.props;
    if (
      fakeAuth.isAuthenticated &&
      !location.pathname.includes("resetpassword")
    ) {
      if (!decryptAndRead(ENCRYPT_USER).modules.includes("QUICKTELLER_FUEL")) {
        // window.location.href = "/?d=isw";
      } else history.push({pathname: "/isw/dashboard"});
    }
  }
  render() {
    // component and props passed are accessed
    // const {children, loading, isLoading, fakeAuth} = this.props;

    return (
      <Fragment>
        <div>
          <Outlet context={this.props} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ui_reducer}) => {
  const {loading, isLoading} = ui_reducer;
  return {
    loading,
    isLoading,
  };
};

export default connect(mapStateToProps)(AuthLayout);
