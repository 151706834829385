import {
    TOGGLE_SIDEBAR
} from "../../redux-flow/arsVariables";

import { initialState } from "../initialState";

const toggleSidebar_reducer = (state = initialState, action) => {
    const {type,payload}=action
    switch (type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                toggleSidebar: payload
            };

        default:
            return state;
    }
};

export default toggleSidebar_reducer;
