import React from "react";
import { notification } from "antd";
import xIcon from '../assets/images/x-icon.svg';

const key = "updatable";
export const Notify = ({ message, className, duration = 0 }) => {
    return className === "error"
        ? notification.error({
              key,
              message: <NotifcationContent message={message} type={className} />,
              duration: duration,
              className: className,
              icon: <></>,
              closeIcon: <img src={xIcon} alt="Close" />
          })
        : className === "success"
        ? notification.success({
              key,
              message: <NotifcationContent message={message} type={className} />,
              duration: duration,
              className: className,
              icon: <></>,
              closeIcon: <img src={xIcon} alt="Close" />
          })
        : notification.destroy();
};

export const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
        return <span>Previous</span>;
    }
    if (type === "next") {
        return <span>Next</span>;
    }
    return originalElement;
};


export const NotifyClose = () => Notify({ className: "close" });

const NotifcationContent = ({message, type}) => {
    return (<div className={`${type}-notify`}>
        <div className="notify-bar"></div>
        <div className="notify-content">
         <h6>{type === "error" ? "Request failed" : "Request successful"}</h6>
          <p>{message}</p>
        </div>
    </div>)
}