import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import { BULK_UPLOAD } from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* bulkUpload(payload) {

    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.bulkUploadService, payload);
        yield put(Actions.miniUiStop());

        if (code === 1) {
            yield put(Actions.uiStart());
            payload.payload.role === 2
                ? yield put(
                      Actions.getSystemUsers({ pageIndex: 1, pageSize: 10 })
                  )
                : yield put(
                      Actions.getAllUsers({
                          pageIndex: 1,
                          pageSize: 10,
                          userType: payload.payload.role,
                          userTypeId: null
                      })
                  );
            yield put(Actions.toggleRightSidebar(false));
        }
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* bulkUploadSaga() {
    yield takeLatest(BULK_UPLOAD, bulkUpload);
}
