import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PropsContext } from "./PropsContext";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const location = useLocation();
    const context = useContext(PropsContext);
    return (
        <WrappedComponent
            {...props}
            {...context}
            params={params}
            location={location}
        />
    );
}
export default withRouter;