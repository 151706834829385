import React, {Component} from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
export const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const dd = new Date().getDate();
const mm = new Date().getMonth();
const yy = new Date().getFullYear();
const currentDate = `${dd}/${monthNames[mm]}/${yy}`; // in the format 11/May/2019
const startDate = moment().subtract(29, "days");
const pastDate = startDate.format("LL");

const endDate = moment();
const currentDate2 = endDate.format("LL");

class DatePicker extends Component {
    state = {
        startDate: startDate,
        endDate: endDate
    };

    handleEvent = (event, picker) => {
        const {endDate, startDate} = picker;

        const today = moment(endDate).format("LL");
        const past = moment(startDate).format("LL");
        if (event.type === "apply") {
            this.setState(
                prevState => {
                    return {
                        ...prevState,

                        endDate,
                        startDate
                    };
                },
                () =>
                    this.props.dateExtractor({
                        today,
                        past
                    })
            );
        }
    };

    render() {
        const {startDate, endDate} = this.state;
        const dateRanges = {
            Today: [moment(), moment()],
            Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
                moment()
                    .subtract(1, "month")
                    .startOf("month"),
                moment()
                    .subtract(1, "month")
                    .endOf("month")
            ]
        };
        return (
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                showDropdowns
                ranges={dateRanges}
                opens="left"
                containerStyles={{display: "block"}}
                onEvent={this.handleEvent}>
                <div className="row">
                    <div className="ml-auto col text-right">
                        <div className="mb-3">
                            <div
                                id="reportrange"
                                className="p-2 bg-white text-primary d-inline-block rounded shadow-sm isw-hoverClick">
                                <div className="d-flex align-items-center">
                                    <i className="material-icons">date_range</i>
                                    <span className="pl-2 pr-1">{`${moment(
                                        startDate
                                    ).format("LL")} - ${moment(endDate).format(
                                        "LL"
                                    )}`}</span>
                                    <i className="material-icons">
                                        arrow_drop_down
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DateRangePicker>
        );
    }
}

export {currentDate, pastDate, currentDate2};
export default DatePicker;
