import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Actions } from "./redux-flow/actions/_index";
import { SUCCESS, FAILURE, ENCRYPT_USER } from "./redux-flow/arsVariables";

// auth
import Login from "./Auth/Login/Login";
import ResetPW from "./Auth/Reset/ResetPW";

// pages
import Customers from "./pages/Customers";
import Vendors from "./pages/Vendors";
import Dashboard from "./pages/Dashboard";
import SystemUsers from "./pages/SystemUsers";
import Fleets from "./pages/Fleets";
import Audits from "./pages/Audits";
import Reports from "./pages/Reports";

// shared
import Layout from "./reuse/layout";
import AuthLayout from "./reuse/authLayout";
import Page404 from "./reuse/404";
import Page403 from "./reuse/403";
import Page500 from "./reuse/500";
import LoadingPage from "./reuse/loading";

// css
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./assets/stylesheet/app.css";
import "./scss/kamsi.css";
import {
  clear,
  decryptAndRead
} from "./redux-flow/services/localStorageHelper";

import IdleTimer from "react-idle-timer";
import createHost from "cross-domain-storage/host";
import {
  RerouteOthers,
  RerouteToContract,
  RerouteToQtellerFuel
} from "./Reroute";
import ROUTE_PATHS, { url } from "./route";
import LandingPage from "./refresh/Views/landingpage";
import LandingLayout from "./refresh/component/landing-layout/landing-layout";
import ForeCourtLandingPage from "./refresh/Views/forecourt/forecourt-landing";
import QuickEnergyLandingPage from "./refresh/Views/quickenergy/quickenergy-landing";
import ContractManagementLandingPage from "./refresh/Views/contract-management/contract-mangement-landing";
import { history } from "./reuse/history";
import { fakeAuth } from "./fakeAuth";
import TermsAndConditions from "./refresh/Views/termsAndCondition";
import Faq from "./refresh/Views/Faq";

// lazy loading
const GeneralProfile = lazy(() => import("./components/GeneralProfile"));

createHost([
  {
    origin: window.env.REACT_APP_FRONT_VENDOR_URL,
    allowedMethods: ["get"]
  },
  {
    origin: window.env.REACT_APP_FRONT_FLEET_URL,
    allowedMethods: ["get"]
  },
  {
    origin: window.env.REACT_APP_FRONT_CUSTOMER_URL,
    allowedMethods: ["get", "remove"]
  },
  {
    origin: window.env.REACT_APP_FRONT_CONTRACT_URL,
    allowedMethods: ["get"]
  },
  {
    origin: window.env.REACT_APP_FRONT_SSO_URL,
    allowedMethods: ["get", "put"]
  }
]);

// Private router function
const PrivateRoute = ({ props }) => {
  const location = useLocation();
  if (fakeAuth.isAuthenticated && decryptAndRead(ENCRYPT_USER)) {
    const grantedModules =
      decryptAndRead(ENCRYPT_USER).modules || props.modules || [];
    if (
      grantedModules.some(m =>
        ["QUICKTELLER_FUEL", "CONTRACT_MANAGEMENT"].includes(m)
      )
    ) {
      return <Layout {...props} fakeAuth={fakeAuth} />;
    } else if (
      !grantedModules.modules.some(m =>
        ["QUICKTELLER_FUEL", "CONTRACT_MANAGEMENT"].includes(m)
      )
    ) {
      return (
        <Navigate
          to={{
            pathname: "/",
            search: "?d=isw"
          }}
        />
      );
    } else {
      return (
        <Navigate
          to={{
            pathname: url,
            state: {
              from: location,
              error: location !== url ? true : false
            }
          }}
        />
      );
    }
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
          search: "?d=isw"
        }}
      />
    );
  }
};

const AuthRoute = ({ ...rest }) => {
  let location = useLocation();
  return <AuthLayout {...rest} location={location} fakeAuth={fakeAuth} />;
};

const WardenRoute = ({
  canUseISWModule,
  canUseContractMgtModule,
  canUseQtFuelModule,
  breakVal,
  decryptedToken,
  props
}) => {
  return fakeAuth.isAuthenticated ? (
    (() => {
      // if both canUseContractMgtModule && canUseQtFuelModule are false,
      // it implies that localStorage needs to be checked again by dispatching the read-storage action
      if (!canUseContractMgtModule && !canUseQtFuelModule && breakVal === 0) {
        props.dispatch(Actions.onInit());
        breakVal++;
        return null;
      } else {
        return canUseISWModule ? (
          <Navigate push to={`${url}dashboard`} />
        ) : canUseContractMgtModule ? (
          <RerouteToContract />
        ) : canUseQtFuelModule ? (
          <RerouteToQtellerFuel
            role={
              decryptedToken.type
                ? decryptedToken.type
                : this.props.role
                ? this.props.role
                : this.props.data.role
            }
          />
        ) : (
          <RerouteOthers />
        );
      }
    })()
  ) : (
    <Navigate push to="/" />
  );
};

const ContractRoute = ({ canUseContractMgtModule }) => {
  return fakeAuth.isAuthenticated ? (
    canUseContractMgtModule ? (
      <RerouteToContract />
    ) : (
      <Navigate
        to={{
          pathname: ROUTE_PATHS["contract-management"],
          search: "?d=isw"
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: ROUTE_PATHS.contractMgt_landing
        // state: {
        //   from: props.location,
        // },
      }}
    />
  );
};

const AllRoutes = ({ props }) => {
  const location = useLocation();
  return (
    <Navigate
      to={{
        pathname: location.pathname.includes(`${url}dashboard`)
          ? "/warden"
          : `${url}#/404`
      }}
      {...props}
      push
    />
  );
};

const IswRoute = ({ props }) => {
  const location = useLocation();
  const path = localStorage.getItem("loginPath");
  return fakeAuth.isAuthenticated ? (
    <Navigate
      push
      to={{
        pathname: location.hash !== "" ? `${url}${location.hash}` : `/warden`,
        state: {
          from: location
        }
      }}
    />
  ) : (
    <Navigate
      to={{
        pathname: path ? path : "/",
        state: {
          from: location
        }
      }}
    />
  );
};

const LogoutRoute = ({ props }) => {
  let path = localStorage.getItem("loginPath");
  localStorage.clear();
  fakeAuth.signout();
  history.push(path);
  return <></>;
};

const DeliveryRoute = ({ decryptedToken, props, canUseQtFuelModule }) => {
  return fakeAuth.isAuthenticated ? (
    canUseQtFuelModule ? (
      <RerouteToQtellerFuel
        role={
          decryptedToken.type
            ? decryptedToken.type
            : props.role
            ? props.role
            : props.data.role
        }
      />
    ) : (
      <Navigate
        push
        to={{
          pathname: ROUTE_PATHS["quick-energy-signin"],
          search: "?d=isw"
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: ROUTE_PATHS.quickEnergy_landing,
        search: "?d=isw"
      }}
    />
  );
};

class App extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(Actions.onInit());
  }

  static getDerivedStateFromProps = (props, state) => {
    const { storage, dispatch /* data */ } = props;
    const { loaded } = state;

    if (storage === SUCCESS && loaded === false) {
      fakeAuth.authenticate();
      return {
        loaded: true
      };
    }

    if (storage === FAILURE && loaded === false) {
      fakeAuth.signout();
      clear();
      dispatch(Actions.reset());
      return {
        loaded: true
      };
    }

    return state;
  };

  _toggleUserStatus = (userId, activationStatus, page, pageIndex, pageSize) => {
    this.props.dispatch(
      Actions.toggleUserStatus({
        userId,
        activationStatus,
        page,
        pageIndex,
        pageSize
      })
    );
  };

  idleTimer = null;
  _onAction = e => {
    this.idleTimer.reset();
  };

  _onActive = e => {
    this.idleTimer.reset();
  };

  _onIdle = e => {
    if (this.idleTimer.isIdle() && fakeAuth.isAuthenticated) {
      fakeAuth.signout();
      clear();
      this.props.dispatch(Actions.logoutUser());
    }
  };

  render() {
    const { loaded } = this.state;
    let canUseContractMgtModule = null;
    let canUseQtFuelModule = null;
    let canUseISWModule = null;
    const decryptedToken = decryptAndRead(ENCRYPT_USER);
    if (this.props.data) {
      canUseContractMgtModule =
        this.props.data.modules.includes("CONTRACT_MANAGEMENT") ||
        (this.props.modules &&
          this.props.modules.includes("CONTRACT_MANAGEMENT"));
      canUseQtFuelModule =
        this.props.data.modules.includes("QUICKTELLER_FUEL") ||
        (this.props.modules && this.props.modules.includes("QUICKTELLER_FUEL"));
      canUseISWModule = this.props.data
        ? canUseQtFuelModule &&
          ["OWNER_ADMIN", "OWNER_USER", "SUPER_ADMIN"].includes(
            this.props.data.role
          )
        : canUseQtFuelModule &&
          ["OWNER_ADMIN", "OWNER_USER", "SUPER_ADMIN"].includes(
            this.props.role
          );
    } else if (decryptedToken) {
      canUseContractMgtModule = decryptedToken.modules.includes(
        "CONTRACT_MANAGEMENT"
      );
      canUseQtFuelModule = decryptedToken.modules.includes("QUICKTELLER_FUEL");
      canUseISWModule =
        canUseQtFuelModule &&
        ["OWNER_ADMIN", "OWNER_USER", "SUPER_ADMIN"].includes(
          decryptedToken.type
        );
    }
    let breakVal = 0; // dummy-value to prevent infinite-loop on /warden route when user has no modules at all

    if (loaded) {
      return (
        <Suspense fallback={<LoadingPage text="Loading..." />}>
          <IdleTimer
            ref={ref => (this.idleTimer = ref)}
            onActive={this._onActive}
            onIdle={this._onIdle}
            onAction={this._onAction}
            debounce={10}
            timeout={
              decryptedToken && decryptedToken.expired
                ? 1000 * 30
                : 1000 * 60 * 61
            }
          />
          <Routes>
            <Route element={<LandingLayout />}>
              <Route
                // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
                path="/"
                element={<LandingPage fakeAuth={fakeAuth} />}
              />
              <Route
                // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
                path="/terms"
                element={<TermsAndConditions />}
              />
              <Route
                // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
                path="/faq"
                element={<Faq />}
              />
              <Route
                path={ROUTE_PATHS.forecourt_landing}
                element={<ForeCourtLandingPage />}
              />
              <Route
                path={ROUTE_PATHS.quickEnergy_landing}
                element={<QuickEnergyLandingPage />}
              />
              <Route
                path={ROUTE_PATHS.contractMgt_landing}
                element={<ContractManagementLandingPage />}
              />
            </Route>
            <Route element={<AuthRoute />}>
              <Route
                exact
                path={ROUTE_PATHS["forecourt-signin"]}
                element={<Login fakeAuth={fakeAuth} />}
              />
              <Route
                exact
                path={ROUTE_PATHS["quick-energy-signin"]}
                element={<Login fakeAuth={fakeAuth} />}
              />
              <Route
                exact
                path={ROUTE_PATHS["contract-management"]}
                element={<Login fakeAuth={fakeAuth} />}
              />
              {canUseISWModule && (
                <Route path={`${url}resetpassword`} element={<ResetPW />} />
              )}
            </Route>
            <Route
              // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
              path="/logout"
              element={<LogoutRoute props={this.props} />}
            />
            <Route
              // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
              exact
              path="/delivery"
              element={
                <DeliveryRoute
                  props={this.props}
                  decryptedToken={decryptedToken}
                  canUseQtFuelModule={canUseQtFuelModule}
                />
              }
              //   component={Login}
            />

            <Route
              // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
              path="/contract"
              element={
                <ContractRoute
                  canUseContractMgtModule={canUseContractMgtModule}
                />
              }
            />

            <Route
              // if it falls on the localhost:3000/isw or www.smartfuel.netlify.com/isw
              exact
              path={url}
              element={<IswRoute props={this.props} />}
            />

            {canUseISWModule && (
              <Route element={<PrivateRoute props={this.props} />}>
                <Route path={`${url}audits`} element={<Audits />} />
                <Route path={`${url}reports`} element={<Reports />} />
                <Route
                  _toggleUserStatus={this._toggleUserStatus}
                  path={`${url}customers`}
                  element={<Customers />}
                />
                <Route
                  path={`${url}customers/:id`}
                  element={<GeneralProfile />}
                />
                {/* system user */}
                <Route
                  _toggleUserStatus={this._toggleUserStatus}
                  path={`${url}system-users`}
                  element={<SystemUsers />}
                />
                {/* fleet */}
                <Route
                  _toggleUserStatus={this._toggleUserStatus}
                  path={`${url}fleets`}
                  element={<Fleets />}
                />
                <Route path={`${url}fleets/:id`} element={<GeneralProfile />} />
                <Route
                  _toggleUserStatus={this._toggleUserStatus}
                  path={`${url}vendors`}
                  element={<Vendors />}
                />
                <Route
                  path={`${url}vendors/:id`}
                  element={<GeneralProfile />}
                />
                <Route path={`${url}dashboard`} element={<Dashboard />} />
              </Route>
            )}
            <Route
              path="/warden"
              element={
                <WardenRoute
                  canUseISWModule={canUseISWModule}
                  canUseContractMgtModule={canUseContractMgtModule}
                  canUseQtFuelModule={canUseQtFuelModule}
                  breakVal={breakVal}
                  decryptedToken={decryptedToken}
                  props={this.props}
                />
              }
            />

            <Route path={`${url}500`} element={<Page500 />} />
            <Route path={`${url}#/500`} element={<Page500 />} />
            <Route path={`${url}#/403`} element={<Page403 />} />
            <Route path={`${url}#/404`} element={<Page404 />} />

            <Route path="*" element={<AllRoutes props={this.props} />} />
          </Routes>
        </Suspense>
      );
    }
    return <LoadingPage text="Getting all assets..." />;
  }
}

const mapStateToProps = ({ storage_reducer, login_reducer }) => {
  const { storage, url: urlFromStore, data } = storage_reducer;
  const { modules, role } = login_reducer;
  return {
    storage,
    urlFromStore,
    data,
    modules,
    role
  };
};

export default connect(mapStateToProps)(App);
