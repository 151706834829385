import React from "react";
import { Link } from "react-router-dom";
import { Icon500 } from "./svgs";
import Header from "../components/Header/Header";
import { url } from "../route";

const Page500 = () => (
  <>
    <Header />
    <div className="isw-error">
        <div className="isw-error-wrapper">
            {/* <h4>Error: 500 Unexpected Error</h4> */}
            <div className="isw-image" style={{ margin: `${1}rem ${1}rem` }}>
                <Icon500 />
            </div>
            <div className="err-text-wrapper">
                <p className="mb-2">
                    An error occurred and your request couldn’t be completed.
                </p>
                <p>
                    Either check the URL,{" "}
                    <Link to={`${url}dashboard`} className="text-danger csr">
                        go home
                    </Link>{" "}
                    , or feel free to{" "}
                    <Link to="" className="text-danger csr">
                        report this issue
                    </Link>{" "}
                    .
                </p>
            </div>
        </div>
    </div>
  </>
);

export default Page500;
