import React from "react";
import { useOutletContext } from "react-router-dom";
import LandingButton from "../../component/landing-button";
import ProductNav from "../../component/product-nav/product-nav";
import QuickEnergyRevenue from "../../../assets/images/driver2.png";
import mQuickEnergyRevenue from "../../../assets/images/driver2_mobile.png";
import StoreIcon from "../../../assets/images/Storefront.svg";
import TruckIcon from "../../../assets/images/Truck.svg";
import TankerImage from "../../../assets/images/tanker.png";
import mTankerImage from "../../../assets/images/tanker_mobile.png";
import greyTruck from "../../../assets/images/greyTruck.svg";

export const QuickEnergyLandingPage = () => {
  const { handleShowLeadForm } = useOutletContext();
  return (
    <div className="forecourt-container">
      <div className="quick-energy sub-hero">
        <h2 className="hero-caption">
          Order diesel from anywhere. Deliver diesel everywhere.
        </h2>
        <p className="sub-hero-caption">
          Our retail distribution platform manages diesel delivery to corporates
          & consumers, enabling
          <br className="d-mobile d-tab" />
          customers to receive and track orders digitally, thereby increasing
          efficiency, reducing delivery
          <br className="d-mobile d-tab" />
          time cycle, and positioning providers to take on more business from
          more corporate partners.
        </p>
        <LandingButton
          text="Sign Up"
          to={`${window.env.REACT_APP_FRONT_CUSTOMER_URL}/customer/signup`}
          classname="sub-hero-button"
        />
      </div>
      <div className="forcourt-feature-container quick-energy">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 m-0 pr-0 d-desktop">
            <img className="tanker-img" src={mTankerImage} alt="tanker" />
          </div>
          <div className="card hero-card col-lg-12 col-md-12 col-sm-12">
            <div className="row m-0 p-0">
              <div className="col-lg-8 order">
                <img
                  className="card-icon"
                  src={greyTruck}
                  alt="Deliver bulk orders to corporate clients"
                />
                <h3 className="card-fort-header">
                  Deliver bulk orders to corporate clients.
                </h3>
                <p className="card-sub-header">
                  Manage diesel delivery to corporates & consumers, enabling you
                  to receive and track orders digitally, thereby increasing your
                  efficiency, reducing your delivery time cycle, and positioning
                  you to take on more business from more corporate partners
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <ul className="card-list">
                      <li>Track orders seamlessly</li>
                      <li>Deliver orders on the clock</li>
                      <li>Generate proof of delivery without stress</li>
                      <li>Timely payment from corporate customers</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <ul className="card-list">
                      <li>Accept payment via multiple channels </li>
                      <li>Enjoy prompt settlement & reconciliation</li>
                      <li>
                        Stay informed with comprehensive reports & analytics
                      </li>
                      <li>Proper planning of deliveries</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 m-0 pr-0 d-mobile">
                <img className="tanker-img" src={TankerImage} alt="tanker" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row quick-energy-benefit">
        <div className="col-lg-6 col-sm-12 benefit-right"></div>
        <div className="col-lg-6 col-sm-12 benefit-left">
          <div className="">
            <h3 className="benefit-cap">
              Convenient & reliable; the easiest way to order diesel
            </h3>
            <span>
              Corporate and individual clients enjoy prompt delivery of energy
              products to their preferred location via a seamless and convenient
              experience. Our energy delivery module offers customers the
              following benefits, among others:
            </span>
            <ul>
              <li>
                <span className="orange-circle"></span>
                <p>
                  Ease of creating multiple locations and branches for receiving
                  your deliveries
                </p>
              </li>
              <li>
                <span className="orange-circle"></span>Ease of placing both
                single and bulk orders for products{" "}
              </li>
              <li>
                <span className="orange-circle"></span>Proof of delivery
                notifications{" "}
              </li>
              <li>
                <span className="orange-circle"></span>Online real-time
                reporting{" "}
              </li>
              <li>
                <span className="orange-circle"></span>Deal engagement with
                suppliers
              </li>
              <li>
                <span className="orange-circle"></span>Multiple suppliers with
                competitive product price
              </li>
              <li>
                <span className="orange-circle"></span>Multiple payment channels
              </li>
              <li>
                <span className="orange-circle"></span>Complete visibility in
                the order/delivery process
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="quick-energy product-nav" id="product-nav">
        <ProductNav />
      </div>
      {/* before footer */}
      <div className="quick-energy pre-footer">
        <div className="row pre-footer-ctn">
          <div className="col-lg-6 col-sm-12 right">
            <h2>
              Improved revenue and customer
              <br className="d-mobile" /> experience for energy providers
            </h2>
            <p>
              Interswitch Energy offers convenience, transparency and ease to
              every stakeholder on the Energy value chain, provider and consumer
              alike. Everyone enjoys full value and 100% sastisfaction on every
              transaction.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12 left">
            <picture>
              <source srcSet={mQuickEnergyRevenue} media="(max-width: 600px)" />
              <img
                //     style={{
                //     maxWidth: 531,
                //     maxHeight: 304
                // }}
                src={QuickEnergyRevenue}
                alt="Quick energy revenue"
              />
            </picture>
          </div>
        </div>
        <div className="pre-footer-lower">
          <div className="card pre-footer-card">
            <span>
              <h3>Interested in the product?</h3>
              <span>Let’s get you started!</span>
            </span>
            <span className="btn-ctn">
              <LandingButton
                pretextIcon={TruckIcon}
                onclick={handleShowLeadForm}
                classname="sub-hero-button"
                text="Get Started As A Vendor"
              />
              <LandingButton
                pretextIcon={StoreIcon}
                to={`${window.env.REACT_APP_FRONT_CUSTOMER_URL}/customer/signup`}
                classname="sub-hero-button deep-blue"
                text="Sign Up As A Consumer"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickEnergyLandingPage;
