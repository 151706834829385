import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import { CREATE_CUSTOMER } from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* createCustomer(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.createCustomerService, payload);
        yield put(Actions.miniUiStop());
        if (code === 1) yield put(Actions.toggleRightSidebar(false));
        yield put(
            Actions.getCustomers({
                pageIndex: 1,
                pageSize: 10
            })
        );
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* createCustomerSaga() {
    yield takeLatest(CREATE_CUSTOMER, createCustomer);
}
