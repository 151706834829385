 const ROUTE_PATHS = {
   vendor : '/vendor',
   fleet: '/fleet',
   signin: '/signin',
   "forecourt-signin": '/forecourt-automation/signin',
   "quick-energy-signin": "/quick-energy/signin",
   "contract-management": "/contract-management/signin",
   delivery: '/delivery',
   contract: '/contract',
   contractmgt: '/contractmanagement',
   quickTeller: '/quicktellerdieseldelivery',
   forecourt_landing: '/forecourt-automation',
   home:'/',
   quickEnergy_landing: '/quick-energy',
   contractMgt_landing: '/contract-management'
}

export default ROUTE_PATHS;

export const url = "/isw/";