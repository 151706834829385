import {
    READ_FAILURE,
    READ_SUCCESS,
    SUCCESS,
    FAILURE,
    RESET
} from "../arsVariables";
import { initialState } from "../initialState";
const storage_reducer = (state = initialState, action) => {
    const { type, ...rest } = action;
    const data = { ...rest };
    switch (type) {
        case READ_SUCCESS:
            return {
                ...state,
                storage: SUCCESS,
                data: data,
                decodedData: data.decoded,
                url: data.url,
            };
        case READ_FAILURE:
            return { ...state, storage: FAILURE, data: null };

        case RESET:
            return {
                ...state,
                storage: null
            };
        default:
            return state;
    }
};

export default storage_reducer;
