import {
    SEND_LEAD_FORM_SUCCESS,
    SEND_LEAD_FORM_FAILURE,
    CLEAR_LEAD_FORM
} from "../arsVariables";
import { initialState } from "../initialState";

const submitLeadForm_reducer = (state = initialState, action) => {
    const { type, description, httpCode } = action;
    switch (type) {
        case SEND_LEAD_FORM_SUCCESS:
            return {
                ...state,
                description,
                httpCode
            };
        case SEND_LEAD_FORM_FAILURE:
            return {
                ...state
            };
        case CLEAR_LEAD_FORM:
            return {
                ...state,
                description: "",
                httpCode: "",
            }
        default:
            return state;
    }
};

export default submitLeadForm_reducer;
