import {
    GET_CUSTOMER_INFO_FAILURE,
    GET_CUSTOMER_INFO_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getCustomerInfo_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                customer_info: payloadReturned,
                total: totalCount
            };
        case GET_CUSTOMER_INFO_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getCustomerInfo_reducer;
