import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../redux-flow/services/services";
import { TOGGLE_USER_STATUS } from "../../../redux-flow/arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";

function* toggleUserStatus(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description }
        } = yield call(Services.toggleUserStatusService, payload);
        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            const { page, pageIndex, pageSize } = payload.payload;
            return yield put(
                page === "sys"
                    ? Actions.getSystemUsers({ pageIndex, pageSize })
                    : page === "fleets"
                    ? Actions.getFleets({
                          pageIndex,
                          pageSize
                      })
                    : page === "vendors"
                    ? Actions.getVendors({
                          pageIndex,
                          pageSize
                      })
                    : Actions.getCustomers({
                          pageIndex,
                          pageSize
                      })
            );
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* toggleUserStatusSaga() {
    yield takeLatest(TOGGLE_USER_STATUS, toggleUserStatus);
}
