import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
    GET_FLEET_USERS_FAILURE,
    GET_FLEET_USERS_SUCCESS,
    GET_FLEET_USERS
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getFleetUsers(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description, payload: payloadReturned, totalCount }
        } = yield call(Services.getFleetUsersService, payload);
        yield put(Actions.miniUiStop());
        if (code !== 1) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: GET_FLEET_USERS_FAILURE
            });
        } else if (code === 1) {
            return yield put({
                type: GET_FLEET_USERS_SUCCESS,
                payloadReturned,
                totalCount
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
        return yield put({
            type: GET_FLEET_USERS_FAILURE
        });
    }
}

export default function* getFleetUsersSaga() {
    yield takeLatest(GET_FLEET_USERS, getFleetUsers);
}
