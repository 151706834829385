import { initialState } from "../../initialState";
import {
    GET_ISW_ORDER_STATS_FAILURE,
    GET_ISW_ORDER_STATS_SUCCESS,
   
} from "../../arsVariables";

const getISWOrderStats_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_ISW_ORDER_STATS_SUCCESS:
            return {
                ...state,
                orderStatistics: payloadReturned,
                total: totalCount
            };
        case GET_ISW_ORDER_STATS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getISWOrderStats_reducer;
