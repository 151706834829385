import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";

const InputField = ({
    name,
    label,
    error,
    classes,
    required = true,
    ...rest
}) => {
    return (
        <Fragment>
            <TextField
                error={error !== "" && error !== undefined ? true : false}
                label={label}
                name={name}
                id={`${name} kamsi`}
                required={required}
                margin="dense"
                variant="filled"
                {...rest}
            />
            {error && <small /*style={{ color: "#ee312a" }}*/>{error}</small>}
        </Fragment>
    );
};

export { InputField };
