import React, { Component } from "react";
import { itemRender, NotifyClose } from "./notify";
import { Pagination } from "antd";
import { formatNumber, formatMoney } from "./currencyHelper";
import { Actions } from "../redux-flow/actions/_index";
import { NavLink } from "react-router-dom";
import { url } from "../route";

// a base component to reuse methods and some UI i'd rather not import
class BaseComponent extends Component {
  state = {
    currentPage: 1,
    dataPerPage: 10,
    loading: false,
    SearchKeyword: "",
  };

  getBySearch = (e, empty) => {
    this.setState({
      currentPage: 1,
    });
  };

  _deleter = (userId) => {
    this.props._deleteUser(userId);
  };

  renderTableAbnormality = (text) => (
    <ul className="isw-table--body">
      <li className="isw-table--bodyLi">
        <div className="row isw-table--bodyA">
          <div className="col-lg-12 isw-valign--middle">
            <h3 className="isw-subtitle">{text || "Data not available"}</h3>
          </div>
        </div>
      </li>
    </ul>
  );

  renderPageTracker = (total) => {
    const { currentPage, dataPerPage } = this.state;
    const indexOfLastUser = currentPage * dataPerPage;
    const indexOfFirstUser = indexOfLastUser - dataPerPage + 1;

    return total > 0 ? (
      <p className="isw-p mb-0">
        {total > indexOfFirstUser && total > indexOfLastUser
          ? `List (showing ${indexOfFirstUser}-${indexOfLastUser} of ${total}
                                                records)`
          : total > indexOfFirstUser && total < indexOfLastUser
          ? `List (showing ${indexOfFirstUser}-${total} of ${total} records)`
          : total - (currentPage - 1) * dataPerPage === 1
          ? `List (showing ${indexOfFirstUser}-${total} of ${total} records)`
          : `List (showing ${indexOfFirstUser}-${total} of ${total} records)`}
      </p>
    ) : null;
  };

  _toggler = (e, userId, activationStatus, component) => {
    e.preventDefault();
    const { currentPage: pageIndex, dataPerPage: pageSize } = this.state;
    this.setState({ loading: true });
    this.props._toggleUserStatus(
      userId,
      activationStatus,
      component,
      pageIndex,
      pageSize
    );
  };

  handleSearch = ({ target: { value } }) => {
    this.setState({ SearchKeyword: value });
  };

  renderTableDropdown2 = ({ activated, id, component }) => (
    <span className="isw-p2">
      <div className="btn-group">
        <span
          style={{
            cursor: "pointer",
          }}
          role="button"
          id="dropdownMenuLink1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="material-icons">more_vert</i>
        </span>

        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <button
            onClick={(e) =>
              this._toggleStatus(e, id, activated ? false : true, component)
            }
            className={`dropdown-item ${
              activated ? "text-danger" : "text-success"
            }`}
          >
            {activated ? "Disable" : "Enable"}
          </button>
        </div>
      </div>
    </span>
  );

  _toggleRightSidebar = (type, id) => {
    const { dispatch, toggleRightSidebar } = this.props;
    if (toggleRightSidebar === type) {
      dispatch(Actions.toggleRightSidebar(false));
      this.setState({ dropdownUserId: null });
    } else {
      dispatch(Actions.toggleRightSidebar(type));
      this.setState({ dropdownUserId: id });
    }
  };

  renderTableDropdown = ({ activated, id, vendorNumber, component, sbFor }) => (
    <span className="isw-p2">
      <div className="btn-group">
        <span
          style={{
            cursor: "pointer",
          }}
          role="button"
          id="dropdownMenuLink1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="material-icons">more_vert</i>
        </span>

        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          {component === "sys" ? null : component === "vendors" ? (
            <NavLink
              to={`${url}${component}/${vendorNumber}`}
              className="dropdown-item"
            >
              View Info
            </NavLink>
          ) : (
            <NavLink to={`${url}${component}/${id}`} className="dropdown-item">
              View Info
            </NavLink>
          )}
          {component === "fleets" ? null : (
            <button
              onClick={(e) =>
                this._toggler(e, id, activated ? false : true, component)
              }
              className={`dropdown-item ${
                activated ? "text-danger" : "text-success"
              }`}
            >
              {activated ? "Disable" : "Enable"}
            </button>
          )}

          {component === "sys" || component === "fleets" ? null : (
            <button
              className="dropdown-item"
              onClick={() => this._toggleRightSidebar(sbFor, id)}
            >
              Upload Users
            </button>
          )}
        </div>
      </div>
    </span>
  );

  renderAdminTableContents = ({
    id,
    vendorNumber,
    companyName,
    email,
    activated,
    phoneNumber,
    component,
  }) => (
    <li className="isw-table--bodyLi" key={id.toString()}>
      <div className="row isw-table--bodyA">
        <div className="col-lg-3 isw-valign--middle">
          <h3 className="isw-subtitle">{companyName}</h3>
        </div>
        <div className="col-lg-4 isw-valign--middle">
          <span className="isw-p2">{email}</span>
        </div>

        <div className="col-lg-1 isw-valign--middle">
          <span className="isw-p2">{activated ? "Active" : "Inactive"}</span>
        </div>

        <div className="col-lg-3 isw-valign--middle text-center">
          <span className="isw-p2">{phoneNumber}</span>
        </div>
        <div className="col-lg-1 isw-valign--middle text-center">
          {this.renderTableDropdown({
            activated,
            id,
            vendorNumber,
            component,
            sbFor: `bulk_${component}`,
          })}
        </div>
      </div>
    </li>
  );

  renderAdminTableHeader = () => (
    <ul className="isw-table--head">
      <li className="isw-table--headLi">
        <div className="row">
          <div className="col-lg-3">
            <span className="isw-table--headItem">Company Name</span>
          </div>
          <div className="col-lg-4">
            <span className="isw-table--headItem">Email</span>
          </div>
          <div className="col-lg-1">
            <span className="isw-table--headItem">Status</span>
          </div>
          <div className="col-lg-3 text-center">
            <span className="isw-table--headItem">Phone Number</span>
          </div>
          <div className="col-lg-1">
            <span className="isw-table--headItem">Action</span>
          </div>
        </div>
      </li>
    </ul>
  );
  handleTableChange = (page, pageSize) => {
    const { dataPerPage, SearchKeyword } = this.state;
    NotifyClose();
    this.setState({
      currentPage: page,
    });
    this.fetch({
      pageIndex: page,
      pageSize: dataPerPage,
      SearchKeyword,
    });
  };

  renderPagination = (total) => {
    const { dataPerPage, currentPage } = this.state;
    return (
      <div className="row">
        <div className="col-11">
          <nav aria-label="pagination" className="float-right">
            <Pagination
              total={Math.ceil(total / dataPerPage) * 10 || 0}
              onChange={this.handleTableChange}
              hideOnSinglePage={true}
              current={currentPage}
              itemRender={itemRender}
            />
          </nav>
        </div>
      </div>
    );
  };

  renderDashboardCard1 = ({ header, amount, count, quantity }) => (
    <div className="col-12 mb-4">
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <div className="isw-card-header mb-2">
            <h3 className="isw-card-h3 pb-4">{header}</h3>
            <div className="isw-card-p mb-4" />
          </div>
          <div className="d-inline">
            <h4 className="isw-user-section-title mb-1">{count}</h4>
            <div className="isw-p2">Total Number</div>
          </div>
          <hr className="isw-divider-horizontal" />
          <div className="d-inline">
            <h4 className="isw-user-section-title mb-1">
              {formatNumber(quantity)}
              <sub
                className="text-muted"
                style={{
                  fontSize: `${0.5}em`,
                }}
              >
                Litres
              </sub>
            </h4>
            <div className="isw-p2">Volume</div>
          </div>
          <hr className="isw-divider-horizontal" />
          <div className="d-inline">
            <h4 className="isw-user-section-title mb-1 text-orange">
              {formatMoney(amount)}
            </h4>
            <div className="isw-p2">Value</div>
          </div>
        </div>
      </div>
    </div>
  );

  renderDashboardCard2 = ({ header, footer, count }) => (
    <div className="col-12 mb-4">
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <div className="isw-card-header mb-2">
            <h3 className="isw-card-h3">{header}</h3>
            <div className="isw-card-p" />
          </div>
          <div className="d-inline">
            <h4 className="isw-user-section-title mb-1 text-orange">
              {formatNumber(count)}
            </h4>
            <div className="isw-p2">{footer ? footer : "Total Number"}</div>
          </div>
        </div>
      </div>
    </div>
  );

  // renderChoiceModal = ({ header, others, id }) =>
  //     this._toggleRightSidebar(`single_${id}`);

  renderChoiceModal = ({ header, others, id }) => (
    <div id="exampleModal" className="modal fade" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="isw-card-header p-0">
              <h3 className="isw-card-h3">{header}</h3>
              <div className="isw-card-p">Select create {others} type</div>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-5">
            <div className="d-flex justify-content-between">
              <span
                className="d-flex flex-column justify-content-center py-3 px-4 border isw-hoverClick"
                data-dismiss="modal"
                onClick={() => this._toggleRightSidebar(`single_${id}`)}
              >
                <i
                  className="material-icons text-center"
                  style={{
                    fontSize: `${3}em`,
                  }}
                >
                  person_outline
                </i>
                <h6 className="mb-0">Single {others}</h6>
              </span>

              <span
                className="d-flex flex-column justify-content-center py-3 px-4 border isw-hoverClick"
                data-dismiss="modal"
                onClick={() => this._toggleRightSidebar(`bulk_${id}`)}
              >
                <i
                  className="material-icons text-center"
                  style={{
                    fontSize: `${3}em`,
                  }}
                >
                  people_outline
                </i>
                <h6 className="mb-0">Bulk {others}s</h6>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseComponent;
