export const calcDate = (dateObj) => {
    let d = dateObj ? new Date(dateObj) : new Date();
    var options = {
        weekday: undefined,
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    return d.toLocaleDateString("en-US", options);
  };

  export const calcTime = (dateObj) =>{
    let d = dateObj ? new Date(dateObj) : '';
    if(d){
      d = d.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' });
    }
    return d;
  }

  export const convertRole = (role)=> {
     let roleValuePair = {
        SYS_ADMIN: 'System Admin',
        SUPER_ADMIN: 'Super Admin',
        VENDOR_ADMIN: 'Vendor Admin',
        LOGISTIC: 'Logistics User',
        PROCUREMENT: 'Procurement User',
        FLIT: 'Fleet Manager',
        DRIVER: 'Driver User',
        CUSTOMER_ADMIN: 'Corporate Admin',
        CUSTOMER_USER: 'Corporate User',
        VENDOR_SUPER_ADMIN: 'Vendor Super Admin',
     }

     if(role) {
         return roleValuePair[role];
     }
     else return '';
  }

  export const errorHandler = (err) => {
    let message = "Something is not right";
    if (err.response) {
      if (err.response.message) {
        message = err.response.message
      }
      if (err.response.response) {
        let errObj = err.response.response;
        if (errObj.data.status && errObj.data.status.error_description) { //errObj.data && errObj.data.error_description
          message = errObj.data.status.error_description;
        }
        if (errObj.data.status && errObj.data.status.description) {
          message = errObj.data.status.description;
        }
      }
    }
    return message;
  }