import React from "react";
import FormLayout from "../../reuse/form";
import { connect } from "react-redux";
import { Actions } from "../../redux-flow/actions/_index";
import Joi from "joi-browser";
import Static from "../../reuse/Static";
import { history } from "../../reuse/history";
import { NotifyClose } from "../../reuse/notify";
class ResetPW extends FormLayout {
    state = {
        data: { password: "", confirmPassword: "" },
        email: "",
        token: "",
        errors: {},
        styles: {},
        confirmPasswordError: ""
    };

    schema = {
        password: Joi.string()
            .min(7)
            .max(20)
            .alphanum()
            .required()
            .label("Password"),
        confirmPassword: Joi.string()
            .alphanum()
            .required()
            .label("Confirm Password")
    };

    componentWillUnmount() {
        NotifyClose();
    }

    componentDidMount() {
        const params = history.location.search;
        const paramsObj = params.split(/=|&/);
        const email = paramsObj[1];
        const token = paramsObj[3];
        this.setState({
            email,
            token
        });
    }

    onSubmit = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        const {
            email,
            token,
            data: { password, confirmPassword }
        } = this.state;

        if (password !== confirmPassword) {
            return this.setState({
                confirmPasswordError: "Passwords do not match!"
            });
        } else if (password === confirmPassword) {
            this.setState({ confirmPasswordError: "" });
        }
        this.props.dispatch(
            Actions.resetPW({
                email,
                token,
                password,
                rtPassword: confirmPassword
            })
        );
    };

    render() {
        const { email, confirmPasswordError } = this.state;
        const { loading } = this.props;

        return (
            <div className="isw-login">
                <div className="container-fluid p-0">
                    <div className="row no-gutters bg-primary">
                        <div className="col-lg-6">
                            <div className="isw-login--middle">
                                <div className="isw-login--middle-form">
                                    <form
                                        className="row"
                                        style={{
                                            maxWidth: `${30}rem`,
                                            margin: `${0} auto`
                                        }}>
                                        <div className="col-12">
                                            <header>
                                                <h1 className="text-primary">
                                                    Change Password
                                                </h1>
                                                <p>
                                                    Hi {email}, please enter
                                                    your new password to
                                                    continue
                                                </p>
                                            </header>
                                        </div>
                                        <div className="form-field mb-4 col-12">
                                            {this.renderInput(
                                                "password",
                                                "Password"
                                            )}
                                        </div>
                                        <div className="form-field mb-4 col-12">
                                            {this.renderInput(
                                                "confirmPassword",
                                                "Confirm Password",
                                                true,
                                                "",
                                                "password"
                                            )}
                                            {confirmPasswordError.length >
                                                0 && (
                                                <small
                                                    style={{
                                                        color: "#ee312a"
                                                    }}>
                                                    {confirmPasswordError}
                                                </small>
                                            )}
                                        </div>

                                        <div className="col-12">
                                            {this.renderButton(
                                                "Change Password",
                                                loading,
                                                this.onSubmit
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Static />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null)(ResetPW);
