import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { EDIT_BASIC_INFO, ENCRYPT_USER } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import {
    decryptAndRead,
    encryptAndStore
} from "../services/localStorageHelper";
function* editBasicInfo(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.editBasicInfoService, payload);
        yield put(Actions.miniUiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            const { firstName, lastName } = payload.payload;
            const storage = decryptAndRead(ENCRYPT_USER);
            storage.decoded.given_name = `${firstName} ${lastName}`;
            encryptAndStore(ENCRYPT_USER, { ...storage }, true);
            yield put(Actions.onInit());
            yield put(Actions.toggleRightSidebar(false));
            yield put(Actions.getBasicInfo());
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* editBasicInfoSaga() {
    yield takeLatest(EDIT_BASIC_INFO, editBasicInfo);
}
