import React from 'react';
import { Link } from 'react-router-dom';
import ROUTE_PATHS from '../../../route';
import '../../../refresh/component/landing.css';
import Point from '../../../assets/images/point.svg';
export const LandingSiteMap= ({ currentLocation }) =>{
    return(<div className="sitemap-container">
             <Link reloadDocument className="sitemap-link" to={ROUTE_PATHS.home}>Home</Link><span> <img src={Point} alt="point"/> </span> 
             {(currentLocation === ROUTE_PATHS.forecourt_landing) && <Link className="sitemap-link current" to={ROUTE_PATHS.forecourt_landing}>Forecourt Automation</Link> }
             {(currentLocation === ROUTE_PATHS.quickEnergy_landing) && <Link className="sitemap-link current" to={ROUTE_PATHS.quickEnergy_landing}>QuickEnergy</Link> }
             {(currentLocation === ROUTE_PATHS.contractMgt_landing) && <Link className="sitemap-link current" to={ROUTE_PATHS.contractMgt_landing}>Contract Management</Link> }
        </div>)
}
export default LandingSiteMap;