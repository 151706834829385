import { GET_AUDIT_LOGS, DOWNLOAD_AUDIT_LOGS } from "../arsVariables";

const getAuditLogs = payload => ({
    type: GET_AUDIT_LOGS,
    payload
});

export const downloadAuditLog = payload =>({
    type: DOWNLOAD_AUDIT_LOGS,
    payload
});

export default getAuditLogs;
