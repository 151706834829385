import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { CHANGE_PW } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
function* changePassword(payload) {
    try {
        yield put(Actions.miniUiStart());
        const { data } = yield call(Services.changePasswordService, payload);
        const { code, description } = data;
        yield put(Actions.miniUiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) return yield put(Actions.toggleRightSidebar(false));
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* changePasswordSaga() {
    yield takeLatest(CHANGE_PW, changePassword);
}
