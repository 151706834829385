import React, { Fragment } from "react";
import FormLayout from "../../reuse/form";
import { connect } from "react-redux";
import { Actions } from "../../redux-flow/actions/_index";
import Joi from "joi-browser";
import { ENCRYPT_USER } from "../../redux-flow/arsVariables";
import { decryptAndRead } from "../../redux-flow/services/localStorageHelper";
import { Icon, Drawer } from "antd";
// material ui
import { Button, IconButton } from "@material-ui/core";

class ChangePW extends FormLayout {
    data = { currentPW: "", password: "", confirmPassword: "" };
    state = {
        data: this.data,
        token: "",
        pending: false,
        errors: {},
        styles: {}
    };

    schema = {
        currentPW: Joi.string()
            .required()
            .label("Current Password"),
        password: Joi.string()
            .min(7)
            .max(20)
            .alphanum()
            .required()
            .label("Password"),
        confirmPassword: Joi.string()
            .alphanum()
            .required()
            .label("Confirm Password")
    };
    componentDidMount() {
        const storage = decryptAndRead(ENCRYPT_USER);
        if (!storage) return;
        const token = storage.access_token;
        this.setState({ token });
    }
    componentDidUpdate() {
        if (
            (!this.props.toggleRightSidebar && this.state.pending) ||
            this.props.toggleRightSidebar === "remove"
        ) {
            this.setState({
                data: this.data,
                pending: false,
                errors: {},
                styles: {}
            });
            this.props.dispatch(Actions.toggleRightSidebar(false));
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const { password, currentPW, confirmPassword } = this.state.data;
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        if (password !== confirmPassword) {
            const error = {};
            error.confirmPassword = "Must match passowrd!";
            return this.setState({ errors: error });
        }
        const { token } = this.state;

        this.setState({ pending: true });
        this.props.dispatch(
            Actions.changePW({
                token,
                currentPW,
                password,
                rtPassword: password
            })
        );
    };

    _toggleRightSidebar = () => {
        this.setState({
            data: this.data,
            errors: {},
            styles: {}
        });
        this.props.dispatch(Actions.toggleRightSidebar(false));
    };
    render() {
        const { toggleRightSidebar, isLoading } = this.props;
        return (
            <Fragment>
                <div className="isw-mainLayout1" id="body">
                    <Drawer
                        visible={
                            toggleRightSidebar === "change_pw" ? true : false
                        }
                        mask={false}
                        closable={false}
                        placement="right"
                        title={
                            <Fragment>
                                <IconButton
                                    onClick={this._toggleRightSidebar}
                                    style={{
                                        cursor: "pointer"
                                    }}>
                                    <Icon
                                        type="right"
                                        style={{
                                            width: `${16}px`,
                                            color: "#999"
                                        }}
                                    />
                                </IconButton>
                                Change Password
                            </Fragment>
                        }
                        zIndex={999003}
                        width={400}
                        on_toggleRightSidebar={this._toggleRightSidebar}>
                        <div className="isw-content--wrapper ">
                            <div className="isw-login--middle-form">
                                <form
                                    className="row"
                                    style={{
                                        maxWidth: `${30}rem`,
                                        margin: `${0} auto`
                                    }}>
                                    <div className="form-field mb-4 col-12">
                                        {this.renderInput(
                                            "currentPW",
                                            "Current Password",
                                            true,
                                            "",
                                            "password"
                                        )}
                                    </div>
                                    <div className="form-field mb-4 col-12">
                                        {this.renderInput(
                                            "password",
                                            "Password"
                                        )}
                                    </div>
                                    <div className="form-field mb-4 col-12">
                                        {this.renderInput(
                                            "confirmPassword",
                                            "Confirm Password",
                                            true,
                                            "",
                                            "password"
                                        )}
                                    </div>
                                </form>

                                <div className="drawer-btn-cont">
                                    <Button
                                        onClick={this._toggleRightSidebar}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        className="isw-btn  text-primary"
                                        // id below for testing
                                        id="close-CP">
                                        <span>Cancel</span>
                                    </Button>
                                    {this.renderDrawerSubmitButton(
                                        isLoading,
                                        this.onSubmit,
                                        "Save",
                                        "Saving"
                                    )}
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ ui_reducer }) => {
    const { isLoading } = ui_reducer;
    return {
        isLoading
    };
};
export default connect(mapStateToProps)(ChangePW);
