import { combineReducers } from "redux";
import storage_reducer from "./storage_reducer";
import login_reducer from "./login_reducer";
import logout_reducer from "./logout_reducer";
import toggleSidebar_reducer from "./toggleSidebar_reducer";
import toggleRightSidebar_reducer from "./toggleRightSidebar_reducer";

// handling user
import editBasicInfo_reducer from "./editBasicInfo_reducer";
import getBasicInfo_reducer from "./getBasicInfo_reducer";
import getSystemUsers_reducer from "./getSystemUsers_reducer";
import getAuditLogs_reducer from "./getAuditLogs_reducer";
import getReportLogs_reducer from "./getReportLogs_reducer";

import submitLeadForm_reducer from "./leadForm_reducer";

// STATUS
import deleteUser_reducer from "./status/deleteUser_reducer";

// statistics
import getISWOrderStats_reducer from "./statistics/getISWOrderStats_reducer";
import getISWProfileStats_reducer from "./statistics/getISWProfileStats_reducer";
import getAllUsers_reducer from "./getAllUsers_reducer";
import { initialState } from "../initialState";
import getCustomerUsers_reducer from "./getCustomerUsers_reducer";
import getCustomerInfo_reducer from "./getCustomerInfo_reducer";
import getVendors_reducer from "./getVendors_reducer";
import getCustomers_reducer from "./getCustomers_reducer";
import getFleets_reducer from "./getFleets_reducer";
import getVendorUsers_reducer from "./getVendorUsers_reducer";
import getFleetUsers_reducer from "./getFleetUsers_reducer";
import getFleetInfo_reducer from "./getFleetInfo_reducer";
import getVendorInfo_reducer from "./getVendorInfo_reducer";
import updatePaymentCodeInfo_reducer from "./updatePaymentCode_reducer";

const ui_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "STOP_LOAD":
      return {
        ...state,
        loading: false,
        sucess: false
      };
    case "LOAD":
      return {
        ...state,
        loading: true,
        sucess: false
      };
    case "mini_LOAD":
      return {
        ...state,
        isLoading: true,
        sucess: false
      };
    case "mini_STOP_LOAD":
      return {
        ...state,
        isLoading: false,
        sucess: false
      };

    default:
      return state;
  }
};
const IndexReducer = combineReducers({
  getFleetInfo_reducer,
  getVendorInfo_reducer,
  getCustomerInfo_reducer,

  getCustomerUsers_reducer,
  getVendorUsers_reducer,
  getFleetUsers_reducer,
  getSystemUsers_reducer,

  getVendors_reducer,
  getCustomers_reducer,
  getFleets_reducer,
  submitLeadForm_reducer,

  ///
  ui_reducer,
  getAllUsers_reducer,
  // return new toggle state for leftsidebar
  toggleSidebar_reducer,
  // return new toggle state for right side bar
  toggleRightSidebar_reducer,
  // handling responses from their respective sagas
  storage_reducer,
  login_reducer,
  logout_reducer,
  editBasicInfo_reducer,
  getBasicInfo_reducer,

  // audits
  getAuditLogs_reducer,
  //report
  getReportLogs_reducer,
  // STATUS
  deleteUser_reducer,
  // stats
  getISWOrderStats_reducer,
  getISWProfileStats_reducer,

  //Update Payment
  updatePaymentCodeInfo_reducer
});

export default IndexReducer;
