import React, { useState, useEffect, memo } from "react";
import { Chart } from "react-google-charts";
import { Spin } from "antd";
import Services from "../redux-flow/services/services";
import { Notify } from "./notify";
import { connect } from "react-redux";
import { monthNames } from "./datePicker";

const Graph = ({ from, graphType, to }) => {
    const [graphData, setGraphData] = useState([
        [
            "Duration",
            `${
                graphType === "locations"
                    ? "Locations"
                    : graphType === "customers"
                    ? "Customers"
                    : "Vendors"
            }`
        ],
        [monthNames[new Date().getMonth()], 0]
    ]);
    const [loading, setLoading] = useState(false);
    const fixDateFormat = date =>
        `${new Date(date).getFullYear()}-${new Date(date).getMonth() +
            1}-${new Date(date).getDate()}`;

    useEffect(() => {
        setLoading(true);
        Services[
            `${
                graphType === "locations"
                    ? "getLocationGraphDataService"
                    : graphType === "customers"
                    ? "getCompanyGraphDataService"
                    : "getVendorGraphDataService"
            }`
        ]({
            from: fixDateFormat(from),
            to: fixDateFormat(to),
            limit: 5,
            status: 7
        })
            .then(res => {
                setLoading(false);
                const {
                    data: { payload, code }
                } = res;
                if (code === 1)
                    return payload.length === 1 ? null : setGraphData(payload);
            })
            .catch(error => {
                setLoading(false);
                Notify({
                    message: "Error fetching visualization data",
                    className: "error"
                });
            });
    }, [to, from]);

    return (
        <Spin spinning={loading}>
            <Chart
                height={480}
                width={"100%"}
                chartType="LineChart"
                loader={<Spin />}
                data={[graphData[0], ...graphData.slice(1)]}
                options={{
                    curveType: "function",
                    legend: "top",
                    hAxis: {
                        title: "Duration",
                        format: "short"
                    },
                    vAxis: {
                        title: "Volume",
                        format: "short",
                        viewWindow: {
                            min: 0
                        }
                    },
                    bubble: { textStyle: { fontSize: 11 } }
                }}
                legendToggle
            />
        </Spin>
    );
};

const mapStateToProps = ({ login_reducer, storage_reducer }) => {
    const { decodedData: decodedFromStorage } = storage_reducer;
    const { decodedData: decodedFromLogin } = login_reducer;
    return {
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage
    };
};

export default connect(mapStateToProps)(memo(Graph));
