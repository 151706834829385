import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import  store  from "./store-util";
import App from "./App";
import { ErrorHandler } from "./reuse/error";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { history } from "./reuse/history";


const rootId = document.getElementById("root");

ReactDOM.render(
    <Provider store={store}>
    <CookiesProvider>
        <ErrorHandler>
          <BrowserRouter history={history}>
          <App />
          </BrowserRouter>
        </ErrorHandler>
      </CookiesProvider>
    </Provider>,
    rootId
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
