import React from 'react';
import { useOutletContext } from 'react-router-dom';
import LandingButton from '../../component/landing-button';
import Card from '../../../assets/images/card.svg';
import Scanner from '../../../assets/images/scanner.svg';
import WifiIcon from '../../../assets/images/wifi_icon.svg';
import MonitorIcon from '../../../assets/images/monitor_icon.svg';
import CheckIcon from '../../../assets/images/check.svg';
import ProductNav from '../../component/product-nav/product-nav';
import RevenueImg from '../../../assets/images/revenue_img.png';

export const ForeCourtLandingPage = () => {
    const { handleShowLeadForm } = useOutletContext();
    return (<div className="forecourt-container">
        <div className="sub-hero">
            <h2 className="hero-caption">Enjoy full visibility across all outlets; monitor<br className="d-mobile" /> stock, view analytics & access reports </h2>
            <p className="sub-hero-caption">The Forecourt Automation Solution comes with a robust suite of features and services
                designed<br className="d-mobile" /> to automate your pumps, digitizing the control & management of pump operations and sales.</p>
            <LandingButton text="Talk to Sales" onclick={handleShowLeadForm} classname="sub-hero-button" />
        </div>
        <div className="forcourt-feature-container">
            <div className="row justify-content-center">
                <div className="card hero-card col-lg-8 col-md-12 col-sm-12">
                    <img className="card-icon" src={Card} alt="card" />
                    <h3 className="card-fort-header">Integrated Multichannel Payment System</h3>
                    <p className="card-sub-header">Forecourt Automation is integrated with the Interswitch payment gateway that enables your customers to;</p>
                    <ul className="card-list">
                        <li>Pay at the pump using any payment instrument or channel of their choice – cash, Verve, MasterCard, Visa, Paycode, USSD, QR Code, Wallet</li>
                        <li>Pay from their bank app and other tokens they already have</li>
                    </ul>
                    <p className="m-0">Beyond the benefits to your customers, the multichannel payment system benefits your business as well, it guarantees prompt and seamless settlement & reconciliation.</p>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-4 hero-img"></div>
            </div>
        </div>
        <div className="forcourt-feature-container">
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-12 col-sm-12 hero-img self"></div>
                <div className="card hero-card self col-lg-8 col-md-12 col-sm-12">
                    <img className="card-icon" src={Scanner} alt="card" />
                    <h3 className="card-fort-header">Enable Self Service with Multimode Operations</h3>
                    <p className="mb-0">Switch between operation modes as it suits your business and offer your customers and staff ultimate convenience.</p>
                    <p className="card-sub-header">Self-Service Mode</p>
                    <ul className="card-list">
                        <li>This mode empowers customers to pay & serve themselves at your fuel pumps.
                            Beyond the obvious advantage of getting complete value for your product,
                            this mode also enables you to sell your products 24/7 without recruiting staff to work night shift.
                            It will also significantly reduce the number of staff required at outlets.</li>
                    </ul>
                    <p className="card-sub-header mt-1">Attendant Operated Mode </p>
                    <ul className="card-list">
                        <li>This mode enables attendants to offer optimised
                            service to customers while ensuring both the outlet and the
                            customer get full value. Tracking and monitoring are completely
                            digital, blocking any chance for theft, fraud, or leakage.</li>
                    </ul>
                    <p className="card-sub-header mt-2">Among other benefits, our multi-mode operations;</p>
                    <ul className="card-list">
                        <li>Automates sales operations at the filling station.</li>
                        <li>Integrates multichannel payment system – Verve, Paycode, MasterCard, Visa, Wallet</li>
                        <li>Enables remote control of the pumps </li>
                        <li>Customises fleet services </li>
                        <li>Simplifies reporting & analytics with BI </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="forcourt-feature-container">
            <div className="row justify-content-center mb-5">
                <div className="card hero-card wifi col-lg-6 col-md-12 col-sm-12">
                    <img className="card-icon" src={WifiIcon} alt="card" />
                    <h3 className="card-fort-header">100% Wireless Technology </h3>
                    <p className="">Forecourt automation is installed via wireless technology,
                        eliminating the risks, stress and costs that accompany running cables or breaking
                        and defacing existing infrastructure at the outlets. This way, you can upgrade to
                        seamless installations and operations without unnecessary disruptions</p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 hero-img wifi"></div>
            </div>
        </div>

        <div className="forcourt-feature-container">
            <div className="row justify-content-center mb-5">
                <div className="col-lg-6 col-md-12 col-sm-12  hero-img monitor"></div>
                <div className="card hero-card monitor col-lg-6 col-md-12 col-sm-12">
                    <img className="card-icon" src={MonitorIcon} alt="card" />
                    <h3 className="card-fort-header">Monitor and Manage your Wet Stock</h3>
                    <p className="">An important avenue for blocking leakages is the efficient
                        management of your inventory, particularly your wet stock.
                        Beyond the reports sent in by your outlet managers, embrace a
                        digital system that gives you 100% visibility of what is available or required.</p>
                    <ul className="card-list">
                        <li>Automate the inventory management of products in underground tanks to
                            ascertain the quality of available products
                        </li>
                        <li>
                            Check your inventory level real-time, anytime
                        </li>
                        <li>Monitor your stock in any outlet from wherever you are</li>
                        <li>Stay on top of your business with timely and comprehensive reports and analytics</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="sol-section forcourt bg-sm-white" style={{
            flexDirection: 'column',
        }}>
            <h2 className="sol-cap pb-2">Providing solutions to drive your growth</h2>
            <span>By digitizing operations across all your retail outlets, Interswitch
                Energy empowers you<br className="d-mobile" /> with a transparent central system that provides
                seamless automation and Effective<br className="d-mobile" /> monitoring of sales..</span>
        </div>
        <div className="row check-list-container">
            <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                <img src={CheckIcon} alt="Visibility into sales and daily operations" />
                <h5>Visibility into sales and daily operations</h5>
                <p>Log in from wherever you are and enjoy a 360-degree view of all sales
                    operations across all your outlets nationwide.
                </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                <img src={CheckIcon} alt="Remote management of forecourt operations" />
                <h5>Remote management of forecourt operations</h5>
                <p>Manage each pump across all your outlets and every aspect of your
                    forecourt operations without leaving your current location.
                </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                <img src={CheckIcon} alt="ePayment options for customers" />
                <h5>ePayment options for customers</h5>
                <p>Give your customers the convenience and freedom to
                    pay via their preferred channel at any of your outlets.
                </p>
            </div>
            {/* underground */}
            <div className="col-lg-4 col-md-6 col-sm-12 checklist-item">
                <img src={CheckIcon} alt="Roboust Inventory and Sales Management" />
                <h5>Roboust Inventory and Sales Management</h5>
                <p>Get a real-time view of products from deliveries into the tanks
                    through sales at the forecourt with reconciled positions.
                </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 checklist-item last">
                <img src={CheckIcon} alt="Access to instant reporting and analytics" />
                <h5>Access to instant reporting and analytics</h5>
                <p>Access the information you need for business decisions via
                    comprehensive reports and analytics delivered real-time.
                </p>
            </div>
        </div>
        <div id="product-nav">
        <ProductNav />
        </div>
        {/* before footer */}
        <div className="pre-footer">
            <div className="row pre-footer-ctn">
                <div className="col-lg-6 col-sm-12 right">
                    <h2>
                        Improved revenue and customer<br className="d-mobile" /> experience for energy providers
                    </h2>
                    <p>
                        Stay in charge of your energy business, regardless of how many outlets you have. From your inventory to your processes and daily sales, Interswitch Energy provides
                        insights real-time, equipping you to make informed business decisions.
                    </p>
                </div>
                <div class="col-lg-6 col-sm-12 left">
                    <img src={RevenueImg} alt="" />
                </div>
            </div>
            <div className="pre-footer-lower">
                <div className="card pre-footer-card">
                    <span>
                        <h3>Interested in the product?</h3>
                        <span>Let’s get you started!</span>
                    </span>
                    <LandingButton onclick={handleShowLeadForm} classname="sub-hero-button" text="Talk to Sales" />
                </div>
            </div>
        </div>
    </div>)
}

export default ForeCourtLandingPage;