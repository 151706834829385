export const initialState = {
  users: [],
  permissions: [],
  role: null,
  loading: false,
  isLoading: false,
  decodedData: {},
  basicInfo: {},
  customer_info: null,
  vendor_info: null,
  fleet_info: null,
  vendors: [],
  vendorsLength: null,
  vendor_users: [],
  vendor_users_length: null,
  fleets: [],
  fleetsLength: null,
  fleet_users: [],
  fleet_users_length: null,
  customers: [],
  customersLength: null,
  customers_users: [],
  customer_users_length: null,
  system_users: [],
  system_usersLength: null,
  orderStatistics: [],
  profileStatistics: [],
  audits: [],
  toggleSidebar: true,
  toggleRightSidebar: true,
  updatedPaymentCode: []
};
