import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PumpIcon from '../../../assets/images/gasoline-pump.svg';
import OilIcon from '../../../assets/images/oil.svg';
import CotractIcon from '../../../assets/images/contract.svg';
import ROUTE_PATHS from '../../../route';



export const ProductNav = () => {
    const { pathname } = useLocation();

    return (<div id="our-products" className="row product-nav-section justify-content-center">
         {pathname !== ROUTE_PATHS.home && <div className="empty-product-nav col-lg-3 col-md-12 col-sm-12">
             <h2>More Solutions</h2>
             <p>All in one platform for automating processes,
                  built to enable and facilitate seamless
                value exchange between oil & gas businesses 
                and the people they serve.</p>
         </div>}
        {pathname !== ROUTE_PATHS.forecourt_landing &&
            <Link reloadDocument={true} to={ROUTE_PATHS.forecourt_landing}>
                <div className="card product-card col-lg-3 col-sm-12">
                    <span className="icon-circle">
                        <img src={PumpIcon} alt="Forecourt Automation" />
                    </span>
                    <div className="card-inner">
                        <h3 className="card-caption space">Forecourt Automation</h3>
                        <p className="text-center">
                            Digitize your forecourt operations across all your retail outlets.
                            Deliver customer satisfaction and enjoy seamless inventory to sales management.
                        </p>
                        <Link reloadDocument={true} className="card-link" to={ROUTE_PATHS.forecourt_landing}>Get Started</Link>
                    </div>
                </div>
            </Link>}
        {pathname !== ROUTE_PATHS.contractMgt_landing &&
            <Link reloadDocument={true} to={ROUTE_PATHS.contractMgt_landing}>
                <div className="col-lg-3 col-sm-12 card product-card">
                    <span className="icon-circle">
                        <img src={CotractIcon} alt="Contract Management" />
                    </span>
                    <div className="card-inner">
                        <h3 className="card-caption space">Contract Management</h3>
                        <p className="text-center">
                            Manage large and multiple contracts for efficient fleet fuelling and
                            activate seamless collaboration between oil marketers & corporate customers.
                        </p>
                        <Link reloadDocument={true} className="card-link" to={ROUTE_PATHS.contractMgt_landing}>Get Started</Link>
                    </div>
                </div>
            </Link>}
        {pathname !== ROUTE_PATHS.quickEnergy_landing &&
            <Link reloadDocument={true} to={ROUTE_PATHS.quickEnergy_landing}>
                <div className="card product-card col-lg-3 col-sm-12">
                    <span className="icon-circle">
                        <img src={OilIcon} alt="Quick Energy" />
                    </span>
                    <div className="card-inner">
                        <h3 className="card-caption">QuickEnergy</h3>
                        <p className="text-center">
                            Access reliable energy suppliers and experience effective order management
                            and delivery of petroleum products for corporates & individuals.
                        </p>
                        <Link reloadDocument={true} className="card-link" to={ROUTE_PATHS.quickEnergy_landing} >Get Started</Link>
                    </div>
                </div>
            </Link>}
    </div>)
}
export default ProductNav;