import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
    GET_AUDIT_LOGS_FAILURE,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS,
    DOWNLOAD_AUDIT_LOGS_SUCCESS,
    DOWNLOAD_AUDIT_LOGS_FAILURE,
    DOWNLOAD_AUDIT_LOGS
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import fileDownload from "js-file-download";

function* getAuditLogs(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: {
                code,
                description,
                payload: payloadReturned,
                totalCount,
                totalRecords
            }
        } = yield call(Services.getAudtiLogsService, payload);
        yield put(Actions.uiStop());
        if (code !== 1) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: GET_AUDIT_LOGS_FAILURE
            });
        } else if (code === 1) {
            return yield put({
                type: GET_AUDIT_LOGS_SUCCESS,
                payloadReturned,
                totalCount: totalRecords ? totalRecords : totalCount
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_AUDIT_LOGS_FAILURE
        });
    }
}

export default function* getAuditLogsSaga() {
    yield takeLatest(GET_AUDIT_LOGS, getAuditLogs);
}

function* downloadAuditLog(payload) {
    try {
      yield put(Actions.uiStart());
      const response = yield call(Services.downloadAuditLogsService, payload);
      fileDownload(response.data, 'Auditlog.xlsx');
      Notify({
          message: 'Audit log download successful',
          className: 'success',
      })
      yield put(Actions.uiStop());
      
      return yield put({
        type: DOWNLOAD_AUDIT_LOGS_SUCCESS,
      });
    } catch (err) {
      Notify({
        message: err.response.message,
        className: "error",
      });
      yield put(Actions.uiStop());
      return yield put({
        type: DOWNLOAD_AUDIT_LOGS_FAILURE,
      });
    }
  }
  
  
  export function* downloadAuditLogSaga() {
    yield takeLatest(DOWNLOAD_AUDIT_LOGS, downloadAuditLog);
  }