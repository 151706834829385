import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    GET_BASIC_FAILURE,
    GET_BASIC_SUCCESS,
    GET_BASIC_INFO
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getBasicInfo(payload) {
    try {
        yield put(Actions.uiStart());
        const { error, data, status } = yield call(
            Services.getBasicInfoService,
            payload
        );
        const { code, description, payload: payloadReturned } = data;
        yield put(Actions.uiStop());
        if (error === false && status === 200 && code === 1) {
            return yield put({
                type: GET_BASIC_SUCCESS,
                payload: payloadReturned
            });
        } else if (error === false && status === 200) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: GET_BASIC_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_BASIC_FAILURE
        });
    }
}

export default function* getBasicInfoSaga() {
    yield takeLatest(GET_BASIC_INFO, getBasicInfo);
}
