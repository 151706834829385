import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import {
    decryptAndRead,
    encryptAndStore
} from "../../redux-flow/services/localStorageHelper";
import Services from "../../redux-flow/services/services";
import {
    READ_FAILURE,
    READ_SUCCESS,
    READ_EXPIRED,
    READ_STORAGE,
    REFRESH_TOKEN,
    ENCRYPT_USER
} from "../arsVariables";
import jwtDecode from "jwt-decode";
function* readStorage() {
    try {
        const data = decryptAndRead(ENCRYPT_USER);
        const { expired, url, access_token, type: role, ...rest } = data;
        const decoded = jwtDecode(access_token);

        if (expired === false) {
            return yield put({
                type: READ_SUCCESS,
                url,
                decodedData: decoded,
                role,
                ...rest
            });
        } else if (expired === true) {
            return yield put({ type: READ_EXPIRED });
        } else {
            return yield put({ type: READ_FAILURE });
        }
    } catch (error) {
        return yield put({ type: READ_FAILURE });
    }
}

function* refreshStorage() {
    try {
        const storage = decryptAndRead(ENCRYPT_USER);
        if (storage) {
            const { refresh_token: token } = storage;
            const newUser = yield call(Services.refreshTokenService, token);
            const { status, data, error } = newUser;
            if (error === false && status !== 200) {
                return yield put({ type: READ_FAILURE });
            }
            if (error === false && status === 200) {
                return yield put({ type: REFRESH_TOKEN, data });
            }
            if (error === true) {
                return yield put({ type: READ_FAILURE });
            }
        } else {
            return yield put({ type: READ_FAILURE });
        }
    } catch (error) {
        return yield put({ type: READ_FAILURE, ...error });
    }
}

function* updateToken({ data }) {
    const storage = decryptAndRead(ENCRYPT_USER);

    storage.access_token = data.access_token;
    storage.id_token = data.id_token;
    delete storage.expired;
    encryptAndStore(ENCRYPT_USER, { ...storage }, true);
    return yield put({ type: READ_SUCCESS, ...storage });
}

export default function* storageSaga() {
    yield takeEvery(READ_STORAGE, readStorage);
    yield takeLatest(READ_EXPIRED, refreshStorage);
    yield takeEvery(REFRESH_TOKEN, updateToken);
}
