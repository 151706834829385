import React from "react";
import { Link } from "react-router-dom";
import ROUTE_PATHS from "../../../route";
import { EnergyLogoIcon } from "../../svgIcons";
import LandingButton from "../landing-button";
import whitePhone from "../../../assets/images/whitePhone.svg";
import ArrowIcon from "../../../assets/images/whiteArrow.svg";

import "./header.css";

export const LandingHeader = ({ currentLocation, launchSalesForm }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light landing-nav">
      <Link reloadDocument className="navbar-brand" to="/">
        {/* <EnergyLogoIcon classname="energy-logo" /> */}
        <EnergyLogoIcon classname="energy-logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        {currentLocation !== ROUTE_PATHS.home && (
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                style={{ marginTop: 5 }}
                className="nav-link land-nav"
                to={ROUTE_PATHS.home}
              >
                View Solutions
              </Link>
            </li>
            <li className="nav-item">
              <a
                style={{ marginTop: 5 }}
                className="nav-link land-nav"
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.interswitchgroup.com/"
              >
                Contact Support
              </a>
            </li>
            <li className="nav-item">
              {currentLocation === ROUTE_PATHS.forecourt_landing && (
                <LandingButton
                  text="Login"
                  to={ROUTE_PATHS["forecourt-signin"]}
                  classname="nav-link land-nav button"
                />
              )}
              {currentLocation !== ROUTE_PATHS.forecourt_landing && (
                <LandingButton
                  text="Login"
                  to={
                    currentLocation === ROUTE_PATHS.quickEnergy_landing
                      ? ROUTE_PATHS["quick-energy-signin"]
                      : ROUTE_PATHS["contract-management"]
                  }
                  classname="nav-link land-nav button"
                />
              )}
            </li>
            <li className="nav-item">
              {(currentLocation === ROUTE_PATHS.forecourt_landing ||
                currentLocation === ROUTE_PATHS.contractMgt_landing) && (
                <LandingButton
                  icon={whitePhone}
                  text="Talk to Sales"
                  onclick={launchSalesForm}
                  classname="nav-link land-nav button sales"
                />
              )}
              {currentLocation === ROUTE_PATHS.quickEnergy_landing && (
                <LandingButton
                  icon={ArrowIcon}
                  text="Sign Up"
                  to={`${window.env.REACT_APP_FRONT_CUSTOMER_URL}/customer/signup`}
                  classname="nav-link land-nav button sales"
                />
              )}
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};
export default LandingHeader;
