import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import LandingFooter from '../landing-footer/landing-footer';
import LandingHeader from '../landing-header/header';
import '../../../assets/font/stylesheet.css';
import '../landing.css';
import LeadForm from '../../Views/lead-form';
import { useState } from 'react';
import LandingSiteMap from '../landing-sitemap/sitemap';
import ROUTE_PATHS from '../../../route';
// import Ndpr from '../../../reuse/Ndpr';

export const LandingLayout = () => {
    const [ showLeadForm, setShowLeadForm] = useState(false);
    const { pathname } = useLocation();
  
    useEffect(()=>{
        switch (pathname){
            case ROUTE_PATHS.forecourt_landing:
            case ROUTE_PATHS.quickEnergy_landing:
            case ROUTE_PATHS.contractMgt_landing:
            localStorage.setItem("prev-page", pathname);
            localStorage.setItem("prevPage", pathname);
             break;
            default:
            break;
        }
    },[pathname])

  
    const handleShowLeadForm = () => setShowLeadForm(!showLeadForm);

    return (<div className="container-fluid landing-container">
        {/* <Ndpr onAcceptanceCheck={()=> { return false }} /> */}
        <LandingHeader currentLocation={pathname} launchSalesForm={handleShowLeadForm} />
        {pathname !==  ROUTE_PATHS.home ? <LandingSiteMap currentLocation={pathname} />
         : <></> }
        <main>
            {showLeadForm && <LeadForm handleForm={handleShowLeadForm} />}
            <Outlet context={{ handleShowLeadForm }} />
        </main>
        <LandingFooter />
    </div>)
}

export default  LandingLayout;