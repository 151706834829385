import {
  GET_REPORT_LOGS,
  GET_REPORT_LOGS_PDF_DOWNLOAD,
  GET_REPORT_LOGS_EXCEL_DOWNLOAD,
} from "../arsVariables";

const getReportLogs = (payload) => ({
  type: GET_REPORT_LOGS,
  payload,
});

const getReportLogsPDFDownloads = (payload) => ({
  type: GET_REPORT_LOGS_PDF_DOWNLOAD,
  payload,
});

const getReportLogsExcelDownloads = (payload) => ({
  type: GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  payload,
});

const getReportLogsActions = {
  getReportLogs,
  getReportLogsPDFDownloads,
  getReportLogsExcelDownloads,
};
export default getReportLogsActions;
