import React, { Fragment } from "react";
import { IconLogo } from "./svgs";
import { url } from "../route";

const Static = () => {
    return (
        <Fragment>
            <div className="col-lg-6">
                <div className="isw-column--left">
                    <div className="isw-column--left-top">
                        <IconLogo
                            height={"90px"}
                            width={"200px"}
                            color="white"
                        />
                    </div>

                    <div className="isw-column--left-base">
                        <section>
                            <p className="mb-0">
                                Smartfuel is Interswitch’s end-to-end petroleum
                                downstream solution covering payments, loyalty
                                management, fleet management, forecourt
                                automation and monitoring. It helps reduce
                                consumer cash spending within and around the
                                forecourt /station, create increased downstream
                                diversity including off station delivery &
                                contract management for oil marketers, and track
                                delivery/spend analytics.
                            </p>
                            <div className="mt-4">
                                <a
                                    href={`${url}#/404`}
                                    className="text-white small mr-2">
                                    Privacy Policy
                                </a>
                                <span className="small w-100">|</span>
                                <a
                                    href={`${url}#/404`}
                                    className="text-white small ml-2">
                                    Contact
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Static;
