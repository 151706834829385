import {
  loginService,
  refreshTokenService,
  resetPasswordService,
  forgotPasswordService,
  changePasswordService,
  submitLeadFormService
} from "./authServices";

import {
  createVendorService,
  editBasicInfoService,
  createCustomerService,
  getBasicInfoService,
  createSystemUserService,
  getSystemUsersService,
  getAudtiLogsService,
  downloadAuditLogsService,
  bulkUploadService,
  getAllUsersService,
  getReportLogsService,
  getReportLogsDownloadPDFService,
  getReportLogsDownloadExcelService,

  //
  getCustomerUsersService,
  getCustomerInfoService,
  getCustomersService,
  getVendorsService,
  getFleetsService,
  getVendorUsersService,
  getVendorInfoService,
  getFleetUsersService,
  getFleetInfoService,
  updatePaymentCodeInfoService
} from "./appServices";

import {
  rolesService,
  updateUserPermissionsService,
  permissionsService,
  getUserPermissionsService,
  batchUpdateUserPermissionsService
} from "./permissionServices";

import { toggleUserStatusService, deleteUserService } from "./statusServices";

import {
  getISWOrderStatsService,
  getISWProfileStatsService,
  getLocationGraphDataService,
  getCompanyGraphDataService,
  getVendorGraphDataService
} from "./statistics";

const Services = {
  getCustomerUsersService,
  getCustomerInfoService,
  getCustomersService,
  getVendorsService,
  getFleetsService,
  getVendorUsersService,
  getVendorInfoService,
  getFleetUsersService,
  getFleetInfoService,

  ///
  loginService,
  refreshTokenService,
  resetPasswordService,
  forgotPasswordService,
  changePasswordService,
  // lead form
  submitLeadFormService,

  // getting
  getAllUsersService,
  getAudtiLogsService,
  downloadAuditLogsService,
  getReportLogsService,
  getReportLogsDownloadPDFService,
  getReportLogsDownloadExcelService,

  // creating
  bulkUploadService,
  editBasicInfoService,
  createVendorService,
  createCustomerService,
  getBasicInfoService,
  createSystemUserService,
  getSystemUsersService,
  // permissions related
  rolesService,
  updateUserPermissionsService,
  permissionsService,
  getUserPermissionsService,
  batchUpdateUserPermissionsService,
  // status related
  toggleUserStatusService,
  deleteUserService,

  // stats
  getISWOrderStatsService,
  getISWProfileStatsService,
  getLocationGraphDataService,
  getCompanyGraphDataService,
  getVendorGraphDataService,

  //PaymentInfo
  updatePaymentCodeInfoService
};

export default Services;
