import React from "react";
import "./terms.css";

const TermsAndConditions = () => {
  return (
    <div className="tc_container">
      <div className="top-wrapper">
        <h3>Terms and Conditions for Business</h3>

        <p>
          The Interswitch Energy (INTERSWITCH ENERGY PLATFORM) Standard Terms
          and Conditions (T&C) represent the legal attributes of the INTERSWITCH
          ENERGY PLATFORM service provided by Interswitch Limited (Interswitch).
          The content is binding and is not subject to any varying terms or
          conditions, unless as provided by Interswitch subsequently upon notice
          to the User. Users who are under 18 years of age should review this
          T&C with a parent or guardian. By accepting this T&C you agree to be
          bound by this T&C and the Interswitch’s privacy policy
          (https://interswitchgroup.com/privacy) and as amended at any time and
          posted on the website. If you disagree with any part hereof, do not
          access the INTERSWITCH ENERGY PLATFORM. In addition, User’s use of the
          INTERSWITCH ENERGY PLATFORM shall be subject to any applicable card
          scheme rule, applicable regulatory guidelines or any other
          rules/provision communicated by Interswitch from time to time. All
          such guidelines or rules are hereby incorporated by reference into
          this T&C.
        </p>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          1. DESCRIPTION OF THE INTERSWITCH ENERGY PLATFORM
        </h3>

        <span className="section__wrapper-subHeader">
          INTERSWITCH ENERGY PLATFORM is a trade platform for the Energy Sector,
          that has three (3) major modules:
        </span>

        <ol>
          <li>
            Forecourt Automation – this solution is the automation of the pumps
            for the purpose of controlling and managing pump operations and
            sales at the retail forecourt.
          </li>
          <li>
            Contract Management – this solution is a platform that automates the
            special contract between Oil Marketers and Corporate for AGO and PMS
            purchase across retail outlets.
          </li>
          <li>
            Quick Energy – it manages diesel delivery to corporate and
            consumers, enabling you to receive and track orders digitally,
            thereby increasing your efficiency, reducing your delivery time
            cycle.{" "}
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          2. INTERSWITCH ENERGY PLATFORM LICENCE
        </h3>

        <span className="section__wrapper-subHeader">
          2.1. Interswitch grants to User a non-exclusive, non-transferable
          license to use INTERSWITCH ENERGY PLATFORM solely for the purposes, as
          applicable:
        </span>

        <ol>
          <li>Oil Majors</li>
          <li>Independent Oil Marketers</li>
          <li>Corporate and Individuals</li>
        </ol>

        <span className="section__wrapper-subHeader">
          2.2 User agrees to abide by the terms and conditions of this T&C.
        </span>

        <span className="section__wrapper-subHeader">
          2.3 Except to the extent specifically authorized under this T&C, User
          must not sub-license, transfer, or assign it’s right to use
          INTERSWITCH ENERGY PLATFORM.
        </span>

        <span className="section__wrapper-subHeader">
          2.4. User must comply with the implementation and use requirements
          contained in all Interswitch documentation accompanying INTERSWITH
          ENERGY PLATFORM and communicated to the User from time to time.
        </span>

        <span className="section__wrapper-subHeader">
          2.5 All patents, copyrights, circuit layouts, mask works, trade
          secrets and other proprietary rights in or related to the Platform are
          and will remain the exclusive property of Interswitch, whether or not
          specifically recognized or perfected under the laws of the
          jurisdiction in which the Platform is used or licensed. User will not
          take any action that jeopardizes Interswitch's proprietary rights or
          acquire any right in the Platform. Interswitch will own all rights in
          any copy, translation, modification, adaptation or derivation of the
          Platform or other items of Confidential Information, including any
          improvement or development thereof. User will obtain, at Interswitch's
          request, the execution of any instrument that may be appropriate to
          assign these rights to or perfect these rights in Interswitch's name.
        </span>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          3. OIL MAJORS’ RESPONSIBILITIES AND UNDERTAKINGS
        </h3>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          4. INDEPENDENT OIL MARKETERS RESPONSIBILITIES AND UNDERTAKINGS
        </h3>

        <span className="section__wrapper-subHeader">This User shall:</span>

        <ol>
          <li>
            Set -up its corporate clients on the INTERSWITCH ENERGY PLATFORM for
            ordering and delivery of the customer’s products.
          </li>
          <li>Be responsible for its customers.</li>
          <li>
            Be responsible for creating products, product price, product
            availability and edit product pricing.{" "}
          </li>
          <li>
            Be responsible for collection of sums due from/owed by its
            customers.
          </li>
          <li>
            Ensure adoption of the INTERSWITCH ENERGY PLATFORM among its
            customers.
          </li>
          <li>
            Provide Interswitch with written confirmation of its nominated
            Settlement Bank and its account details with the Settlement Bank,
            which shall be accompanied by written acceptance from the bank to
            act as the Settlement Bank, in the template and format furnished by
            Interswitch.
          </li>
          <li>Supply quality products to its customers.</li>
          <li>
            Indemnify Interswitch of any accidents resulting from the delivery
            of products to clients and customers
          </li>
          <li>
            Be fully responsible and liable for the acts and omissions of its
            agents and representatives.
          </li>
          <li>Be liable to its customers.</li>
          <li>
            Not make any warranty or representation whatsoever, in relation to
            INTERSWITCH ENERGY PLATFORM, which may bind Interswitch or make it
            liable in any way whatsoever.
          </li>
          <li>
            Ensure that it has adequate controls, safeguards, information
            technology security and effective internal controls for all its
            operations.
          </li>
          <li>
            Always provide to and maintain with Interswitch correct and updated
            information, for it and its customers.
          </li>
          <li>Comply with all applicable Laws and Regulations.</li>
          <li>
            Ensure its customers comply with all applicable, laws, regulations.
          </li>
          <li>
            Comply with and implement the requirements of the Nigeria Data
            Protection Act and Nigeria Data Protection Regulation, and any
            amendment thereof and the Data Processing Requirements (
            <a href="https://interswitchgroup.com/DATA_PROCESSING_ANNEX_FOR_TC.pdf">
              https://interswitchgroup.com/DATA_PROCESSING_ANNEX_FOR_TC.pdf
            </a>
            ).{" "}
          </li>
          <li>
            Not engage in any illegal or suspicious activity and/or
            transactions.
          </li>
          <li>
            Conduct appropriate and detailed customer due diligence using a
            risk- based approach on all its customers.
          </li>
          <li>
            Upon request by Interswitch, provide KYC documentation/information
            relating to any of its customers.
          </li>
          <li>
            On a continuous basis, monitor and ensure that its customers conduct
            legitimate businesses and have requisite valid licenses (where
            applicable) to conduct their business.
          </li>
          <li>
            If using, or intending to use, a service provider in connection with
            the INTERSWITCH ENERGY PLATFORM or service, must: <br />
            a. provide Interswitch with information on any service providers it
            uses or intends to use. <br />
            b. validate the service providers are certified as compliant with
            the Payment Card Industry Data Security Standard (PCI DSS) or a
            similarly established data security standard.
          </li>
          <li>
            Be liable for (including without limitation, all charges, losses or
            damages whatsoever arising from) any use of the passwords or
            identification codes used to access INTERSWITCH ENERGY PLATFORM.
          </li>
          <li>
            Observe and comply with all security measures and instructions
            prescribed by Interswitch.
          </li>
          <li>
            Promptly notify Interswitch of any security breach, misuse,
            irregularity, suspected fraudulent transaction, account numbers or
            suspicious activities that may be connected with attempts to commit
            fraud or other illegal activity throughout the duration of use of
            this T&C.
          </li>
          <li>
            Ensure its customers are trained on the use of INTERSWITCH ENERGY
            PLATFORM.
          </li>
          <li>
            Only use Interswitch payment gateway for payments on INTERSWITCH
            ENERGY PLATFORM.
          </li>
          <li>
            Comply with the terms and conditions for use of the Interswitch
            payment gateway.
          </li>
          <li>
            Ensure INTERSWITCH ENERGY PLATFORM is used in accordance with the
            user guide provided by Interswitch, and other communications made by
            Interswitch from time to time.
          </li>
          <li>
            Be responsible for creating and managing user access of its
            personnel.
          </li>
          <li>
            Comply with all security or encryption standards, rules and
            procedure communicated by Interswitch from time to time.
          </li>
          <li>
            be and remain PCI DSS compliant (where required by Interswitch) and
            be in compliance with applicable card scheme security requirements
            during the term of this T&C.
          </li>
          <li>
            where required by Interswitch, provide copy of its PCI DSS
            compliance Certificate, applicable documentation such as Attestation
            of Compliance and Report Of Compliance where applicable and every
            renewal thereof.
          </li>
          <li>
            not offer for sale on INTERSWITCH ENERGY PLATFORM, any goods or
            services not expressly stated during registration of the User’s
            website with Interswitch. Upon default by User, Interswitch shall
            without any liability, immediately withdraw its services and thereby
            terminate the INTERSWITCH ENERGY PLATFORM service provided under
            these T&Cs
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          5. CORPORATE AND INDIVIDUAL’S RESPONSIBILITIES AND UNDERTAKINGS
        </h3>

        <span className="section__wrapper-subHeader">This User shall:</span>

        <ol>
          <li>
            Not make any warranty or representation whatsoever in relation to
            INTERSWITCH ENERGY PLATFORM which may bind Interswitch or make it
            liable in any way whatsoever.{" "}
          </li>
          <li>
            Comply with all security or encryption standards, rules and
            procedures imposed by Interswitch.{" "}
          </li>
          <li>
            Be and remain Payment Card Industry Data Security Standard (PCIDSS)
            compliant (where required by Interswitch) and be in compliance with
            applicable card scheme security requirements during the term of this
            T&C.{" "}
          </li>
          <li>
            Where applicable and required by Interswitch, provide copy of its
            PCIDSS compliance Certificate, applicable documentation such as
            Attestation of Compliance and Report of Compliance where applicable
            and every renewal thereof.{" "}
          </li>
          <li>
            Where required by Interswitch, ensure it has implemented a fraud
            protection and monitoring tool of the highest standard and provide
            evidence of same to Interswitch.{" "}
          </li>
          <li>
            Make connections to such other systems as Interswitch may require
            from time to time.
          </li>
          <li>
            Inform Interswitch of any change in the particulars of its
            designated bank account/settlement account, such change will not be
            effected until the User has provided evidence to Interswitch that it
            has communicated this change to the former Settlement Bank.{" "}
          </li>
          <li>
            Not use any cardholder payment card details including but not
            limited to Primary Account Number (PAN) or Card Number, Personal
            Identification Number (PIN), Card Verification Value (CVV) for any
            purpose other than for the facilitation of the payment authorized by
            the cardholder.{" "}
          </li>
          <li>
            Keep any approved digital certificates for a site authentication
            current and valid and take all necessary measures to protect the
            security and secrecy of its site certificates.
          </li>
          <li>
            Notify Interswitch of any change to the internet protocol address of
            its website used for payment transactions.{" "}
          </li>
          <li>
            Not offer for sale on INTERSWITCH ENERGY PLATFORM, any goods or
            services not expressly stated during registration of the User’s
            website with Interswitch. Upon default by User, Interswitch shall
            without any liability, immediately withdraw its services and thereby
            terminate the INTERSWITCH ENERGY PLATFORM service provided under
            these T&Cs.
          </li>
          <li>
            ensure that it has adequate controls, safeguards, information
            technology security and effective internal controls for all its
            operations.
          </li>
          <li>
            Not and shall not permit its affiliates or any third party to
            translate, reverse engineer, decompile, recompile, update or modify
            all or any part of the INTERSWITCH ENERGY PLATFORM or merge the
            INTERSWITCH ENERGY PLATFORM into any other solution.{" "}
          </li>
          <li>
            Comply with and implement the requirements of the Nigeria Data
            Protection Act, Nigeria Data Protection Regulation (NDPR), or any
            amendment thereof and the Data Processing Requirements
            (https://interswitchgroup.com/DATA_PROCESSING_ANNEX_FOR_TC.pdf).{" "}
          </li>
          <li>
            Complete PCI DSS Self-Assessment Questionnaire (SAQ) A-EP.
            https://www.pcisecuritystandards.org/documents/Understanding_SAQs_PCI_DSS_v3.pdf,
            and provide Interswitch with a copy of same.{" "}
          </li>
          <li>
            provide to and maintain with Interswitch correct and updated
            information at all times.{" "}
          </li>
          <li>
            provide Interswitch with written confirmation of its nominated
            Settlement Bank and its account details with the Settlement Bank,
            which shall be accompanied by written acceptance from the bank to
            act as the Settlement Bank, in the template and format furnished by
            Interswitch.{" "}
          </li>
          <li>
            provide Interswitch promptly with all information and/or documents
            required by it to validate User’s identity.{" "}
          </li>
          <li>
            be responsible for maintaining adequate security and control of any
            and all IDs, passwords, personal identification numbers (PINs), or
            any other codes that it uses to access the INTERSWITCH ENERGY
            PLATFORM.
          </li>
          <li>
            comply with the Acceptable Use Policy or any other Policy or
            agreement communicated by Interswitch.
          </li>
          <li>
            not infringe Interswitch's or any third party's copyright, patent,
            trademark, trade secret or other intellectual property rights, or
            rights of publicity or privacy; not engage in any illegal or
            suspicious activity and/or transactions.
          </li>
          <li>
            not use the services in a manner that Interswitch, a card scheme or
            any other electronic funds transfer network reasonably believes to
            be an abuse of the card system or a violation of card association or
            network rules.
          </li>
          <li>
            not disclose or distribute another user's Information to a third
            party or use the Information for marketing purposes unless with
            written consent of the owner.{" "}
          </li>
          <li>
            not facilitate any viruses, Trojan horses, worms or other computer
            programming routines that may damage, detrimentally interfere with,
            surreptitiously intercept or expropriate any system, data or
            Information; not use an anonymizing proxy; use any robot, spider,
            other automatic device, or manual process to monitor or copy
            Interswitch’s platform without the prior written consent of
            Interswitch.
          </li>
          <li>
            not use any device, software or routine to bypass our robot
            exclusion headers, or interfere or attempt to interfere with
            Interswitch’s website or services.{" "}
          </li>
          <li>
            be responsible for the conduct of its employees, agents, and
            representatives.{" "}
          </li>
          <li>
            prominently and clearly disclose to the cardholder at all points of
            interaction.
          </li>
          <li>
            conduct its business and perform its obligations in compliance with
            applicable laws and regulations.
          </li>
          <li>
            report all instances of a data compromise immediately to
            Interswitch, upon discovery
          </li>
          <li>comply with the applicable card scheme rules. </li>
          <li>
            submit to any procedural and financial audits conducted by and on
            behalf of Interswitch or a card scheme.{" "}
          </li>
          <li>
            fully cooperate with all investigation until completed, if
            undergoing a forensic investigation.
          </li>
          <li>
            if using, or intending to use, a service provider in connection with
            the INTERSWITCH ENERGY PLATFORM or service hereunder, must: <br />
            a. not engage in any activity that may create harm or loss to the
            goodwill of Interswitch or any applicable cards scheme’s brand or
            payment system. <br />
            b. inform and obtain the consent of each customer prior to
            activating recurring payments for such customer. <br />
            c. ensure it selects the correct business category, during
            onboarding on the platform.
          </li>
          <li>
            be aware of the possibility of a chargeback, and regularly check-in
            and provide evidence for such, so its account is not debited and/or
            suspended.
          </li>
          <li>
            keep all transaction records and proof of value being given to the
            customer, for presentation when a claim or chargeback is logged
            against it.{" "}
          </li>
          <li>
            put operational structures and processes in place to attend to all
            customer concerns including chargeback claims.{" "}
          </li>
          <li>
            inform Interswitch in writing of any changes to its ownership
            structure.{" "}
          </li>
          <li>
            ensure its refund policy is clearly visible to all customers on its
            platform.{" "}
          </li>
          <li>
            give authorization to Interswitch to debit its settlement account
            for claims against it and understands that all liabilities/claims
            under this T&C shall be treated as a loan and in this regard gives
            Interswitch a Global Standing Instruction (GSI) to access to all
            existing accounts in Nigeria.
          </li>
          <li>
            fully support Interswitch or its agents, in the recovery process for
            fraud committed by it, its customers, employees, representatives,
            agents, or by any 3rd party.{" "}
          </li>
          <li>
            promptly provide all information/documentation requested by
            Interswitch pursuant to clause xxxix above.{" "}
          </li>
          <li>
            The User shall be liable for (including without limitation, all
            charges, losses or damages whatsoever arising from): <br />
            a. data stored or transmitted on or through the INTERSWITCH ENERGY
            PLATFORM; or any use of the passwords or identification codes
            assigned by Interswitch. <br />
            b. The User shall observe and comply with all security measures and
            instructions prescribed by Interswitch, any applicable card scheme
            or any bank in respect of any card transactions or customer
            (cardholder) payment instruction.
          </li>
          <li>
            The User shall promptly notify Interswitch of any security breach,
            misuse, irregularity, suspected fraudulent transaction, account
            numbers or suspicious activities that may be connected with attempts
            to commit fraud or other illegal activity throughout the duration of
            use of this T&C.{" "}
          </li>
          <li>
            The User shall take all steps to keep secure and confidential any
            information or data related to transactions initiated on the User’s
            website. In the event any such information is lost, stolen or
            otherwise compromised, the User shall forthwith report and give
            written notice of such occurrence to Interswitch whereupon the User
            shall take immediate steps to remedy the situation and prevent its
            reoccurrence.
          </li>
          <li>
            5.52 The User is prohibited from: <br />
            a. submitting any transaction into the payment system that is
            illegal or that the User knows or should have known was illegal. The
            transactions must be legal in both the cardholder’s and User’s
            jurisdiction. <br />
            b. Submitting a transaction into the payment system that the User
            knows or should have known to be either fraudulent or not authorized
            by the cardholder. <br />
            c. requiring a cardholder to complete a postcard or similar device
            that includes any of the following in plain view when mailed: the
            cardholder’s account number, card expiration date, signature, or any
            other card account data. <br />
            d. requesting the Card Verification Value 2 (CVV2) data on any paper
            order form. <br />
            e. submitting transactions on behalf of other entities. <br />
            f. splitting a transaction with the intent of avoiding a single
            Authorization for the full amount of the purchase.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">6. TRANSACTION LIMITS</h3>

        <span className="section__wrapper-subHeader">
          Interswitch reserves the right to impose transaction limits on the
          INTERSWITCH ENERGY PLATFORM for any reason upon notice to the User.
        </span>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          7. SERVICES FEES AND CHARGES
        </h3>

        <ol>
          <li>
            Interswitch shall be entitled to a fee per successful transaction
            via the INTERSWITCH ENERGY PLATFORM as detailed below: <br />
            a. 1.5% fee (with a maximum cap of N2,000) per successful card
            transaction; VAT inclusive <br />
            b. For every successful transaction done with international card,
            the fee is 3.8%, VAT exclusive.
          </li>
          <li>
            Interswitch reserves the right to revise such fees and any charges
            from time to time upon notice to the User.
          </li>
          <li>
            Interswitch shall deduct all fees and charges from successful
            transactions before remitting to User.
          </li>
          <li>
            All payments required to be made by the User on this service shall
            be payable without any deduction, claim, counterclaim, setoff,
            notice or demand.
          </li>
          <li>
            No fee nor charges incurred by the User under this service shall be
            refundable in the event of termination howsoever caused.
          </li>
          <li>
            The User acknowledges that Interswitch may be subject to complying
            with instructions received from participating banks within the
            Interswitch network in respect of any transaction on/to the User’s
            designated account and agrees that no liability shall be imputed to
            Interswitch for acting on any such instruction.
          </li>
          <li>
            If for any reason whatever, a Bank where the User’s designated
            account is domiciled, ceases to belong to the Interswitch network,
            the User shall within 14 working days upon receipt of written notice
            thereof from Interswitch, provide an account for the purpose of the
            transactions with any of the other participating banks.
          </li>
          <li>
            Where claims go above 1% of the User’s total sales processed within
            a month, Interswitch reserves the right to put the User on a
            chargeback monitoring service, which may be subject applicable fees,
            as communicated by Interswitch. A User shall be liable to fee and
            charges, at least 6 months after the last processed transaction. No
            transaction settlement shall occur on the non-settlement days
            provided below: <br />
            a. any day where Banks in Nigeria are not open for business. <br />
            b. Sundays and public holidays as gazetted and declared in Nigeria;
            in which case transactions effected on such days shall be paid and
            settled on the day immediately following which is not any of the
            aforesaid non-settlement days
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          8. INDEMNITY AND LIMITATION OF LIABILITY
        </h3>

        <ol>
          <li>
            8.1. User shall indemnify and hold Interswitch, its subsidiaries,
            affiliates, officers, agents and employees harmless from and against
            all actions, proceedings, costs, claims, demands, charges, expenses
            (including legal expenses), liabilities, fines, levies, losses and
            damages, whether arising in tort, contract or common law, which
            Interswitch may suffer or incur to the extent arising out of or in
            consequence of or in connection with:
            <br />
            a. any claim brought against Interswitch by a customer, cardholder,
            Card Scheme, Card Issuer, Other Financial Institution, Acquirer or
            other third party arising from or processed through the INTERSWITCH
            ENERGY PLATFORM, to which the User is a party thereof; <br />
            b. any breach by the User of any of the provisions of this T&C or
            claim brought against Interswitch arising from any aspect of this
            T&C; <br />
            c. the enforcement or attempted enforcement of this T&C (which
            includes the recovery or attempted recovery of any sum owing to
            Interswitch under this T&C); <br />
            d. the protection of Interswitch’s interest in connection with any
            aspect of the parties’ relationship under this T&C (including the
            cost of any third parties nominated by Interswitch or instructed by
            Interswitch for this purpose); <br />
            e. any transaction (including a transaction which is subsequently
            discovered to be fraudulent); <br />
            f. respect to any negligent act or omission by, or willful
            misconduct of the User or its agents; or <br />
            g. any modification of or addition to the QUICKENERGY PLATFORM not
            provided or approved by Interswitch.
          </li>
          <li>
            If User is a member of a partnership, each partner shall be jointly
            and severally liable under this T&C.
          </li>
          <li>
            In the event of the occurrence of an event that leads to any damage,
            loss, liability or expense to Interswitch as stated in this Clause
            8, the User hereby agrees and authorizes Interswitch to immediately
            debit its account at Settlement.
          </li>
          <li>
            User agrees that if a fraudulent activity is associated with the
            operation of its accounts, Interswitch shall have the right to apply
            restrictions to such account and report to appropriate law
            enforcement agencies.
          </li>
          <li>
            Interswitch excludes all warranties, conditions, terms,
            representations or undertakings whether express, implied, statutory
            or otherwise, including without limitation any condition or warranty
            of merchantability or fitness for a particular purpose. Interswitch
            does not warrant that the INTERSWITCH ENERGY PLATFORM will meet
            User's requirements, or that it will be uninterrupted, secure or
            error free, or that all errors will be corrected.
          </li>
          <li>
            Interswitch shall not be liable to the User in event that the User
            suffers loss arising from a breach of the security and integrity of
            the User’s systems (hardware or software).
          </li>
          <li>
            Interswitch shall not be liable to the User to losses from product
            delivery, accident or acts of God.
          </li>
          <li>
            In no event shall Interswitch be liable to the User in excess of the
            transaction fees that has accrued to Interswitch from transactions
            emanating by virtue of this T&C, in the month immediately preceding
            the date the first such claim arises.
          </li>
          <li>
            No liability shall be raised against Interswitch more than One (1)
            year after the accrual of the cause of such liability therefore, it
            is further agreed that the limitations on liability, expressed
            herein, shall inure to the benefit of and apply to all parents (both
            direct and indirect), subsidiaries and affiliates of Interswitch.
          </li>
          <li>
            The limit of Liability applies irrespective of the number of claims.
          </li>
          <li>
            Interswitch shall not be liable for any loss which occurs during a
            routine maintenance of the INTERSWITCH ENERGY PLATFORM, of which it
            has given the User notice of.
          </li>
          <li>
            Interswitch will not be liable for the actions or inactions of any
            third party not acting on the instructions of Interswitch; neither
            will Interswitch be liable for the actions or inactions not directly
            traceable to it.
          </li>
          <li>
            Interswitch shall not be liable for any special, indirect,
            Incidental, punitive, or consequential damages, including loss of
            profits howsoever caused, including such damages, without
            limitation, as damages arising from loss of data or programming,
            loss of revenue or profits, failure to realize savings or other
            benefits, damage to equipment, and claims against the User by any
            third person, even if Interswitch has been advised of the
            possibility of such damages.
          </li>
          <li>
            Interswitch shall not be liable for any delay, failure, breakdown,
            damage, loss, costs, claim, penalty, fine or expense arising from
            use of the INTERSWITCH ENERGY PLATFORM by the User otherwise than in
            accordance with the terms of this T&C;
          </li>
          <li>
            Any third-party software application User uses on or with the
            INTERSWITCH ENERGY PLATFORM is subject to the license agreed to
            between the User and the third party that provides such software.
            Interswitch does not own, control nor have any responsibility or
            liability for any third-party software application used on or with
            the INTERSWITCH ENERGY PLATFORM.
          </li>
          <li>
            The provisions of this Clause 8 shall survive the termination of
            this T&C.
          </li>
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">9. CHARGEBACKS AND REFUNDS</h3>

        <ol>
          <li>
            In certain circumstances, Card Issuers, Card Schemes and/or Other
            Financial Institutions may require repayment in respect of a
            transaction previously settled and/or remitted to a User,
            notwithstanding that authorisation may have been obtained from the
            Card Issuer and/or Other Financial Institution (such circumstances
            being a "Chargeback").
          </li>
          <li>
            User acknowledges and agrees that under all applicable rules,
            regulations and operating guidelines issued by Card Schemes,
            Financial Institution, Central Bank of Nigeria and Interswitch
            relating to cards, transactions, other payment methods and
            processing of data, User may be required to reimburse Interswitch
            for Chargebacks in circumstances where User has accepted payment in
            respect of the relevant transaction.
          </li>
          <li>
            All Chargebacks shall correspond to the whole or part of the
            settlement value of the original transaction or, at an amount
            equivalent to the original settlement currency at the rate of
            exchange quoted for Settlement purposes on the day the Chargeback is
            processed.
          </li>
          <li>
            Where a Chargeback occurs, Interswitch shall immediately be entitled
            to debit User’s position with its Settlement Bank and/or make a
            deduction from any remittance and/or invoice due to the User to
            recover: <br />
            a. the full amount of the relevant Chargeback; and <br />
            b. any other costs, expenses, liabilities or Fines which may be
            incurred as a result of or in connection with such Chargeback
            ("ChargebackCosts").
          </li>
          <li>
            A Chargeback represents an immediate liability from User to
            Interswitch. Where the full amount of any Chargeback and/or any
            Chargeback Costs is not debited by Interswitch from the User’s
            Merchant Bank Account or deducted from any remittance or invoiced,
            then Interswitch shall be entitled to otherwise recover from User by
            any means the full amount of such Chargeback and Chargeback Costs
            (or the balance thereof, as the case may be).
          </li>
          <li>
            Interswitch shall not be obliged to investigate the validity of any
            Chargeback by any Card Issuer, Card Scheme or Other Financial
            Institution, whose decision shall be final and binding in respect of
            any Chargeback.
          </li>
          <li>
            As Chargebacks may arise a considerable period after the date of the
            relevant transaction, the User acknowledges and agrees that,
            notwithstanding any termination of this relationship for any reason.
          </li>
          <li>
            Interswitch shall remain entitled to recover Chargebacks and
            Chargeback Costs (and, where relevant, from any entity who has
            provided Interswitch with a guarantee or security relating to User’s
            obligations under this relationship) in respect of all Chargebacks
            that occur in relation to transactions effected during the term
            thereof.
          </li>
          <li>
            Interswitch reserves the right to immediately pass on to and recover
            from the User any fines incurred and/or impose further charges on
            User and/or terminate the relationship forthwith if we consider that
            the total value of refunds and/or Chargebacks is unreasonable.
            Interswitch can recover fines from User in the same way as
            Chargebacks and in any event they represent an immediate liability
            from User to Interswitch.
          </li>
          <li>
            User agrees that it bears the responsibility to prove to Interswitch
            satisfaction (or that of the relevant Card Issuer or Other Financial
            Institution) that the debit of a customer's or cardholder's account
            was authorised by such customer or cardholder and that value was
            given to the customer.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">10. SET OFF</h3>

        <ol>
          <li>
            Interswitch is authorized to combine or consolidate all or any of
            the User’s accounts with the Settlement Bank and set off or transfer
            any sum(s) standing to the credit of any one or more of such
            accounts in or towards the satisfaction of the User’s settlement
            liabilities under the INTERSWITCH ENERGY PLATFORM including any
            fees/fines imposed on Interswitch due to any breach of this T&C by
            the User.
          </li>
          <li>
            Interswitch is entitled to defer any settlement or any other sum due
            to the User to the extent that Interswitch considers necessary or
            appropriate to protect their ability to recover the Fees and/or the
            sums or any other liability (actual or anticipated) of the User in
            connection with this Agreement.
          </li>
          <li>
            If Interswitch has reasonable suspicion that a transaction may be
            fraudulent or involve other illegal activity, Interswitch may
            suspend the processing of that transaction and any connected,
            transaction, or withhold settlement until the satisfactory
            completion of any investigation. The User shall not be entitled to
            any interest or other compensation whatsoever in respect of
            suspension or delay in receiving the affected payment. Interswitch
            reserves the right to refund the money back to the card owners.
          </li>
          <li>
            The exercise by Interswitch of any of its rights under this clause
            shall be without prejudice to any other rights or remedies to which
            Interswitch is otherwise entitled (by operation of law, contract, or
            otherwise).
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">11. SECURITY/DEPOSIT</h3>

        <ol>
          <li>
            Where required by Interswitch, Interswitch reserves the right to
            require that the User provides (or procures the provision of)
            security in such form as to be agreed by the Parties to secure the
            performance of the Users actual, contingent or potential obligations
            under this T&C or otherwise in connection with the services
            hereunder. Such security may take the form of a cash deposit, a
            rolling reserve, Government Security Instruments (Treasury bills and
            bonds), Bank guarantee or Insurance guarantee, any other guarantee
            or indemnity. Interswitch reserves the right to unilaterally call
            for an increase to the level of security held.
          </li>
          <li>
            Interswitch may require that any security provided be supplemented
            or replaced at any time.
          </li>
          <li>
            Upon termination of this T&C, Interswitch may retain such amount
            from the security (if any) and settlement sums due to the User as
            may be determined by Interswitch to cover Chargeback risk, Refund
            risk or any potential loss, damages, penalties, cost that may be
            incurred by User for a period of one hundred and eighty days (180)
            Business Days. In the event that such retained amount is not
            sufficient to cover all outstanding amounts of the User
            post-termination, the Merchant shall ensure that it pays the User
            all pending amounts within ten (10) Business Days of receiving the
            demand notice and shall at all times keep Interswitch indemnified in
            this respect.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">12. RIGHT OF AUDIT</h3>

        <ol>
          <li>
            The User shall establish and maintain a reasonable accounting system
            that enables Interswitch readily identify the User’s assets,
            expenses, costs of goods, and use of funds. Interswitch and its
            authorized representatives shall have the unrestricted right to
            audit, examine, and to make copies of, or extracts from all
            financial and related records (in whatever form they may be kept,
            whether written, electronic, or other) relating to or pertaining to
            this T&C kept by or under the control of the User, including, but
            not limited to those kept by the User, its employees, agents,
            assigns, successors, and subcontractors. Such records shall include,
            but not be limited to, accounting records, deposit slips, bank
            statements, all paid vouchers including those for out-of-pocket
            expenses, other reimbursement supported by invoices, original
            estimates, written policies and procedures and other correspondence
            as well as KYC and other relevant documents.
          </li>
          <li>
            Interswitch shall reserve the right to appoint at any time an
            authorized representative/auditor to conduct a systems and/or
            compliance audit of the User (upon reasonable notice) as it may
            require in its absolute discretion notwithstanding that the User has
            confirmed to Interswitch that it has conducted an audit. The User
            undertakes to cooperate fully with and grant Interswitch’s
            representative full access to its operations and relevant
            documentation for the purpose of conducting the audit.
          </li>
          <li>
            User undertakes to permit Interswitch to conduct such other audits
            of its operations and processes as may be applicable or relevant to
            the performance of the User’s obligations under this T&C. Such
            further audits shall be carried out under terms to be decided upon
            by Interswitch at its sole discretion.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">13. WARRANTIES</h3>

        <ol>
          <li>
            User warrants that it is duly registered, and has the full capacity,
            legal and corporate authorisation to accept this T&C and discharge
            the obligations and responsibilities created herein.
          </li>
          <li>
            User further warrants that it has the required licenses and
            regulatory approvals to conduct its business and participate in this
            transaction and no element of the transaction constitutes a breach
            of any existing law, regulation, patent, copyright, or other
            intellectual property in its country or countries of domicile and
            operation.
          </li>
          <li>
            User warrants that it has adequate controls, safeguards, information
            technology security and effective internal controls for all its
            operations.
          </li>
          <li>
            User will keep Interswitch indemnified against all actions, claims,
            proceedings and all legal cost or other expenses arising out of any
            breach of the above warranties or out of any claim by a third party
            based on any facts which if substantiated would constitute such a
            breach or a breach of other relevant legal or contractual duty.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          14. DATA REQUEST AND INTEGRITY
        </h3>

        <ol>
          <li>
            In order to enable Interswitch to comply with applicable laws,
            including but not limited to anti-terrorism, financial services,
            anti-tax evasion and anti-money laundering laws and regulations
            imposing Customer Due Diligence (“CDD”) requirements, as well as
            with the card scheme requirements, the User must, before accepting
            this T&C, and thereafter on our first request, provide information:
            about itself, and in particular about its financial status, solvency
            and liquidity, its activities, its payment acquiring and processing
            arrangements, its shareholders, its ultimate beneficial
            shareholders, the User’s Products/Services, its registered office
            address, as well as any and all regulatory licences and
            registrations required to sell Merchant Products/Services (herein
            defined as “the Merchant Underwriting Data”).
          </li>
          <li>
            The User warrants unconditionally that all Merchant Underwriting
            Data it provides to Interswitch is correct and up to date and
            undertakes to provide Interswitch with at least five (5) Business
            Days prior written notice of any material change of the Merchant
            Underwriting Data, including in particular (but not limited to) any
            change of its directors, shareholders and/or ultimate beneficial
            owners.
          </li>
          <li>
            In addition to data specified in clause 14.1 above, Interswitch may
            also from time to time request the User provide additional financial
            and other information such as relating to: (i) the current actual or
            expected delivery dates for processed Transactions; (ii) estimates
            for the average time between Transaction authentication and the
            related delivery date; (iii) User’s ability to provide its
            Products/Services, and/or (iv) User’s financial status, solvency and
            liquidity. The Merchant shall provide such requested information
            within five (5) Business Days of our written request.
          </li>
          <li>
            If the User fails to provide the data requested in accordance with
            the provisions of this clause, Interswitch reserves the right to
            suspend access to the INTERSWITCH ENERGY PLATFORM until such data is
            provided.
          </li>
          <li>
            The User agrees that Interswitch may run further checks on the User
            ’s identity, creditworthiness and background by contacting and
            consulting relevant registries and governmental authorities or any
            other relevant sources.
          </li>
          <li>
            The User hereby authorizes Interswitch to submit Merchant
            Underwriting Data, or any other relevant information received from
            the User, to the relevant Card Scheme or Third-Party Payment
            Processor to obtain a permission for providing access to their
            Payment Methods for the User, or for any ongoing monitoring related
            purpose.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">15. DISPUTES</h3>

        <ol>
          <li>
            Interswitch shall not be involved in any dispute that may arise
            between the User and its customer.
          </li>
          <li>
            If a dispute arises between Interswitch and the User in connection
            with the interpretation, implementation or operation of this T&C or
            its subject matter or the validity of any document furnished by
            either Interswitch or the User under this T&C which cannot be
            resolved amicably by them within 10 days of notice of the dispute by
            either of them, Interswitch and the User and their legal
            representatives will promptly meet to consider whether there is a
            possibility of resolution by mediation or conciliation.
          </li>
          <li>
            If Interswitch and User do not agree to refer a dispute to mediation
            or conciliation under the Lagos State Multidoor Court House, the
            matter shall refer the dispute to a court of competent jurisdiction.
          </li>
          <li>
            The provisions of this clause: <br />
            a. constitute an irrevocable consent by Interswitch and User to any
            proceedings in terms hereof and no party shall be entitled to
            withdraw there from or claim at any such proceedings that it is not
            bound by those provisions; and <br />
            b. are severable from the rest of this T&C and shall remain in
            effect despite the termination of or invalidity of this T&C for any
            reason.
          </li>
          <li>
            This clause shall not preclude either Interswitch or User from
            obtaining interim relief on an urgent basis from a court of
            competent jurisdiction pending the decision of the mediator or
            conciliator.
          </li>
          <li>
            The mediator or conciliator may, in any dispute in which any matter
            of a technical or financial nature is relevant, appoint an assessor
            having the requisite experience to assist the mediator or
            conciliator in the mediation process.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">16. SERVICE TERMINATION</h3>

        <ol>
          <li>
            This T&C shall commence on the date of acceptance by the User and
            remain effective until terminated in accordance with provisions
            contained herein.
          </li>
          <li>
            Either Interswitch or the User may elect to terminate this T&C by
            giving three (3) month’s prior notice in writing to the other of its
            intention to do so.
          </li>
          <li>
            Interswitch shall be entitled to immediately suspend the provision
            of the service or to terminate the service and by effect this T&C at
            any time with immediate effect by notice to User if: <br />
            a. User is in breach of any of the provisions herein; <br />
            b. User fails to pay any amount due under this T&C; <br />
            c. Interswitch considers (in its absolute discretion) that the total
            value of refunds and/or chargebacks is unreasonable; <br />
            d. User is in breach of any applicable trading limit or floor limit;{" "}
            <br />
            e. User presents a transaction in a situation where User does not
            give to the relevant customer or cardholder the goods, services or
            other facilities referred to which they could reasonably expect to
            receive; <br />
            f. User becomes insolvent or any step is taken for User liquidation,
            winding-up, bankruptcy, receivership, administration or dissolution
            (or anything analogous to the foregoing occurs in any jurisdiction);{" "}
            <br />
            g. User makes or proposes any arrangement with creditors generally;{" "}
            <br />
            h. anything happens to User or a matter is brought to the attention
            of Interswitch which in its absolute discretion, it considers may
            affect User’s ability or willingness to comply with all or any of
            User’s obligation or liabilities herein; <br />
            i. any other change in User circumstances (including a deterioration
            in or change to User’s financial position) or in the nature of
            User’s business or in the goods and/or services supplied by User to
            customers or cardholders occurs which Interswitch in its absolute
            discretion considers material to the continuance of the services or
            any facilities made available to User; <br />
            j. User ceases to carry on business or changes its line of business
            without notification to Interswitch; <br />
            k. Interswitch in its absolute discretion, determines that the
            relationship with User’s business represents increased risk of loss
            or liability; <br />
            l. anything happens to User or comes to the attention of Interswitch
            in relation to User or arising from or incidental to User’s business
            or the conduct of User’s business (including trading practices and
            individual activities) or User engages in any business trading
            practices or individual activity which in Interswitch’s absolute
            discretion is considered disreputable or capable of damaging
            Interswitch’s reputation or that of any of the card scheme or other
            financial Institutions, detrimental to Interswitch’s business or
            that of any of the card scheme or other financial institution or
            which may or does give rise to fraud or any other criminal activity
            or suspicion of fraud or any other criminal activity; <br />
            m. any fines or any other claims are brought against Interswitch by
            any card scheme, financial institution or any other third party
            arising from any aspect of the parties’ relationship (including in
            connection with any security breach, compromise or theft of Data
            held by User or on behalf of User irrespective of whether such
            security breach, compromise or theft of Data was within or outside
            User control); <br />
            n. User undertakes trading practices which Interswitch has not
            consented to; <br />
            o. Interswitch or any affiliate becomes entitled to terminate any
            T&C with or enforce any security from User or User’s affiliate;{" "}
            <br />
            p. any card scheme, financial institution, acquirer or regulator
            introduces additional terms and conditions or amends the terms and
            conditions relating to this service; <br />
            q. User is involved in any activity that may in Interswitch’s
            opinion create harm or loss to the goodwill of a card scheme; or{" "}
            <br />
            r. Interswitch is required or requested to do so by any card scheme,
            financial Institution, regulator, or government agency
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          17. CONSEQUENCES OF TERMINATION
        </h3>

        <ol>
          <li>
            Upon termination of this T&C and or service, all rights and
            obligations of either party shall cease to have effect immediately,
            save that: <br />
            a. the clauses of conditions which expressly or by implication have
            effect after termination will continue to be enforceable
            notwithstanding such termination; and <br />
            b. termination shall not affect accrued rights and obligations of
            either Interswitch or User under the T&C as at the date of
            termination. <br />
          </li>
          <li>
            Upon or at any time after termination, User shall immediately pay
            all amounts owed under the service and, for the avoidance of doubt,
            Interswitch shall remain entitled to withhold sums, set- off any
            sums and recover any Chargebacks and Chargeback Costs pursuant to
            the relevant clauses herein.
          </li>
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">18. GOVERNING LAW</h3>

        <span className="section__wrapper-subHeader">
          This T&C shall be governed by the laws of the Federal Republic of
          Nigeria.
        </span>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">19. WAIVER</h3>

        <span className="section__wrapper-subHeader">
          The failure by either Party to enforce any of the provisions of this
          T&C shall not constitute a waiver of the same or affect that Party's
          rights thereafter to enforce the same.
        </span>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">20. ASSIGNMENT</h3>

        <ol>
          <li>
            Neither Party shall assign any of its obligations under this T&C
            without the prior written consent of the other Party, which shall
            not be unreasonably withheld.
          </li>
          <li>
            Regardless of any provision of this T&C, Interswitch shall be
            entitled to assign all or any part of this relationship to a card
            scheme upon written notice to the User.
          </li>
        </ol>
      </div>

      <div className="section__wrapper">
        <h3 className="section__wrapper-header">21. CONFIDENTIALITY</h3>

        <ol>
          <li>
            The User undertakes to keep confidential all information [written or
            oral] concerning the business and affairs of Interswitch that it
            shall have obtained or received as a result of the discussions
            leading up to or the acceptance of this T&C save that which is [a]
            already in its possession other than as a result of a breach of this
            clause; or [b] in the public domain other than as a result of a
            breach of this clause. And User undertakes to take all such steps as
            shall from time to time be necessary to ensure compliance with the
            provisions of this clause by its employees, agents and
            subcontractors. The confidentiality obligations shall survive the
            termination of this T&C.
          </li>
          <li>
            Interswitch shall be entitled to disclose any transaction data or
            any other information relating to the User to a card scheme.
          </li>
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">22. INTELLECTUAL PROPERTY</h3>

        <span className="section__wrapper-subHeader">
          Nothing set forth in this T&C shall constitute a transfer or
          assignment by Interswitch of any Intellectual Property Rights owned or
          otherwise controlled by it.
        </span>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">23. FORCE MAJEURE</h3>

        <span className="section__wrapper-subHeader">
          If the compliance with this T&C or any obligation under it is
          prevented, restricted or interfered with by reason of circumstances
          beyond the reasonable control of the party obliged to perform it, the
          party so affected shall be excused from performance to the extent of
          the prevention, restriction or interference, but the party so affected
          shall use its best endeavours to avoid or remove the causes of
          non-performance and shall continue performance under this T&C with
          utmost dispatch whenever such causes are removed or diminished.
        </span>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">24. ANTI-CORRUPTION</h3>

        <ol>
          <li>
            The User shall comply with all applicable anti-bribery and
            anti-corruption Laws in any relevant jurisdiction (including those
            in Nigeria and the Foreign Corrupt Practice Act of the United States
            of America and the Bribery Act 2010 of the United Kingdom) and all
            applicable anti-bribery and anti-corruption regulations and codes of
            practice.
          </li>
          <li>
            Each Party shall ensure that it shall use its best endeavours to
            ensure compliance with anti-money laundering laws and best practices
            and it shall set up internal structures to track, prevent and detect
            such violations including breaches of all such laws, regulations and
            conventions.
          </li>
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
          25. INDUCEMENT AND ANTI GRAFT
        </h3>

        <ol>
          <li>
            Notwithstanding the provisions herein, for the purposes of this
            clause the meaning of the word “Gratification” shall include but not
            be limited to: money, donation, gift, loan, fee, reward, valuable
            security, property or interest in property (property being property
            of any description, moveable or immovable, tangible or intangible,
            real or personal whether situated in Nigeria or elsewhere), promise
            of employment, contract of empowerment or services.
          </li>
          <li>
            The User shall not offer to give any person (“person” meaning
            Interswitch employees or third Parties) any gratification or
            consideration of any kind as an inducement or reward for doing or
            not doing or having done or omitting to do a favour or for a
            disfavour done to another in the discharge of official duty, duties
            or in relation to any matter connected with his/her job functions or
            relating to the business of Interswitch.
          </li>
          <li>
            In the fulfilment of this clause, the User owes a duty to
            Interswitch to report any person(s), who request for personally or
            through an agent, (agent meaning any person employed by or acting on
            behalf of another), accepts, receives or obtains any property or
            benefit of any kind for himself or herself or for any other person,
            on account of a reward in any of the instances stipulated herein.
          </li>
          <li>
            The User may effect his or her report by calling the Interswitch
            anonymous line +2340800-TIPOFFS (0800-847-6337) or by using any of
            the underlisted channels: <br />
            · Web Portal: https://tip-offs.deloitte.com.ng · E-mail:
            tip-offs@deloitte.com.ng
            <br />· Mobile App: Download Deloitte Tip-offs Anonymous App on your
            Android or iOS devices.
          </li>
          <li>
            Any breach of the terms of this clause by the User or by the User’s
            employees, Subcontractors, agents or anyone acting on their
            instructions (whether with or without the knowledge of the User)
            shall entitle Interswitch to terminate this T&C with the User
            forthwith. Termination shall be without prejudice to any other
            remedies available to Interswitch at law which may include criminal
            prosecution for corrupt practices.
          </li>
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
        26. NOTICES
        </h3>

        <ol>
          <li>
          Any notice required hereunder shall be in writing and sent to the User via the electronic mail address provided to Interswitch during registration of use of the INTERSWITCH ENERGY PLATFORM. Any electronic Communications will be considered to be received by User within 24 hours of being sent.
          </li>
          <li>
          All communications to Interswitch must be sent by electronic mail to: corporatesupport@interswitchgroup.com.
          </li>
          <li>
          User is to keep primary email address up to date so that Interswitch can communicate with it electronically. User understands and agree that if Interswitch sends an electronic communication, but User does not receive it due to the primary email address on file being incorrect, out of date, blocked, or otherwise unable to receive electronic Communications, Interswitch will be deemed to have provided the communication to the User effectively.
          </li>
        
        </ol>
      </div>
      <div className="section__wrapper">
        <h3 className="section__wrapper-header">
        27. MISCELLANEOUS PROVISIONS
        </h3>

        <ol>
          <li>
          Interswitch reserves the right to terminate, change, suspend or discontinue any aspect or feature of the INTERSWITCH ENERGY PLATFORM at any time, with 2 weeks’ notice to the User.
          </li>
          <li>
          Interswitch reserves the right to amend this T&C at any time and at its sole discretion. Interswitch shall give you notice of such amendment by publication of the amended version via the INTERSWITCH ENERGY PLATFORM. Your continued access to and use of the Service is your acceptance of and compliance with the updated Terms of Use. If you disagree with any part of hereof, do not access the INTERSWITCH ENERGY PLATFORM.
          </li>
          <li>
          If any provision of this Terms of Use is declared unlawful and/or unenforceable by operation of law, any court or authority, such provision shall be severed from this T&C and rendered ineffective so far as is possible without modifying the remaining provisions of this T&C.
          </li>
        
          <span className="section__wrapper-subHeader">
          I hereby authorize Interswitch Limited (Interswitch) to:<br />

• • withhold settlement of all sums due to me, until I complete the Know Your Customer (KYC) exercise, conducted by Interswitch, and submit all documents/information requested by Interswitch; and <br />

• • refund all sums paid to me via the INTERSWITCH ENERGY Platform, to the respective cardholders (regardless of whether the cardholder has been given value), if I do not complete the KYC exercise within 60 days from the date of go live.
        </span>

        
        
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
