import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    CREATE_VENDOR
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
function* createVendor(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.createVendorService, payload);
        yield put(Actions.miniUiStop());
        if (code === 1) yield put(Actions.toggleRightSidebar(false));
        yield put(
            Actions.getAllUsers({
                pageIndex: 1,
                pageSize: 10,
                userType: 3,
                userTypeId: null
            })
        );
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* createVendorSaga() {
    yield takeLatest(CREATE_VENDOR, createVendor);
}
